import React from "react";
import SuccessfullSwapIcon from "../assets/successfull-swap-icon.svg";
import { IoClose } from "react-icons/io5";
import { useTheme } from "@mui/material";
import {
  XBR as XBRimg,
  USDT as USDTimg,
  LightmodeUSDT,
  LightmodeXBR,
  tba,
} from "../assets/index.js";

const SuccessfullSwap = ({ onClose, sendValue, receiveValue, selectedToken, selectedToken2, swapdata }) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  const formatValue = (value) => {
    const [integerPart, decimalPart] = value.toString().split(".");
    return decimalPart && decimalPart.length > 4
      ? parseFloat(value).toFixed(4)
      : value;
  };

  const formattedSendValue = formatValue(sendValue);
  const formattedReceiveValue = formatValue(receiveValue);

  const tokenImageMap = {
    XBR: isLightMode ? LightmodeXBR : XBRimg,
    USDT: isLightMode ? LightmodeUSDT : USDTimg,
    TBA: tba,
  };

  const token1Image = tokenImageMap[selectedToken.name] || tba; 
  const token2Image = tokenImageMap[selectedToken2.name] || tba;

  return (
    <div className="fixed top-[72px] right-2 z-50">
      <a
        href={`https://polygonscan.com/tx/${swapdata}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`block min-w-[270px] w-90per max-w-[400px] box-border rounded-[16px] gap-[10px] relative border-2 border-solid ${
          isLightMode ? "bg-white border-[#D6DAFC]" : "bg-[#02042C] border-[#21246A]"
        }`}
      >
        <div className="flex items-center gap-[8px] p-[16px] pr-7">
          <div className="flex">
            <img src={token1Image} alt={selectedToken.name} className="w-6 h-6 object-cover" />
            <img src={token2Image} alt={selectedToken2.name} className="w-6 h-6 object-cover -ml-2" />
          </div>
          <div className="flex flex-col md:gap-[4px]">
            <p
              className={`text-[16px] ${
                isLightMode ? "text-[#181821]" : "text-[#ffffff]"
              }`}
            >
              Swapped
            </p>
            <p
              className={`text-[16px] ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              {formattedSendValue} {selectedToken.name} for {formattedReceiveValue} {selectedToken2.name}
            </p>
          </div>
          <IoClose
            className={`h-[24px] w-[24px] cursor-pointer hover:opacity-80 right-2 absolute z-50 ${
              isLightMode ? "text-[#181821]" : "text-white"
            }`}
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          />
        </div>
      </a>
    </div>
  );
};

export default SuccessfullSwap;
