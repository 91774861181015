import React from 'react'
import Navbar from '../../components/Navbar'
import ConfirmNodeComponent from './confirmNode'

function ConfirmNode() {
    return (
        <>
            <div className='w-full h-full'>
                {/* <Navbar /> */}
                <div>
                    {/* <NewBuyNodeComponent /> */}
                    <ConfirmNodeComponent />
                </div>

            </div>

        </>
    )
}

export default ConfirmNode