import { parseUnits, formatUnits, BrowserProvider, Contract } from "ethers";
import { CurrencyAmount, Percent, TradeType } from "@uniswap/sdk-core";
import { Pool, SwapRouter, Trade, Route } from "@uniswap/v3-sdk";

import { ERC20_ABI, SWAP_ROUTER_ADDRESS } from "../web3Utils";
import { getPoolInfo } from "./pool";
import { fetchTokenAddress } from "./utils";
import { connectWallet, subscribeToProviders, getProviders } from "./farm";

export async function createTrade(
  token0,
  token1,
  token0Amount,
  token1Amount,
  poolAddress,
  walletProvider
) {
  const poolInfo = await getPoolInfo(poolAddress, walletProvider);
  console.log("poolInfo", poolInfo);

  const pool = new Pool(
    token0,
    token1,
    poolInfo.fee,
    poolInfo.sqrtPriceX96.toString(),
    poolInfo.liquidity.toString(),
    parseInt(poolInfo.tick)
  );

  const swapRoute = new Route([pool], token0, token1);

  const uncheckedTrade = Trade.createUncheckedTrade({
    route: swapRoute,
    inputAmount: CurrencyAmount.fromRawAmount(
      token0,
      // fromReadableAmount(token0Amount, token0?.decimals).toString()
      parseUnits(token0Amount.toString(), token0?.decimals).toString()
    ),
    outputAmount: CurrencyAmount.fromRawAmount(
      token1,
      parseUnits(token1Amount.toString(), token1?.decimals).toString()
      // fromReadableAmount(token1Amount, token1?.decimals).toString()
    ),
    tradeType: TradeType.EXACT_INPUT,
  });

  return uncheckedTrade;
}

export async function executeTrade(
  token0,
  token1,
  token0Amount,
  token1Amount,
  slippageTolerance,
  poolAddress,
  walletProvider
) {
  try {
    console.log(
      "token0: ",
      token0,
      "token1: ",
      token1,
      "token0Amount: ",
      token0Amount,
      "token1Amount: ",
      token1Amount,
      "slippageTolerance: ",
      slippageTolerance,
      "poolAddress: ",
      poolAddress
    );
    const trade = await createTrade(
      token0,
      token1,
      token0Amount,
      token1Amount,
      poolAddress,
      walletProvider
    );
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("executeTrade-connectWallet", providerWithInfo);

      provider = new BrowserProvider(providerWithInfo?.provider);
    }
    
    const walletAddress = await signer?.getAddress();

    if (!walletAddress || !provider) {
      throw new Error("Cannot execute a trade without a connected wallet");
    }

    const percent = slippageTolerance * 100;
    console.log("slippagePercent", percent);
    const options = {
      slippageTolerance: new Percent(percent, 10_000), // 50 bips, or 0.50%
      deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
      recipient: walletAddress,
    };

    const { calldata, value } = SwapRouter.swapCallParameters([trade], options);
    // console.log("methodParams: ", calldata, value);

    // const maxPriorityFeePerGas = await provider.getFeeData(true);
    /*
    console.log("maxFeePerGas", formatUnits(fees.maxFeePerGas, "wei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(fees.maxPriorityFeePerGas, "wei")
    );
    */
    const fees = await provider?.getFeeData();
    const tx = {
      data: calldata,
      to: SWAP_ROUTER_ADDRESS,
      value: value,
      from: walletAddress,
      maxFeePerGas: formatUnits(fees.maxFeePerGas, "wei"), //MAX_FEE_PER_GAS,
      maxPriorityFeePerGas: fees.maxPriorityFeePerGas
        ? parseUnits(fees.maxPriorityFeePerGas.toString(), "wei")
        : parseUnits("10", "gwei"),
    };

    const gasLimit = await signer.estimateGas(tx);
    tx.gasLimit = gasLimit;

    // console.log("Estimated gas limit:", formatUnits(gasLimit, "gwei"));
    // console.log("tx", tx);

    const res = await signer?.sendTransaction(tx);

    const receipt = await res.wait();
    // Check transaction status
    if (receipt.status === 1) {
      console.log("Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }

    console.log("receipt", receipt); // Optional: Print the full receipt for debugging

    console.log("SWAP RESPONSE", res);
    return res.hash;
  } catch (error) {
    console.error("Error at ExecuteTrade: ", error);
    return "Failed";
  }
}

export async function getTokenTransferApprovalFromRouter(token, value, walletProvider) {
  try {

    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    console.log("token", token, "value", value);
    const token0 = await fetchTokenAddress(token, walletProvider);

    const tokenContract = new Contract(token0.address, ERC20_ABI, signer);
    const approveTx = await tokenContract.approve(SWAP_ROUTER_ADDRESS, value);
    const receipt = await approveTx.wait();
    // Check transaction status
    if (receipt.status === 1) {
      console.log("Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }

    console.log("receipt", receipt); // Optional: Print the full receipt for debugging
    return approveTx.hash;
  } catch (error) {
    console.error(error);
    return "Failed";
  }
}
