// web3js
import { isAddress, parseUnits, Contract } from "ethers";
export const USDT_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
];

export const FARM_ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "initialOwner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_lp",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_xbr",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "rewardPerBlock",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ReentrancyGuardReentrantCall",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "purchaseBlockNumber",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Stake",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "purchaseBlockNumber",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "reward",
				"type": "uint256"
			}
		],
		"name": "UnStake",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "LP",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "REWARD_PER_BLOCK",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "XBR",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "balance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "left",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getStakedTokens",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "tokens",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "rewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "reward",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setup",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "stake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "unstake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userStakeDetails",
		"outputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "purchasedBlockNumber",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

export const USDT_ADDRESS = "0x6230Be17697536128bc80302064F790524644D10";
// export const USDT_ADDRESS = "0x1bdd37EDCc5586b7b12F628f881292809E0afA2e";

export const transferUSDT = async (recipient, amount, provider, account, gasLimit) => {

  if (!isAddress(recipient)) {
    alert("Please enter a valid Ethereum address.");
    return;
  }

  if (isNaN(amount) || amount <= 0) {
    alert("Please enter a valid amount.");
    return;
  }

  if (!provider || account === "Not connected") {
    console.log("Wallet is not connected");
    return;
  }

  try {
    const contract = new Contract(USDT_ADDRESS, USDT_ABI, provider);
    const signer = await provider.getSigner();
    const contractWithSigner = contract.connect(signer);
    const actualAmount = parseUnits(amount.toString(), 6);
    const tx = await contractWithSigner.transfer(recipient, actualAmount, { gasLimit: gasLimit });
    const receipt = await tx.wait();
    console.log("Transaction receipt", receipt);
    return receipt; // Return the transaction receipt
  } catch (error) {
    console.error("Error transferring USDT:", error.message);
    throw error; // Propagate the error to the caller
  }
};

export const transferUSDTWithReferral = async (recipient, amount, referralWalletAddress, provider, account, gasLimit) => {
  // Validate the recipient's address
  if (!isAddress(recipient) || !isAddress(referralWalletAddress)) {
    console.error("Please enter valid Ethereum addresses.");
    return;
  }

  // Validate the amount
  if (isNaN(amount) || amount <= 0) {
    console.error("Please enter a valid amount.");
    return;
  }

  if (!provider || account === "Not connected") {
    console.error("Wallet is not connected");
    return;
  }

  try {
    console.log("Recipient:", recipient);
    console.log("Recipient Wallet:", referralWalletAddress);

    // Create a contract instance
    const contract = new Contract(USDT_ADDRESS, USDT_ABI, provider);
    // Get the signer from the provider
    const signer = await provider.getSigner();
    // Connect the contract to the signer
    const contractWithSigner = contract.connect(signer);

    // Calculate 80% of the amount for the recipient and 20% for the referral wallet
    const recipientAmount = parseUnits((amount * 0.8).toString(), 6);
    const referralWalletAmount = parseUnits((amount * 0.2).toString(), 6);

    // Call the transfer function with two recipients in a single transaction
    const tx = await contractWithSigner.transfer(recipient, recipientAmount, { gasLimit: gasLimit });
    // Wait for the transaction to be mined
    const receipt = await tx.wait();
    console.log("Recipient transaction receipt", receipt);

    // Call the transfer function for the referral wallet within the same transaction
    const txReferral = await contractWithSigner.transfer(referralWalletAddress, referralWalletAmount, { gasLimit: gasLimit });
    // Wait for the referral transaction to be mined
    const receiptReferral = await txReferral.wait();
    console.log("Referral transaction receipt", receiptReferral);

    // Return combined receipt
    return { receipt, receiptReferral };
  } catch (error) {
    console.error("Error occurred while transferring USDT:", error);
    throw error;
  }
};