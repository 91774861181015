import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { NewCheck, NewCheckLight } from "../assets";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";

const PopUpDialog = ({
  isOpen,
  handleClose,
  title,
  description,
  transactionDetails,
  footerText,
  onFooterClick,
  transactionHash,
}) => {
  const theme = useTheme();

  const truncateValue = (value) => {
    if (value?.length > 10) {
      const start = value.slice(0, 10);
      const end = value.slice(-10);
      return `${start}....${end}`;
    }
    return value;
  };

  const formatReceivedLPTokens = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const iconSrc = theme.palette.mode === "light" ? NewCheckLight : NewCheck;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "70%",
          backgroundImage: "none",
          borderRadius: "24px",
          backdropFilter: "blur(20px)",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        className={`${
          theme.palette.mode === "light"
            ? "bg-white text-gray-200"
            : "text-mediumslateblue-500 bg-gray-200"
        } rounded-3xl`}
        dividers
      >
        <img
          alt=""
          src={iconSrc}
          className="m-auto mt-2 rounded-full w-[60px] h-[60px]"
        />

        <div
          className={`text-center ${
            theme.palette.mode === "light"
              ? "text-gray-200"
              : "text-[#F7F7FE]"
          } text-ft22 font-inter font-bold p-2`}
        >
          {title}
        </div>
        <div
          className={`${
            theme.palette.mode === "light"
              ? "text-[#5B5C71]"
              : "text-[#ACADBB]"
          } text-center text-[14px] font-inter font-normal p-2`}
        >
          {description}
        </div>
        <hr className="text-gray-30 bg-slate-200 h-[1px]" />
        {transactionDetails.map((detail) => (
          <div className="flex justify-between p-2" key={detail?.label}>
            <span className="text-[#ACADBB] text-[16px] font-inter font-normal">
              {detail?.label}
            </span>
            <span className="text-[detail.color] text-[16px] font-inter font-normal">
              {detail?.label === "Received LP Tokens"
                ? formatReceivedLPTokens(detail?.value)
                : truncateValue(detail?.value)}
            </span>
          </div>
        ))}
        <button
          onClick={() => onFooterClick(transactionHash)}
          className={`bg-[#5663F2] ${
            theme.palette.mode === "light"
              ? "text-white"
              : "text-lightgray-400"
          } hover:bg-opacity-80 mb-2 rounded-xl py-3 w-full align-center flex mt-[15px] cursor-pointer`}
        >
          <div className="m-auto">{footerText}</div>
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpDialog;
