import { createSlice } from '@reduxjs/toolkit';

const nodePrice = Number(process.env.REACT_APP_NODE_PRICE);

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    amount: nodePrice,
    quantity: 1,
    referralAddress: '',
    isValidReferral: false,
    // dummy: localStorage.getItem('dummy') === 'true',
    clickedBuyNode: false,

    receiverAddress: '',
    selectedCheckboxes: {},
  },
  reducers: {
    incrementAmount: (state) => {
      if (state.amount < 10000) {
        state.amount += nodePrice;
      }
    },
    decrementAmount: (state) => {
      if (state.amount > 1000) {
        state.amount -= nodePrice;
      }
    },
    incrementQuantity: (state) => {
      if (state.quantity < 10) {
        state.quantity += 1;
      }
    },
    decrementQuantity: (state) => {
      if (state.quantity > 1) {
        state.quantity -= 1;
      }
    },
    setReferralAddress: (state, action) => {
      state.referralAddress = action.payload;
    },
    setIsValidReferral: (state, action) => {
      state.isValidReferral = action.payload;
    },
    // setDummy: (state, action) => {
    //   state.dummy = action.payload;
    //   localStorage.setItem('dummy', action.payload);
    //   // sessionStorage.setItem('dummy', action.payload); 
    // },
    setClickedBuyNode: (state, action) => {
      state.clickedBuyNode = action.payload;
    },
    setReceiverAddress: (state, action) => {
      state.receiverAddress = action.payload;
    },
    setSelectedCheckboxes: (state, action) => {
      const { index, value } = action.payload;
      state.selectedCheckboxes[index] = value;
    },
    clearSelectedCheckboxes: (state) => {
      state.selectedCheckboxes = {};
    },
  },
});

export const {
  incrementAmount,
  decrementAmount,
  incrementQuantity,
  decrementQuantity,
  setReferralAddress,
  setIsValidReferral,
  // setDummy,
  setClickedBuyNode,
  setReceiverAddress, 
  setSelectedCheckboxes,
   clearSelectedCheckboxes
} = dataSlice.actions;

export default dataSlice.reducer;



