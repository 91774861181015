const termsData = {
  terms1: {
    title: 'Terms and Conditions',
    sections: [
      {
        title: '',
        content: `In this document, referred to as the "Agreement," the terms and conditions that govern your access to and 
        utilization of the X Bull Run Protocol and website, which may be found at www.xbullrun.org, as well as any 
        subdomains that are linked with the Website, are outlined. You are required to give this Agreement a thorough 
        reading because it determines how you will use the Website. By accessing or using the Website, you are indicating
        that you have read, comprehended, and are willing to be bound by this Agreement in its entirety. You should not 
        use the website because you are not permitted to access or use it, and you should not use the website if you do 
        not agree.
        
        <br></br>
        
        NOTICE: <u>This Agreement includes a number of essential provisions, such as a class action waiver and a binding 
        arbitration provision, both of which have an effect on your rights regarding the manner in which disputes are 
        addressed. Only if you are totally in agreement with these conditions will you be able to visit the Website, and you 
        should only do so if you are ready to do so.</u>
        
        <br></br>
        
        It is within our sole discretion to make any necessary changes to this Agreement at any time, and we reserve the 
        right to do so. If we make any significant changes to the Agreement, we will let you know by revising the date that 
        appears at the top of the Agreement and by keeping a current version of the Agreement available at 

        <br></br>

        <a href="https://xbullrun.org/terms-of-service" style="color: #5555fc;">https://xbullrun.org/terms-of-service</a>.
        
        <br></br>
        
        Your continued access to or use of the website will constitute as confirmation that you accept the adjustments that 
        have been made, and all modifications will become effective as soon as they are posted. It is imperative that you 
        immediately cease accessing and using the Website if you do not agree with any modifications that have been 
        made to this Agreement.

        <br></br>
      <div>
        <div><u><b>X Bull Run use cases</b></u></div>
        In order to enable its users to construct and participate in decentralized pools without the need for third-party 
        custody of assets, X Bull Run Protocol is a decentralized distributed application that operates on a Polygon MATIC 
        blockchain network. This application makes use of smart contracts, each of which is referred to as a "Smart 
        Contract." Every participant is the owner of their assets and has complete control over them in a smart contract. 
        Participants are free to enter and leave pools at any moment without interruption<br></br>
       </div>

       <div>
        <div><b><u>Founder's Node</u></b></div>
        The XBR Founder's Node, v1 program is a smart contract that operates on the Polygon MATIC blockchain as an 
        Algorithmic Non-Fungible Token (ERC-721) software. This software serves the purpose of enabling the DAO project 
        to be completed. The XBR Founder's Node provides support for community-driven, secure, and rewarded nodes 
        that are providing power to the XBR Platform. Due to the fact that only 5000 of these licensed are available on our 
        website or through a third-party website, the DAO initiative assists the Foundation in voting on future changes that 
        will maintain the health of our ecosystem.
       </div>
        
        <br></br>

        <u>THE FOUNDER'S NODE ARE AWARE THAT PARTICIPATION IS RISKY AND THE PROJECT DOES NOT GUARANTEE ANY 
        FINANCIAL RETURNS</u>`
      },
    ]
  },
  terms2: {
    title: 'Founder’s Node Privacy Policy',
    sections: [
      {
        title: '',
        content: `
        This Privacy Policy describes how XBR Foundation Lab. (referred to as "XBR Protocol Labs", "the Foundation", "we", "us", or "our") collects, uses, and shares data in relation to the X Bull Run web app (https://buy.xbullrun.org/node), the www.xbullrun.org website, and all other properties, products, and services provided by us (collectively referred to as "the Services"). The use of the Services is governed by this Policy as well as our Terms of Service.

        <ul style="list-style-type: disc; margin-left: 20px; margin-Top: 15px;">
        <li style="margin-bottom: 10px; padding-left: 5px;">The organization prioritizes privacy in all of its activities. In addition, we have established transparency as one of our basic values as a foundation. For this reason, we attempt to be upfront and honest about the liable amount of data that we collect. In addition, we do not collect or save any personal information, such as your name or internet protocol (IP) address, and we do not save user accounts.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">We use the data we collect in line with the law and your instructions, as well as any applicable conditions in our terms of service.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">We reserve the right to disclose or provide access to the information that we collect; nevertheless, your personal information will not be disclosed to any third parties for any marketing purposes whatsoever.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">By utilizing services provided by Google and other third parties that make use of tracking technologies such as cookies, device ID, and local Storage, we are able to collect information regarding your utilization of the Services as well as our interactions with you. If you do not want these third-party services to gather data about your device and activity online, you have the option to decline their collection.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">Third parties power parts of our services. These external platforms have separate privacy policies and may independently gather your data. We maintain security measures but cannot guarantee full transmission security. You retain responsibility for wallet and key safety.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">We do not target minors under COPPA compliance. If you believe we have obtained a minor's data, email dispute@xbullrun.org so we can promptly delete it. Overall, use good judgment when sharing personal information.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">The California Consumer Privacy Act requires certain businesses to provide a privacy notice to California residents detailing how their personal information is collected, used, and shared. This CCPA notice also informs Californians of the rights and choices they have to access, delete, and limit sharing of their data.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">To exercise your GDPR rights regarding your personal data, email <a href="https://xbullrun.org/terms-of-service" style="color: #5555fc;">dispute@xbullrun.org</a>. Additional details may be needed to process requests. Note that information may still be retained as lawful interests even after requests, including for legal obligations, dispute resolution, fraud prevention, and contract enforcement. Please reach out with questions.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">We will notify you through our services if this policy undergoes significant changes. However, by continuing to use our offerings, you agree to review the latest terms and conditions regularly and consent to be bound by them. Ongoing usage signifies your acceptance of the most recent governance policies.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">If you have any questions about this privacy policy or how we gather, use, or share your data, feel free to email <a href="https://xbullrun.org/terms-of-service" style="color: #5555fc;">dispute@xbullrun.org</a>, and we will gladly address any concerns regarding our information collection and handling practices.</li>
        <li style="margin-bottom: 10px; padding-left: 10px;">This overview explains key points on how we collect, use, share and protect your personal information. For full details on data handling practices, disclosures, and your rights, please see the complete privacy policy at <a href="https://xbullrun.org/terms-of-service" style="color: #5555fc;">https://xbullrun.org/privacypolicy</a>.</li>
      </ul>
      
        `
      },
    ]
  },
  terms3: {
    title: "Founder’s Node Investment Notice ",
    sections: [
      {
        title: '',
        content: `XBR Founder's Nodes are not securities or investments guaranteeing returns, but rather 
        governance ANFTs maximum saving fee or no fee at all granting usage and voting rights on the 
        protocol. They enable early adopters to save on ecosystem fees long-term. For example, Pool 
        Creators can use Node governance power and discounted fees to better construct portfolios. 
        Participants can stake into those pools at lower cost or no fee. Nodes don't directly yield profits, 
        but allow holders to save on powering real utility. As adoption grows, having early governance 
        makes Nodes more valuable. Rather than ROI, Nodes enable active ecosystem usage and 
        influence for committed early supporters
        
        <br></br>

        As X Bull Run ecosystem fees are paid in XBR tokens, Founder's Nodes partake in mining 70% of 
        the maximum supply over 5 years. Minted tokens can be used by Node holders to create pools 
        with discounted fees or participate in other pools to gain fractional ownership of crypto baskets -
        aligning incentives between early adopters supporting the network and users driving utility.

        <br></br>

        Purchasing an XBR Founder's Node is non-refundable regardless of jurisdiction. By acquiring a 
        Node, you agree no refunds will be provided and that all sales are final per this no-refund policy
        
        `
      },
    ]
  }
};

export default termsData;
