import React, { useEffect, useState, useRef } from "react";
import { Toaster, toast } from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { ReactComponent as SwapVertical } from "../assets/swapverti.svg";
import tabBgVideo from "../assets/SwapbgVideo.mp4";
import { MdKeyboardArrowRight } from "react-icons/md";
import {
  XBR,
  USDT,
  layer,
  RightArrow,
  ViewAllDetails,
  LightmodeUSDT,
  LightmodeXBR,
  NewCheck,
  tba,
} from "../assets/index.js";

import axios from "axios";

import {
  formatUnits,
  parseUnits,
  Contract,
  BrowserProvider,
  isHexString,
} from "ethers";
import { useTheme } from "@mui/material";
import { getTokenTransferApprovalFromRouter } from "../uniswap/swap.js";
import { USDT_TOKEN, XBR_TOKEN, TBA_TOKEN, ERC20_ABI } from "../web3Utils.js";
import PopUpDialog from "../components/popUp.jsx";
import ErrorPopUPDialog from "../components/errorPopUp.jsx";
import { toastType } from "../constants/Data.jsx";
import {
  createSwap,
  fetchTokenAddress,
  getQuotes,
  shortenTransactionHash,
} from "../uniswap/utils.js";
import { findPoolAddress } from "../uniswap/pool.js";
import TradesTable from "../components/trade/tradeTable.js";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { subscribeToProviders, getProviders } from "../uniswap/farm.js";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import PathToSuccess from "../components/PathToSuccess.jsx";
import LatestTrades from "../components/LatestTrades.jsx";
import FailedTransaction from "../components/FailedTransaction.jsx";
import SuccessfullSwap from "../components/SuccessfullSwap.jsx";

const NewSwapComponent = (props) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { fetchUsdtPrice } = props;
  const [swapUsdtValue, setSwapUsdtValue] = useState("");
  const [swapXbrValue, setSwapXbrValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeTab, setActiveTab] = useState("latest");
  const [usdtBalance, setUsdtBalance] = useState("0.00");
  const [xbrBalance, setXbrBalance] = useState("0.00");
  const [swapButton, setSwapButton] = useState(false);
  const [slippageTolerance, setSlippageTolerance] = useState(0.5);
  const [inputSlippageTolerance, setInputSlippageTolerance] = useState("");
  const [approve, setApprove] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [trade, setTrade] = useState(null);
  const [isOpenSlippage, setIsOpenSlippage] = useState(false);
  const [usdt1, setUsdt1] = useState("0.00");
  const [swapConfirmModal, setSwapConfirmModal] = useState(false);
  const [swapdata, setSwapdata] = useState("");
  const [swapTransactionFailModal, setSwapTransactionFailModal] =
    useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);

  const columnsConfig = [
    { name: "date", title: "Date" },
    { name: "type", title: "Type" },
    { name: "usd", title: "USD" },
    { name: "xbr", title: "XBR" },
    { name: "usdt", title: "USDT" },
    { name: "price", title: "Price" },
  ];

  const columnWidths = [
    { columnName: "date", width: 180 },
    { columnName: "type", width: 180 },
    { columnName: "usd", width: 180 },
    { columnName: "xbr", width: 180 },
    { columnName: "usdt", width: 180 },
    { columnName: "price", width: 180 },
  ];

  const connectedAccount = localStorage.getItem("connectedAccount");

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSwap, setIsSwap] = useState(false);
  const [usdtPrice, setUsdtPrice] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");

  const [holdersCount, setHoldersCount] = useState("0");
  const [liquidity, setLiquidity] = useState("85.4K");
  const [totalSupply, setTotalSupply] = useState("1,694");
  const [fdv, setFdv] = useState("139.7k");
  const [volume24h, setVolume24h] = useState("39.7K");
  const [sendValue, setSendValue] = useState("");
  const [receiveValue, setReceiveValue] = useState("");
  const [showInputValue, setShowInputValue] = useState(false);
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);

  const handlePopClose = () => {
    setOpen(false);
    setTrade(null);
    setOpen1(false);
    setOpen3(false);
  };

  const viewTransaction = (txHash) => {
    setOpen(false);
    setTrade(null);
    setOpen1(false);
    if (isHexString(txHash, 32)) {
      window.open(`https://polygonscan.com/tx/${txHash}`, "_blank");
    } else {
      alert("Invalid transaction hash");
    }
  };

  const [swapTbaValue, setSwapTbaValue] = useState("0");
  const [tbaBalance, setTbaBalance] = useState("1.111");
  const handleSlippageChange = (value) => {
    // If the input is empty, set slippageTolerance to 0
    if (value === "") {
      setInputSlippageTolerance("");
      setSlippageTolerance(0);
      return;
    }

    // Ensure the input value is a number and within the allowed range (0 to 15)
    const regex = /^\d{0,2}(\.\d{0,2})?$/;

    // Check if the input matches the regex and is within the allowed range (0 to 15)
    if (regex.test(value) && parseFloat(value) <= 15) {
      setInputSlippageTolerance(value);

      // If the input is a valid number, update the slippage tolerance state
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setSlippageTolerance(parsedValue);
      } else {
        setSlippageTolerance(0);
      }
    }
  };

  const getTextColor = (kind) => (kind === "buy" ? "green" : "red");

  const handleInputChange = (event) => {
    let value = event.target.value;

    // Remove any non-numeric characters except minus sign (-)
    value = value.replace(/[^0-9.-]/g, "");

    // Prevent negative values
    if (value.startsWith("-")) {
      value = "";
    }

    // Check if value exceeds 15
    if (value === "" || parseFloat(value) === 0) {
      setInputSlippageTolerance(value); // Update input value
      setSlippageTolerance(0); // Update state with 0
      return; // Exit early to prevent setting NaN
    }

    // Parse the value to float
    value = parseFloat(value);

    if (!isNaN(value) && value > 15) {
      value = 15;
    }

    setInputSlippageTolerance(value.toString()); // Update input value
    setSlippageTolerance(value); // Update state
  };

  const fetchBalances = async () => {
    try {
      if (!window.ethereum) {
        throw new Error("Please install MetaMask");
      }

      let signer;
      let provider;
      if (walletProvider) {
        console.log("Connected to Wallet Connect", "Provider", walletProvider);
        provider = new BrowserProvider(walletProvider);
        signer = await provider?.getSigner();
      } else {
        console.log("connecting without walletConnect!");
        await subscribeToProviders();
        const providers = await getProviders();
        const wallet = localStorage.getItem("walletName");

        const providerWithInfo = await providers.find(
          (provider) => provider.info.name === wallet
        );
        console.log("removeLiquidity-connectWallet", providerWithInfo);
        provider = new BrowserProvider(providerWithInfo?.provider);
        signer = await provider?.getSigner();
      }

      const address = await signer.getAddress();

      const usdcContract = new Contract(USDT_TOKEN.address, ERC20_ABI, signer);
      const xbrContract = new Contract(XBR_TOKEN.address, ERC20_ABI, signer);
      const tbaContract = new Contract(TBA_TOKEN.address, ERC20_ABI, signer);

      const usdcBalance = formatUnits(await usdcContract.balanceOf(address), 6);

      const xbrBalance = formatUnits(await xbrContract.balanceOf(address), 18);

      const tbaBalance = formatUnits(await tbaContract.balanceOf(address), 18);

      setUsdtBalance(usdcBalance);
      setXbrBalance(xbrBalance);
      setTbaBalance(tbaBalance);
      console.log({ usdcBalance: usdcBalance, xbrBalance: xbrBalance });
      return { usdcBalance, xbrBalance };
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const formatBalance = (balance) => {
    // Ensure the balance is treated as a number
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (num >= 1e3) {
      return (Math.floor(num / 1e2) / 10).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return num.toFixed(3).replace(/\.00$/, ""); // Output as a string with two decimal places
    }
  };

  const formatBalanceForPrice = (balance) => {
    console.log("formatBalanceForPrice", balance);
    // Ensure the balance is treated as a number
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return num.toFixed(6).replace(/\.?0+$/, ""); // Output as a string without unnecessary trailing zeros
    }
  };

  const validateInput = (value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    ) {
      return false; // Return false for null or undefined values
    }
    const regex = /^[0-9]*\.?[0-9]*$/; // Regular expression to match numbers with optional decimal point
    return regex.test(value);
  };
  function checkTokenBalance(tokenName, balance) {
    const validTokens = {
      XBR: "0.00",
      USDT: "0.00",
      TBA: "0.00",
    };

    if (validTokens[tokenName] && balance === validTokens[tokenName]) {
      setOpen3(true);
      setTimeout(handlePopClose, 3000);
      return true;
    }

    return false;
  }
  const approveForSwap = async (selectedOption) => {
    console.log("selectedToken.name", selectedToken.name);
    if (!window.ethereum) {
      toast.error("Please install or connect MetaMask", { duration: 500 });
      return;
    }
    if (
      checkTokenBalance(selectedToken.name, xbrBalance) ||
      checkTokenBalance(selectedToken.name, usdtBalance) ||
      checkTokenBalance(selectedToken.name, tbaBalance)
    ) {
      return;
    }
    setIsLoading(true);
    setIsApproving(true);
    try {
      console.log("Approve for Swap", selectedOption);
      const value = sendValue;
      let isToastDisplayed = false;

      if (
        value === null ||
        value === undefined ||
        value === "" ||
        value === 0 ||
        value === "0"
      ) {
        if (!isToastDisplayed) {
          isToastDisplayed = true;
          toast.error("Please enter a valid amount", {
            duration: 2000,
            onClose: () => {
              isToastDisplayed = false;
            },
          });
        }
        return;
      }
      console.log("Arge Value", value); 
      
      // const selectedToken = selectedOption === "USDTx" ? USDT_TOKEN : XBR_TOKEN;
      const unit =
        selectedOption === "TBA" || selectedOption === "XBR" ? 18 : 6;

      const approve = await getTokenTransferApprovalFromRouter(
        // selectedOption === "USDT" && selectedToken2.name === "XBR"
        //   ? "USDTx"
        //   : selectedOption,
        selectedOption,
        parseUnits(value.toString(), unit),
        walletProvider
      );
      console.log("approve", approve);
      if (approve === "Failed") {
        setOpen1(true);
        setTimeout(handlePopClose, 15000);
      }
      if (approve !== "Failed" && approve !== null && approve !== undefined) {
        console.log("Called Approve Swap Pop Up");
        setApprove(true);
        setIsLoading(false);
        setIsApproving(false);
      }
      return approve;
    } catch (error) {
      setIsApproving(false);
      setIsLoading(false);
      console.error(error);
      throw error;
    }
  };

  const swap = async (selectedOption) => {
    setIsSwapping(true);
    try {
      console.log("Swap");
      // let [firstToken, secondToken, firstAmount, secondAmount] =
      //   selectedToken.name === "TBA"
      //     ? ["TBA", "USDT", sendValue, receiveValue]
      //     : selectedToken2.name === "TBA"
      //     ? ["USDT", "TBA", sendValue, receiveValue]
      //     : [
      //         selectedToken.name === "USDT" ? "USDTx" : selectedToken.name,
      //         selectedToken2.name === "USDT" ? "USDTx" : selectedToken2.name,
      //         sendValue,
      //         receiveValue,
      //       ];

      // let [firstToken, secondToken, firstAmount, secondAmount] =
      //   selectedToken.name === "TBA" && selectedToken2.name === "USDT"
      //     ? ["TBA", "USDT", sendValue, receiveValue]
      //     : selectedToken.name === "USDT" && selectedToken2.name === "TBA"
      //     ? ["USDT", "TBA", sendValue, receiveValue]
      //     : [
      //         selectedToken.name === "USDT" ? "USDTx" : selectedToken.name,
      //         selectedToken2.name === "USDT" ? "USDTx" : selectedToken2.name,
      //         sendValue,
      //         receiveValue,
      //       ];
      // CHANGE FOR ACTUAL WORKING
      let [firstToken, secondToken, firstAmount, secondAmount] = [
        selectedToken.name,
        selectedToken2.name,
        sendValue,
        receiveValue,
      ];
      console.log("firstToken:", firstToken, "secondToken:", secondToken);
      console.log("firstAmount:", firstAmount, "secondAmount:", secondAmount);

      if (!validateInput(firstAmount) || !validateInput(secondAmount)) {
        toast.error("Please enter a valid amount", {
          duration: 1000,
        });
        return;
      }

      // const trade = await executeTrade(
      //   firstToken,
      //   secondToken,
      //   Number(firstAmount),
      //   Number(secondAmount),
      //   slippageTolerance
      // );

      const createSwapRespone = await createSwap(
        firstToken,
        secondToken,
        Number(firstAmount),
        Number(secondAmount),
        slippageTolerance,
        walletProvider
      );

      console.log("TRADE", createSwapRespone);
      setSwapdata(createSwapRespone);
      if (createSwapRespone === "Failed") {
        setIsSwapping(false);
        setOpen1(true);
        setTimeout(handlePopClose, 15000);
      }
      if (
        createSwapRespone !== "Failed" &&
        createSwapRespone !== null &&
        createSwapRespone !== undefined
      ) {
        setSwapConfirmModal(true);
        console.log("Called Swap Pop Up");
        const tradeObj = {
          transactionHash: createSwapRespone,
          firstAmount,
          secondAmount,
        };
        setTrade(tradeObj);
        setIsSwap(true);
        setOpen(true);
        setTimeout(() => {
          handlePopClose();
          setIsSwap(false);
          setIsSwapping(false);
          setSwapConfirmModal(false);
          setApprove(false);
          setSendValue("");
          setReceiveValue("");
        }, 15000);
      }
    } catch (error) {
      console.log("Error Swap Pop Up", error);
      setIsSwapping(false);
      setErrorMsg(error.message);
      setOpen1(true);
      setSwapTransactionFailModal(true);
      setTimeout(handlePopClose, 15000);
      console.error(error);
      throw error;
    }
  };

  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const grey = theme.palette.background.grey;
  const querty = theme.palette.disabeled.back;
  const quertyColor = theme.palette.disabeledColor.backColor;

  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const compareNumeric = (a, b, orderBy) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    // Function to close dropdown when clicked outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsOpen2(false);
      }
    };

    // Adding event listener to the document body
    document.addEventListener("mousedown", handleClickOutside);

    // Cleaning up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const cardStyle =
    theme.palette.mode === "light"
      ? {
          backgroundColor: "white", // Assuming grey is defined correctly in your theme
          color: theme.palette.text.primary,
        }
      : {
          backgroundColor: "#02042C", // Dark mode or other default style
          color: theme.palette.text.primary,
        };
  const columns = [
    { Header: "Date", accessor: "date" },
    { Header: "Quantity (LPT)", accessor: "lpt" },
    { Header: "Quantity (USDT)", accessor: "usdt" },
    { Header: "Tx.Hash", accessor: "txHash" },
  ];
  function formatValue(value) {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + "K";
    } else {
      return value.toFixed(2);
    }
  }
  const XBRIconSrc = theme.palette.mode === "light" ? LightmodeXBR : XBR;
  const USDTIconSrc = theme.palette.mode === "light" ? LightmodeUSDT : USDT;
  const tokens = [
    { id: "XBR", name: "XBR", image: XBRIconSrc },
    { id: "USDT", name: "USDT", image: USDTIconSrc },
    { id: "TBA", name: "TBA", image: tba },
  ];

  const [selectedToken, setSelectedToken] = useState(tokens[1]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedToken2, setSelectedToken2] = useState(tokens[0]);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);

  const newOnChangeSwapInputs = async (value, type, data, type2) => {
    console.log("value:", value, "type:", type, "type2:", type2);

    if (!value || value === undefined || value === null || value === "") {
      setSendValue("");
      setReceiveValue("");
      setIsInsufficientBalance(false);
      return;
    }

    const regex = /^(?!-)[0-9]*[.]?[0-9]*$/;

    try {
      const balanceMap = {
        XBR: xbrBalance,
        USDT: usdtBalance,
        TBA: tbaBalance,
      };

      const balance =
        type === selectedToken.name ? parseFloat(balanceMap[type]) : 0;

      // Check if the entered value exceeds the balance
      if (parseFloat(value) > balance) {
        setIsInsufficientBalance(true);
      } else {
        setIsInsufficientBalance(false);
      }

      if (data === 1) {
        if (regex.test(value)) {
          setSendValue(value);
          const result =
            value !== "" && value > parseFloat(0)
              ? await getQuotes(type, type2, value, walletProvider)
              : "";
          setReceiveValue(result);
        }
      } else {
        if (regex.test(value)) {
          setReceiveValue(value);
          const result =
            value !== "" && value > parseFloat(0)
              ? await getQuotes(type, type2, value, walletProvider)
              : "";
          setSendValue(result);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFormattedBalance = (name) => {
    const balanceMap = {
      XBR: `${formatBalanceForPrice(parseFloat(xbrBalance))}`,
      USDT: `${formatBalanceForPrice(parseFloat(usdtBalance))}`,
      TBA: `${formatBalanceForPrice(parseFloat(tbaBalance))}`,
    };
    return balanceMap[name] || "0.00";
  };
  const swapTokens = (value) => {
    if (value === 1) {
      newOnChangeSwapInputs(
        receiveValue,
        selectedToken2.name,
        1,
        selectedToken.name
      );
      const temp = selectedToken;
      setSelectedToken(selectedToken2);
      setSelectedToken2(temp);
    } else {
      newOnChangeSwapInputs(
        receiveValue,
        selectedToken2.name,
        2,
        selectedToken.name
      );
      const temp = selectedToken2;
      setSelectedToken2(selectedToken);
      setSelectedToken(temp);
    }
  };
  const newSwapOptions = async () => {
    await newOnChangeSwapInputs(
      receiveValue,
      selectedToken2.name,
      1,
      selectedToken.name
    );
    const temp = selectedToken;
    setSelectedToken(selectedToken2);
    setSelectedToken2(temp);
  };
  const newMax = async (type, inputType) => {
    try {
      const balances = await fetchBalances();
      const balanceMap = {
        XBR: balances.xbrBalance,
        USDT: balances.usdcBalance,
        TBA: tbaBalance,
      };

      const balance = balanceMap[type] || "0.00";

      await newOnChangeSwapInputs(
        balance,
        type,
        inputType,
        type === selectedToken.name ? selectedToken2.name : selectedToken.name
      );
    } catch (error) {
      console.error("Error getting max balances", error);
    }
  };
  const forbiddenPairs = [
    { pair1: "XBR", pair2: "TBA" },
    { pair1: "TBA", pair2: "XBR" },
  ];

  const isForbiddenPair = (tokenA, tokenB) => {
    return forbiddenPairs.some(
      (pair) =>
        (pair.pair1 === tokenA && pair.pair2 === tokenB) ||
        (pair.pair1 === tokenB && pair.pair2 === tokenA)
    );
  };
  const handleTokenChange = (token, isFirst) => {
    if (isFirst) {
      console.log("first===");
      if (isForbiddenPair(token.name, selectedToken2.name)) {
        toast.error("This pair is not allowed", toastType);
        return;
      }
      setSelectedToken(token);
      if (sendValue) {
        console.log("sendValu=", sendValue, "token", token);
        newOnChangeSwapInputs(sendValue, token.name, 1, selectedToken2.name);
      }
    } else {
      console.log("first===");
      if (isForbiddenPair(selectedToken.name, token.name)) {
        toast.error("This pair is not allowed", toastType);
        return;
      }
      setSelectedToken2(token);
      if (receiveValue) {
        console.log("sendValu=", sendValue, "token", token);
        newOnChangeSwapInputs(receiveValue, token.name, 2, selectedToken.name);
      }
    }
  };

  const fetchMarketData = async () => {
    try {
      console.log("fetchMarketData", selectedToken.name, selectedToken2.name);
      const token0 = await fetchTokenAddress(
        selectedToken.name,
        walletProvider
      );
      const token1 = await fetchTokenAddress(
        selectedToken2.name,
        walletProvider
      );
      const { poolAddress } = await findPoolAddress(
        token0.address,
        token1.address,
        walletProvider
      );
      console.log("poolAddress", poolAddress);
      const URL = process.env.REACT_APP_API;
      const responses = await axios.get(`${URL}/v1/xbr/coin-price?`, {
        params: {
          address: poolAddress,
          count: 157860000,
        },
      });

      const coinMarketCapData = await responses.data.response
        .coinMarketCapResponseData.data;
      const poolInfoHolders = formatBalance(
        coinMarketCapData.poolInfoD.holders
      );
      console.log("poolInfoHolders", coinMarketCapData.poolInfoD.holders);
      const liquidity = formatBalance(coinMarketCapData?.poolInfoD.liquidity);
      const totalSupply = coinMarketCapData?.totalSupply;
      const fdv = formatBalance(coinMarketCapData?.fdv);
      const volume24h = formatBalance(coinMarketCapData?.volume24h);
      const price = await coinMarketCapData?.priceUsd;
      console.log("SelectedPrice", price);

      setSelectedPrice(price);
      setHoldersCount(poolInfoHolders);
      setLiquidity(liquidity);
      setTotalSupply(formatBalance(totalSupply));
      setFdv(fdv);
      setVolume24h(volume24h);
      // console.log("coinMarketResponse", {
      //   poolInfoHolders,
      //   liquidity,
      //   totalSupply,
      //   fdv,
      //   volume24h,
      // });
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  const fetchMarketDataDefault = async () => {
    try {
      const poolAddress = "0x1eb8DFe76eE03Fd68Aa58f849CAD5590B4D12617";
      const URL = process.env.REACT_APP_API;
      const responses = await axios.get(`${URL}/v1/xbr/coin-price?`, {
        params: {
          address: poolAddress,
          count: 157860000,
        },
      });

      const coinMarketCapData = await responses.data.response
        .coinMarketCapResponseData.data;
      const poolInfoHolders = formatBalance(
        coinMarketCapData.poolInfoD.holders
      );
      console.log("poolInfoHolders", coinMarketCapData.poolInfoD.holders);
      const liquidity = formatBalance(coinMarketCapData?.poolInfoD.liquidity);
      const totalSupply = coinMarketCapData?.totalSupply;
      const fdv = formatBalance(coinMarketCapData?.fdv);
      const volume24h = formatBalance(coinMarketCapData?.volume24h);
      const price = await coinMarketCapData?.priceUsd;
      console.log("SelectedPrice", price);

      setSelectedPrice(price);
      setHoldersCount(poolInfoHolders);
      setLiquidity(liquidity);
      setTotalSupply(formatBalance(totalSupply));
      setFdv(fdv);
      setVolume24h(volume24h);
      // console.log("coinMarketResponse", {
      //   poolInfoHolders,
      //   liquidity,
      //   totalSupply,
      //   fdv,
      //   volume24h,
      // });
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  const fetchUSDTPrice = async () => {
    const price = await fetchUsdtPrice();
    console.log("PRICE", await price);
    setUsdtPrice(await price);
  };

  const fetchPrice = async (address) => {
    const URL = `${process.env.REACT_APP_API}/v1/xbr/token-prices?address=${address}`;
    const response = await axios.get(URL);
    return response?.data?.data?.attributes?.price_usd;
  };

  function formatTbaPrice(price) {
    // Ensure the price is a number
    if (typeof price !== "number" || isNaN(price)) return "NaN";

    // Convert the price to a string in fixed-point notation to ensure precision
    let priceStr = price.toFixed(25); // Use enough precision to capture all significant digits

    // Find the index of the decimal point
    let decimalIndex = priceStr.indexOf(".") + 1;

    // Find the index of the first non-zero digit after the decimal point
    let firstNonZeroIndex = decimalIndex;
    while (
      priceStr[firstNonZeroIndex] === "0" &&
      firstNonZeroIndex < priceStr.length
    ) {
      firstNonZeroIndex++;
    }

    // Count the number of leading zeroes
    let zeroCount = firstNonZeroIndex - decimalIndex;

    // Get the significant digits part (adjust the length as needed)
    let significantPart = priceStr.substring(
      firstNonZeroIndex,
      firstNonZeroIndex + 3
    );

    // Construct the formatted string
    return `0.0(${zeroCount})${significantPart}`;
  }

  const fetch1USDT = async () => {
    const quotes = await getQuotes(
      selectedToken.name,
      selectedToken2.name,
      1,
      walletProvider
    );
    if(selectedToken.name === "TBA"){
      const TBA = await fetchPrice("0x7e1AE068Ac4e0ffD7a84750c2FB093A0EAE9aEa2");
      setUsdt1(formatTbaPrice(Number(TBA)));
    }else{
      setUsdt1(formatBalanceForPrice(quotes));
    }}

  useEffect(() => {
    fetchUSDTPrice();
  }, []);

  useEffect(() => {
    fetchMarketData();
    fetchBalances();
    fetch1USDT();
    if (holdersCount === "0") {
      fetchMarketDataDefault();
    }
  }, [newOnChangeSwapInputs, selectedToken, selectedToken2]);

  useEffect(()=>{
    setApprove(false);
  },[sendValue, receiveValue])

  function safeParseNumber(value) {
    const number = Number(value);
    return isNaN(number) ? 0 : number;
  }
  return (
    <div>
      <div
        className="relative max-w-[30.5rem] mx-auto p-4"
        style={{
          background:
            theme.palette.mode === "light"
              ? "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)"
              : "linear-gradient(180deg, #050840 0%, rgba(5, 8, 64, 0.00) 100%)",
        }}
      >
        {(swapConfirmModal || swapTransactionFailModal) && (
          <div
            className={`fixed inset-0 bg-black z-50 ${
              theme.palette.mode === "light" ? "opacity-30" : "opacity-60"
            }`}
            onClick={() => {
              setSwapConfirmModal(false);
              setSwapTransactionFailModal(false);
            }}
          ></div>
        )}

        <div className={`relative`}>
          <div
            className={`relative w-full  ${
              theme.palette.mode === "light" ? "bg-white" : ""
            } mobile:rounded-xl  rounded-3xl flex flex-col items-center justify-center mobile:p-2 p-4`}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute top-0 left-0 w-full h-full object-cover mobile:rounded-xl rounded-3xl z-0 opacity-30"
              src={tabBgVideo}
            >
              Your browser does not support the video tag.
            </video>
            <div className="flex flex-col items-start justify-start gap-[8px] w-full relative">
              {/* Card 1 */}
              <div
                style={cardStyle}
                className={`flex justify-between rounded-2.5 mobile:rounded-2.5 flex-row items-center p-4 z-20 w-full ${
                  isInsufficientBalance && connectedAccount
                    ? "border border-[#CE4954]"
                    : ""
                }`}
              >
                <div className="flex flex-col w-full">
                  {/* "Send" Label */}
                  <div className="flex justify-between">
                    <div
                      className={`leading-[1.5rem] text-[16px] ${
                        theme.palette.mode === "light"
                          ? "text-[#69696F]"
                          : "text-[#97979B]"
                      }`}
                    >
                      Send
                    </div>
                    {isInsufficientBalance && connectedAccount && (
                      <div className="text-[#CE4954] text-[16px]">
                        Insufficient Balance
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row justify-between items-center w-full">
                    {/* Token Selection and Balance */}
                    <div className="flex flex-col items-start justify-center gap-1">
                      <div className="relative" ref={dropdownRef1}>
                        <button
                          onClick={() => setIsOpen(!isOpen)}
                          className={`flex items-center justify-between px-4 py-3 w-32 ${
                            theme.palette.mode === "light"
                              ? "bg-lightgray-400"
                              : "bg-gray-400"
                          } rounded-xl border border-gray-300 gap-2`}
                        >
                          <div className="flex items-center justify-center gap-2">
                            <img
                              src={selectedToken.image}
                              alt={selectedToken.name}
                              className="w-6 h-6 object-cover"
                            />
                            <span>{selectedToken.name}</span>
                          </div>
                          {isOpen ? (
                            <ChevronUpIcon className="ml-[10px] w-5 h-5" />
                          ) : (
                            <ChevronDownIcon className="ml-[10px] w-5 h-5" />
                          )}
                        </button>
                        {isOpen && (
                          <div
                            className={`absolute w-full mt-1 ${
                              theme.palette.mode === "light"
                                ? "bg-lightgray-400 "
                                : " bg-[#02042C] text-white"
                            }  border border-gray-300 rounded-xl z-50`}
                          >
                            {tokens.map((currency) => (
                              <div
                                key={currency.id}
                                className={`px-4 rounded-xl py-2 ${
                                  theme.palette.mode === "light"
                                    ? "hover:bg-lightgray-200"
                                    : " hover:bg-slate-900"
                                }  flex items-center gap-2 cursor-pointer`}
                                onClick={() => {
                                  if (currency.id === selectedToken2.id) {
                                    swapTokens(1);
                                  } else {
                                    handleTokenChange(currency, true);
                                  }
                                  setIsOpen(false);
                                }}
                              >
                                <img
                                  src={currency.image}
                                  alt={currency.name}
                                  className="w-6 h-6 object-cover"
                                />
                                <span>{currency.name}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                        <div
                          className={` ${
                            theme.palette.mode === "light"
                              ? "text-[#69696F]"
                              : "text-[#97979B]"
                          } whitespace-nowrap mobile:text-ft1_5  text-ft5 flex gap-1`}
                        >
                          <span>
                            Balance: {getFormattedBalance(selectedToken.name)}
                          </span>
                          <div
                            className="text-ft5 font-bold text-[#747FF4]"
                            style={{ cursor: "pointer" }}
                            onClick={() => newMax(selectedToken.name, 1)}
                          >
                            Max
                          </div>
                        </div>
                    </div>

                    {/* Send Value Input */}
                    <div className="flex flex-col items-end justify-center gap-4 text-right mt-2">
                      <input
                        className={`
                        relative bg-[#02042C] text-right ${
                          theme.palette.mode === "light"
                            ? "text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
                            : "text-white"
                        } 
                        outline-none font-inter text-3xl md:text-ft8 w-30 md:w-80per font-bold placeholder-white 
                        ${
                          isInsufficientBalance
                            ? "border-red-500 text-red-500"
                            : ""
                        }
                      `}
                        placeholder="0.00"
                        type="text"
                        onWheel={(e) => e.preventDefault()}
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(
                            /[^0-9.]/g,
                            ""
                          );
                          newOnChangeSwapInputs(
                            inputValue,
                            selectedToken.name,
                            1,
                            selectedToken2.name
                          );
                        }}
                        value={sendValue}
                      />

                        <div
                          className={` ${
                            theme.palette.mode === "light"
                              ? "text-gray-10"
                              : "text-gray-50"
                          } text-ft5`}
                        >
                          ≈ $
                          {selectedToken.name === "USDT"
                            ? formatBalanceForPrice(
                                safeParseNumber(sendValue) *
                                  safeParseNumber(usdtPrice)
                              )
                            : formatBalanceForPrice(
                                safeParseNumber(sendValue) *
                                  safeParseNumber(selectedPrice)
                              )}
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>

              {/* Swap Image */}
              <div className="absolute top-[calc(50%-1.5rem)] left-1/2 transform -translate-x-1/2 flex items-center justify-center z-20">
                {/* Outer Circle */}
                <div
                  className={`w-[3rem] h-[3rem] rounded-full flex items-center justify-center ${
                    theme.palette.mode === "light"
                      ? "bg-[#D6DAFC]"
                      : "bg-[#14174F]"
                  }`}
                >
                  {/* Inner Circle */}
                  <div
                    className={`w-[2rem] h-[2rem] rounded-full flex items-center justify-center cursor-pointer ${
                      theme.palette.mode === "light"
                        ? "bg-white"
                        : "bg-[#02042C]"
                    }`}
                  >
                    <SwapVertical
                      className={`${
                        theme.palette.mode === "light"
                          ? "text-[#34343C]"
                          : "text-white"
                      }`}
                      onClick={() => newSwapOptions()}
                    />
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div
                style={cardStyle}
                className={`rounded-2.5 flex flex-col items-center justify-between p-4 w-full z-10`}
              >
                {/* "Receive" Label */}
                <div
                  className={`leading-[1.5rem] w-full text-left text-[16px] ${
                    theme.palette.mode === "light"
                      ? "text-[#69696F]"
                      : "text-[#97979B]"
                  }`}
                >
                  Receive
                </div>

                <div className="flex flex-row justify-between items-center w-full">
                  {/* Token Selection and Balance */}
                  <div className="flex flex-col items-start justify-center gap-1 w-1/2">
                    <div className="relative" ref={dropdownRef2}>
                      <button
                        onClick={() => setIsOpen2(!isOpen2)}
                        className={`flex items-center justify-between px-4 py-3 w-32 ${
                          theme.palette.mode === "light"
                            ? "bg-lightgray-400"
                            : "bg-gray-400"
                        } rounded-xl border border-gray-300 gap-2`}
                      >
                        <div className="flex items-center justify-center gap-2">
                          <img
                            src={selectedToken2.image}
                            alt={selectedToken2.name}
                            className="w-6 h-6 object-cover"
                          />
                          <span>{selectedToken2.name}</span>
                        </div>
                        {isOpen2 ? (
                          <ChevronUpIcon className="ml-[10px] w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="ml-[10px] w-5 h-5" />
                        )}
                      </button>
                      {isOpen2 && (
                        <div
                          className={`absolute w-full mt-1 ${
                            theme.palette.mode === "light"
                              ? "bg-lightgray-400 "
                              : " bg-[#02042C]  text-white"
                          }  border border-gray-300 rounded-xl z-50`}
                        >
                          {tokens.map((currency) => (
                            <div
                              key={currency.id}
                              className={`px-4 rounded-xl py-2 ${
                                theme.palette.mode === "light"
                                  ? "hover:bg-lightgray-200"
                                  : " hover:bg-slate-900"
                              }  flex items-center gap-2 cursor-pointer`}
                              onClick={() => {
                                if (currency.id === selectedToken.id) {
                                  swapTokens(2);
                                } else {
                                  handleTokenChange(currency, false);
                                }
                                setIsOpen2(false);
                              }}
                            >
                              <img
                                src={currency.image}
                                alt={currency.name}
                                className="w-6 h-6 object-cover"
                              />
                              <span>{currency.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                      <div
                        className={` ${
                          theme.palette.mode === "light"
                            ? "text-[#69696F]"
                            : "text-[#97979B]"
                        } whitespace-nowrap mobile:text-ft1_5  text-ft5 flex gap-1`}
                      >
                        <span>
                          Balance: {getFormattedBalance(selectedToken2.name)}
                        </span>
                        <div
                          className="text-ft5 font-bold text-[#747FF4]"
                          style={{ cursor: "pointer" }}
                          onClick={() => newMax(selectedToken2.name, 2)}
                        >
                          Max
                        </div>
                      </div>
                  </div>

                  {/* Input and Calculated Value */}
                  <div className="flex flex-col items-end justify-center gap-4 text-right w-1/2 mt-2">
                    <input
                      style={cardStyle}
                      className={`
          relative bg-[#02042C] ${`${
            theme.palette.mode === "light"
              ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
              : "  text-white"
          }`} text-right text-white outline-none
          font-inter text-3xl md:text-ft8
          w-36 md:w-80per
          font-bold                   
          placeholder-white               
        `}
                      placeholder="0.00"
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                        newOnChangeSwapInputs(
                          inputValue,
                          selectedToken2.name,
                          2,
                          selectedToken.name
                        );
                      }}
                      value={receiveValue}
                    />
                      <div
                        className={` ${
                          theme.palette.mode === "light"
                            ? "text-[#69696F]"
                            : "text-[#97979B]"
                        } text-ft5`}
                      >
                        ≈ $
                        {selectedToken2.name === "USDT"
                          ? formatBalanceForPrice(
                              safeParseNumber(receiveValue) *
                                safeParseNumber(usdtPrice)
                            )
                          : formatBalanceForPrice(
                              safeParseNumber(receiveValue) *
                                safeParseNumber(selectedPrice)
                            )}
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mx-auto gap-5 w-full mt-4 z-0">
              <div
                className={`${
                  theme.palette.mode === "light"
                    ? "bg-white"
                    : "bg-[#02042C] text-[white]"
                } flex flex-col justify-between w-full p-4 items-center rounded-[16px] overflow-hidden`}
                style={{
                  maxHeight: isOpenSlippage ? "150px" : "60px",
                  transition: "max-height 0.5s ease",
                }}
              >
                <div className="flex justify-between items-center w-full mb-4">
                  <div
                    className={`text-ft font-paragraph-p1-regular ${
                      theme.palette.mode === "light"
                        ? "text-[#69696F]"
                        : "text-[#97979B]"
                    }`}
                  >
                    Max. Slippage
                  </div>
                  <div className="flex gap-1 flex-row items-center justify-between md:justify-normal">
                    {/* Button to toggle slippage options */}
                    <div className="relative w-full flex items-center">
                      <button
                        onClick={() => setIsOpenSlippage(!isOpenSlippage)}
                        className={`py-1 px-3 font-semibold rounded-[24px] outline-none text-center w-full hover:cursor-pointer 
              ${
                theme.palette.mode === "light"
                  ? "bg-[#EEEFFE]  text-[#181821]"
                  : "bg-[#181B56]"
              } flex justify-between items-center`}
                      >
                        {slippageTolerance}%
                        <span className="ml-2">
                          {isOpenSlippage ? <FaAngleUp /> : <FaAngleDown />}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={`flex flex-row justify-between rounded-[16px] gap-2 w-full`}
                >
                  {[0.1, 0.5, 1.0].map((value) => (
                    <button
                      key={value}
                      className={`py-1 px-4 font-semibold rounded-[24px] text-center hover:cursor-pointer 
            ${
              slippageTolerance === value
                ? theme.palette.mode === "light"
                  ? "bg-[#5663F2] text-white"
                  : "bg-[#181B56] text-white"
                : theme.palette.mode === "light"
                ? "bg-transparent border-2 border-[#D6DAFC] text-[#181821]"
                : "bg-transparent border-2 border-[#181B56] text-[#97979B]"
            }`}
                      onClick={() => handleSlippageChange(value)}
                    >
                      {value}%
                    </button>
                  ))}
                  {/* Custom slippage input */}
                  <div className="relative flex-grow">
                    <input
                      type="text"
                      className={`py-1 pl-4 pr-10 rounded-[24px] outline-none w-full text-end
        ${
          theme.palette.mode === "light"
            ? "bg-white text-[#181821] placeholder-[#97979B]  border-2 border-[#D6DAFC]"
            : "bg-transparent border-2 border-[#181B56] text-white placeholder-[#97979B]"
        }`}
                      placeholder={slippageTolerance.toString()}
                      value={inputSlippageTolerance}
                      onChange={(e) => handleSlippageChange(e.target.value)}
                    />
                    <span
                      className={`absolute right-4 top-1/2 transform -translate-y-1/2 ${
                        theme.palette.mode === "light"
                          ? "text-[#181821]"
                          : "text-white"
                      }`}
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {connectedAccount && (
              <div
                className={`w-full transition-height duration-500 ease-in-out overflow-hidden z-0`}
                style={{ height: isOpenDetails ? "210px" : "52px" }}
              >
                <div
                  className={`flex justify-between items-center w-full p-4 mt-2 text-white cursor-pointer`}
                  onClick={() => setIsOpenDetails(!isOpenDetails)}
                >
                  <span>
                    1 {selectedToken.name} = {usdt1} {selectedToken2.name}
                  </span>
                  <button>
                    {isOpenDetails ? (
                      <FaAngleUp className="text-white transition-transform duration-500" />
                    ) : (
                      <FaAngleDown className="text-white transition-transform duration-500" />
                    )}
                  </button>
                </div>

                <div className={`p-4 pt-0 w-full flex flex-col gap-2 `}>
                  <div className="flex justify-between items-center w-full">
                    <span className="text-[#97979B]">Max. slippage</span>
                    <span>{slippageTolerance}%</span>
                  </div>
                  {/* <div className="flex justify-between items-center w-full">
              <span className="text-[#97979B]">Fee (0.25%)</span>
              <span>$80.75</span>
            </div>
            <div className="flex justify-between items-center w-full">
              <span className="text-[#97979B]">Network cost</span>
              <span>$12.87</span>
            </div> */}
                  <div className="flex justify-between items-center w-full">
                    <span className="text-[#97979B]">Receive at least</span>
                    <span>
                      {receiveValue ? formatBalance(receiveValue) : "0.00"}{" "}
                      {selectedToken2.name}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="w-full flex justify-center mt-4 z-0">
              {connectedAccount ? (
                <div className="w-full flex gap-4">
                  <button
                    className={`flex-1 h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center py-3 px-3  ${
                      theme.palette.mode === "light"
                        ? "text-white"
                        : "text-white"
                    } bg-[#5763F3] hover:bg-[#747FF4] transition-colors duration-300 ${
                      isInsufficientBalance ||
                      isApproving ||
                      parseFloat(sendValue) <= 0 ||
                      sendValue === ""
                        ? "opacity-50 cursor-default"
                        : "cursor-pointer"
                    }`}
                    onClick={() => {
                      if (!approve && !isLoading && !isInsufficientBalance) {
                        setIsLoading(true);
                        approveForSwap(selectedToken.name)
                          .then(() => {
                            setIsLoading(false);
                            setIsApproving(false);
                          })
                          .catch(() => {
                            setIsLoading(false);
                            setIsApproving(false);
                          });
                      }
                    }}
                    disabled={
                      isApproving ||
                      isInsufficientBalance ||
                      parseFloat(sendValue) <= 0 ||
                      sendValue === ""
                    }
                  >
                    <div
                      className={`relative font-paragraph-p1-regular text-ft15 font-semibold flex`}
                    >
                      {approve ? (
                        <span className="flex gap-1 flex-row items-center">
                          <img
                            alt="NewCheck"
                            src={NewCheck}
                            className="w-4 h-4 mr-2 mt-[2px] rounded-[12px]"
                          />
                          Approved
                        </span>
                      ) : isApproving ? (
                        <div>
                          <BiLoaderAlt className="animate-spin text-gray-50" />
                        </div>
                      ) : (
                        "Approve"
                      )}
                    </div>
                  </button>

                  {/* Swap Token Button */}
                  <button
                    className={`flex-1 h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center py-3 px-3 cursor-pointer ${
                      theme.palette.mode === "light"
                        ? "text-white"
                        : "text-white"
                    } bg-[#5763F3] hover:bg-[#747FF4] transition-colors duration-300 ${
                      !approve || loading ? "opacity-50 cursor-default" : ""
                    }`}
                    onClick={() => {
                      if (!loading && approve) {
                        setLoading(true);
                        swap()
                          .then(() => {
                            setLoading(false);
                          })
                          .catch(() => {
                            setLoading(false);
                          });
                      }
                    }}
                    disabled={!approve || isSwapping}
                  >
                    <div
                      className={`relative font-paragraph-p1-regular text-ft15 font-semibold ${
                        theme.palette.mode === "light"
                          ? "text-white"
                          : "text-white"
                      }`}
                    >
                      {swapConfirmModal ? (
                        <span className="flex gap-1 flex-row items-center">
                          <img
                            alt="NewCheck"
                            src={NewCheck}
                            className="w-4 h-4 mr-2 mt-[2px] rounded-[12px]"
                          />
                          Swapped
                        </span>
                      ) : isSwapping ? (
                        <div>
                          <BiLoaderAlt className="animate-spin text-gray-50" />
                        </div>
                      ) : (
                        "Swap Token"
                      )}
                    </div>
                  </button>
                </div>
              ) : (
                <NavLink
                  to="/"
                  className="w-full h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center bg-[#5763F3] hover:bg-[#747FF4] transition-colors duration-300 text-white cursor-pointer"
                >
                  Connect Wallet
                </NavLink>
              )}{" "}
            </div>
          </div>
        </div>

        {swapTransactionFailModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <FailedTransaction
              onClose={() => setSwapTransactionFailModal(false)}
            />
          </div>
        )}
        {swapConfirmModal && (
          <div className="z-50">
            <SuccessfullSwap
              sendValue={sendValue}
              receiveValue={receiveValue}
              selectedToken={selectedToken}
              selectedToken2={selectedToken2}
              swapdata={swapdata}
              onClose={() => {
                setSwapConfirmModal(false);
                setApprove(false);
              }}
            />
          </div>
        )}
        <Toaster />
      </div>
      <div className="flex-1 flex flex-row items-baseline justify-center gap-[0.25rem] mt-2 text-lightslategray-300">
        <div className="w-[1.5rem] h-[1.5rem] flex flex-row items-center justify-center">
          <img
            className="w-[1.063rem] !m-[0] top-[0rem] left-[0.188rem] h-[1.25rem] overflow-hidden shrink-0 z-[0]"
            alt="layer"
            src={layer}
          />
        </div>
        <div className="w-[5.625rem] text-ft5 inline-block h-[1.5rem] shrink-0">{`Powered by `}</div>
        <b className="w-[4.813rem] text-ft5 uppercase inline-block h-[1.5rem] shrink-0">
          Uniswap
        </b>
      </div>

      <div className="w-full sm:w-[84vw] flex flex-col lg:flex-row justify-center items-center gap-6 mt-20">
        <PathToSuccess
          fdv={fdv}
          liquidity={liquidity}
          holdersCount={holdersCount}
          totalSupply={totalSupply}
          selectedToken={selectedToken}
          selectedToken2={selectedToken2}
        />
        <LatestTrades
          activeTab={activeTab}
          connectedAccount={connectedAccount}
          selectedToken={selectedToken}
          selectedToken2={selectedToken2}
          swapConfirmModal={swapConfirmModal}
        />
      </div>
    </div>
  );
};

export default NewSwapComponent;
