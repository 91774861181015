import { useState, useEffect } from "react";
import { layer, NewCheck } from "../assets";
import {
  stake,
  getApprove,
  getEstimatedLPTokens,
  farmDetails,
} from "../uniswap/farm";
import { useTheme } from "@mui/material";
import PopUpDialog from "../components/popUp";
import ErrorPopUPDialog from "../components/errorPopUp.jsx";
import { BiLoaderAlt } from "react-icons/bi";
import LpFarmTable from "../components/trade/lpFarmTable.js";
import UnstakePopup from "../components/successfullPopup.js";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { getPositionIds } from "../uniswap/position";
import { isAddress } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNavigate } from "react-router-dom";
import { fetchTransactions } from "../uniswap/lpfarm.js";

const LPFarmComponent = (props) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { farmedXBR } = props;
  const [activeTab, setActiveTab] = useState("latest");
  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const textColor = theme.palette.text.primary;
  const backgroundLight = theme.palette.background.paper;
  const [farmInputValue, setFarmInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [unstakeOpen, setUnstakeOpen] = useState(false);
  const [isFarm, setIsFarm] = useState(false);
  const [isfarmLoading, setIsFarmLoading] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDepositLoading, setDepositIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [positionId, setPositionIds] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [farmTrxns, setFarmTrxns] = useState([]);
  const [userFarmTxs, setUserFarmTxs] = useState([]);
  const [dialogData, setDialogData] = useState({
    transactionHash: "",
    deposit: "",
  });
  const navigate = useNavigate();
  const tradesData = {
    latest: [{ date: "1d 10h ago", usdt: 5.67, xbr: 23.75, lpt: "$4.6" }],
    your: [{ date: "1d 14h ago", usdt: 12.16, xbr: 56.75, lpt: "$5.8" }],
  };
  const [yourDeposit, setYourDeposit] = useState(0);
  const [yourFarmedXBR, setYourFarmedXBR] = useState(0);
  const [yourShare, setYourShare] = useState(0);

  const formatBalanceForPrice = (price) => {
    console.log("RPICE", price);
    // Ensure the price is a number
    if (typeof price !== "number" || isNaN(price)) return "NaN";

    // Convert the price to a string in fixed-point notation to ensure precision
    let priceStr = price.toFixed(25); // Use enough precision to capture all significant digits

    // Find the index of the decimal point
    let decimalIndex = priceStr.indexOf(".") + 1;

    // Find the index of the first non-zero digit after the decimal point
    let firstNonZeroIndex = decimalIndex;
    while (
      priceStr[firstNonZeroIndex] === "0" &&
      firstNonZeroIndex < priceStr.length
    ) {
      firstNonZeroIndex++;
    }

    // Count the number of leading zeroes
    let zeroCount = firstNonZeroIndex - decimalIndex;

    // Get the significant digits part (adjust the length as needed)
    let significantPart = priceStr.substring(
      firstNonZeroIndex,
      firstNonZeroIndex + 2
    );

    // Construct the formatted string
    return `0.0(${zeroCount})${significantPart}`;
  };

  const handleApproveForFarm = async (token) => {
    try {
      setIsLoading(true);
      // fetch tokenId
      setIsFarmLoading(true);
      const tokenId = Number(selectedPosition);
      console.log("tokenId", typeof tokenId);
      const res = await getApprove(walletProvider, tokenId);
      if (res !== "Failed" && res !== null && res !== undefined) {
        console.log("Called Approve Swap Pop Up");
        setIsFarm(true);
        setIsLoading(false);
      }

      console.log("Farm Approve", res);
    } catch (error) {
      console.error("Error at handleApproveForFarm", error);
      setIsFarmLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchPositionIds = async () => {
      try {
        const connectedAccount = localStorage.getItem("connectedAccount");
        if (!isAddress(connectedAccount)) {
          return;
        }
        const positions = await getPositionIds(walletProvider);
        const farmedXbr = await farmDetails(connectedAccount);
        console.log("farmedXbr", farmedXbr);
        setYourDeposit(farmedXbr.depositedTokens);
        setYourFarmedXBR(
          farmedXbr.farmedXBRTokens === "0.0"
            ? farmedXbr.farmedXBRTokens
            : formatBalanceForPrice(Number(farmedXbr.farmedXBRTokens))
        );
        setYourShare( farmedXbr.userShare === "0.0"
          ? farmedXbr.userShare
          : parseFloat(farmedXbr.userShare).toFixed(2));
        console.log("positions: ", positions);
        setPositionIds(positions);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      }
    };

    fetchPositionIds();
  }, []);

  const connectedAccount = localStorage.getItem("connectedAccount");
  const handleFarmDeposit = async (token) => {
    try {
      // fetch tokenId
      setDepositIsLoading(true);
      const tokenId = Number(selectedPosition);
      console.log("tokenId", tokenId);
      const deposit = await stake(walletProvider, tokenId, 0);
      console.log("Farm Deposit", deposit);
      if (
        deposit === "Failed" ||
        deposit === null ||
        deposit === "" ||
        deposit === undefined
      ) {
        setOpen1(true);
        setDepositIsLoading(false);
        setTimeout(() => setOpen1(false), 15000);
      }
      if (
        deposit !== "Failed" &&
        deposit !== null &&
        deposit !== "" &&
        deposit !== undefined
      ) {
        setDialogData({
          transactionHash: deposit.transactionHash,
          deposit: deposit,
        });
        setIsDeposit(true);
        setDepositIsLoading(false);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setFarmInputValue("");
          setIsFarm(false);
          setIsDeposit(false);
        }, 15000);
      }
    } catch (error) {
      setDepositIsLoading(false);
      console.error("Error at handleFarmDeposit", error);
    }
  };

  useEffect(() => {
    if (Array.isArray(positionId) && positionId.length === 1) {
      setSelectedPosition(positionId[0]);
      handlePositionSelect(positionId[0]);
    }
  }, [positionId]);

  useEffect(() => {
    if (Array.isArray(positionId) && positionId.length === 1 && selectedPosition !== positionId[0]) {
      handlePositionSelect(positionId[0]);
    }
  }, [positionId]);



  const handlePopClose = () => {
    setOpen(false);
    setOpen1(false);
  };
  const cardStyle =
    theme.palette.mode === "light"
      ? {
          backgroundColor: "#E6E7F0",
          color: theme.palette.text.grey,
        }
      : {
          backgroundColor: theme.palette.dark, // Dark mode or other default style
          color: theme.palette.text.primary,
        };

  const handlePositionSelect = async (position) => {
    setSelectedPosition(position);
    setIsOpen(false);
    try {
      const result = await getEstimatedLPTokens(
        position,
        process.env.REACT_APP_FARMV2_CONTRACT_ADDRESS
      );
      console.log("Estimated LP Tokens: ", result);
      setFarmInputValue(result);
      console.log("Estimated LP Tokens: ", result);
    } catch (error) {
      console.error("Error getting estimated LP Tokens:", error);
    }
  };
 

 

  const getData = async () => {
    const userAddress = localStorage.getItem("connectedAccount");
    try {
      const farmAllTrades = await fetchTransactions(
        "" // userAddress
      );
      const farmUserTrades = await fetchTransactions(userAddress);

      setFarmTrxns(farmAllTrades);
      setUserFarmTxs(farmUserTrades);
      // console.log("Fetched transactions:", farmAllTrades);
    } catch (error) {
      console.error("Error getting data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{ backgroundColor: alt, color: theme.palette.text.primary }}
      className="md:w-[87%] 3xl:w-full bg-dark-01 flex flex-col md:flex-row m-auto mt-[25px] justify-center  overflow-hidden text-left text-[1rem] text-darkgray font-paragraph-p1-regular gap-[2%]"
    >
      <div
        className={`md:w-[45%] w-full fullScreen:w-[600px] max-w-[700px]  rounded-3xl  h-fit flex flex-col items-start  justify-start gap-[1.5rem]`}
      >
        <div
          className={`  ${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white"
              : ""
          }  font-paragraph-p1-regular w-full  flex-1 rounded-3xl bg-midnightblue-300 flex flex-col items-start justify-start mobile:p-4 p-[1.5rem] gap-[1rem] `}
        >
          <div
            style={cardStyle}
            className=" rounded-xl min-h-[166px] w-full bg-midnightblue-100 flex flex-row   p-[1.5rem] gap-[2rem]"
          >
            <div className="relative flex items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`flex items-center xl:w-[263px] justify-between px-4 h-[43px] py-1 rounded-xl border border-gray-300 gap-2 text-[#9192A3]`}
                style={{
                  backgroundColor: alt,
                }}
              >
                <div className="flex items-center mobile:text-[10px] justify-center gap-2">
                  <span className="text-[#9192A3]">
                    {selectedPosition
                      ? `NFT ID: ${selectedPosition}`
                      : "Select NFT ID"}
                  </span>
                </div>
                {isOpen ? (
                  <ChevronUpIcon className="ml-[10px] w-4 h-4" />
                ) : (
                  <ChevronDownIcon className="ml-[10px] w-4 h-4" />
                )}
              </button>
              {isOpen && (
                <div
                  className={`absolute top-[5%] w-full mt-21 border border-gray-300 rounded-xl z-50 text-[#9192A3]`}
                  style={{
                    backgroundColor: alt,
                  }}
                >
                  {Array.isArray(positionId) &&
                    positionId.map((position) => (
                      <div
                        key={position.id}
                        className={`px-4 rounded-xl py-2 ${
                          theme.palette.mode === "light"
                            ? "hover:bg-lightgray-200"
                            : "hover:bg-slate-900"
                        } flex items-center pl-30per gap-2 cursor-pointer`}
                        onClick={() => {
                          handlePositionSelect(position);
                          setIsOpen(false);
                        }}
                      >
                        <span>{position}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className=" flex flex-col max-md:w-8  items-end justify-start gap-4 text-right">
              <input
                style={cardStyle}
                className={`
    relative bg-midnightblue-100 text-right ${`${
      theme.palette.mode === "light"
        ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
        : "  text-white"
    }`}  outline-none
    font-inter text-3xl md:text-ft8
    w-36 md:w-80per
    font-bold                   
    placeholder-white               
  `}
                disabled
                placeholder="0.00"
                type="text" // Change type to "text"
                onChange={(event) => {
                  // Apply regex validation to allow only numbers and a single dot
                  let inputValue = event.target.value.replace(/[^0-9.]/g, "");

                  // Limit to a single dot
                  const dotCount = (inputValue.match(/\./g) || []).length;
                  if (dotCount > 1) {
                    inputValue = inputValue.slice(0, -1);
                  }

                  // Update state with the sanitized input value
                  setFarmInputValue(inputValue);
                }}
                value={farmInputValue}
                onWheel={(e) => e.preventDefault()} // Prevent scrolling
                maxLength="20"
              />

              <div
                className={` ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } text-ft5`}
              >
                ≈ $0.0000
              </div>
              {/* <div
                className={` text-ft5 font-bold ${
                  theme.palette.mode === "light" ? "text-red-30" : "text-red-10"
                }`}
              >
                Max
              </div> */}
            </div>
          </div>

          <div className="flex md:w-70per mt-4 flex-row mx-auto w-full justify-center gap-[0.75rem] ">
            <button
                onClick={() => handleApproveForFarm(farmInputValue)}
              disabled={!selectedPosition}
              className={`flex-1 h-[47px] min-h-[47px] mobile:text-base rounded-2.5 ${
                theme.palette.mode === "light"
                  ? "text-mediumslateblue-300"
                  : "text-mediumslateblue-500"
              }  flex flex-row items-center justify-center py-[0.5rem] px-[0.75rem] border-[2px] border-solid border-mediumslateblue-300   ${
                isFarm
                  ? "border-transparent text-green-500 cursor-default"
                  : "border-mediumslateblue-300 hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
              }`}
            >
              <div
                className=" relative text-ft15 font-semibold"
              >
                {" "}
                {isFarm ? (
                  <span className="flex gap-1 flex-row items-center">
                    <img
                      alt="NewCheck"
                      src={NewCheck}
                      className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                    ></img>
                    Approved
                  </span>
                ) : isLoading ? (
                  <div>
                    <BiLoaderAlt className=" animate-spin text-blue" />
                  </div>
                ) : (
                  "Approve"
                )}
              </div>
            </button>
            <div
             onClick={() => {
              if (isFarm) handleFarmDeposit(farmInputValue);
            }}
              className={`flex-1 h-[47px] min-h-[47px] rounded-2.5 flex flex-row  ${
                theme.palette.mode === "light"
                  ? "text-mediumslateblue-300"
                  : "text-mediumslateblue-500"
              }  items-center justify-center py-[0.5rem] px-[0.75rem] border-[2px] border-solid border-mediumslateblue-300   ${
                isDeposit
                  ? "border-transparent cursor-default"
                  : isFarm
                  ? "border-mediumslateblue-300 hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                  : ""
              }`}
            >
              <div
               
                className="relative font-paragraph-p1-regular text-ft15  font-semibold"
              >
                {isDeposit ? (
                  <span className="flex gap-1 flex-row items-center">
                    <img
                      alt="NewCheck"
                      src={NewCheck}
                      className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                    ></img>
                    Deposited
                  </span>
                ) : isDepositLoading ? (
                  <div className="flex justify-center">
                    <BiLoaderAlt className="animate-spin w-5 h-5 " />
                  </div>
                ) : (
                  "Deposit"
                )}
              </div>
            </div>
          </div>
          {open && (
            <PopUpDialog
              isOpen={open}
              handleClose={() => setOpen(false)}
              title="Staking Successful!"
              description="You've successfully staked  LP tokens in the XBR-USDT farm."
              transactionDetails={[
                {
                  label: "Transaction ID",
                  value: dialogData.transactionHash,
                  color: "#13CC95",
                },

                {
                  label: "LP Tokens Deposited",
                  value: farmInputValue,
                  color: "#ACADBB",
                },
              ]}
              footerText="View Farm Details"
              onFooterClick={() => setOpen(false)}
            />
          )}
          {unstakeOpen && (
            <UnstakePopup
              isOpen={unstakeOpen}
              handleClose={() => setUnstakeOpen(false)}
              title="Are you sure you want to unstake your LP tokens?"
              description="You’ll get 35 XBR back to your wallet."
              footerText="Confirm"
              footerText1="Cancel"
              transactionDetails={[
                {
                  label: "Token Id",
                  value: farmInputValue || "00",
                  color: "#13CC95",
                },
              ]}
              onConfirmClick={(unstakeData) => {
                // Send the unstake data
                console.log("Unstake data:", unstakeData);
                // Call the appropriate function to send the unstake data
                // For example: sendUnstakeData(unstakeData);
                // Close the dialog
                setUnstakeOpen(false);
              }}
              onFooterClick={() => setUnstakeOpen(false)}
            />
          )}
          {open1 && (
            <ErrorPopUPDialog
              isOpen={open1}
              image="1"
              handleClose={handlePopClose}
              title="Something went wrong!"
              description="Check your wallet or internet connection. You can also contact us."
              footerText="Contact Us"
              onFooterClick={handlePopClose}
            />
          )}
          <div className=" flex-1 flex flex-row  justify-start gap-[0.25rem] text-lightslategray-300">
            <div className="w-[1.5rem] h-[1.5rem] flex flex-row items-center justify-center ">
              <img
                className="w-[1.063rem]  !m-[0] top-[0rem] left-[0.188rem] h-[1.25rem] overflow-hidden "
                alt=""
                src={layer}
              />
            </div>
            <div className="w-[5.625rem]  leading-[1.5rem] inline-block h-[1.5rem] shrink-0">{`Powered by `}</div>
            <b className="w-[4.813rem]  leading-[1.5rem] uppercase inline-block h-[1.5rem] shrink-0">
              Uniswap
            </b>
          </div>
        </div>
        <div
          className={` ${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white text-gray-200"
              : ""
          } self-stretch rounded-3xl bg-midnightblue-300 flex flex-col items-start justify-start px-4 md:px-6 py-4 md:py-6 gap-[1rem]`}
        >
          <div className="self-stretch flex flex-row items-start justify-start gap-[1rem]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`mobile:text-ft1 md:text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }`}
              >
                Your deposit (LPT)
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                {yourDeposit}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`mobile:text-ft1 md:text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }`}
              >
                Farming share
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                {yourShare} %
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`mobile:text-ft1 md:text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }`}
              >
                Farmed(XBR)
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                {yourFarmedXBR || 0}
              </div>
            </div>
          </div>
          <div className="flex justify-center mx-auto">
            <button
              // disabled={farmInputValue <= 0}
              onClick={() => navigate("/claim-xbr")}
              className={` rounded-xl  flex flex-row items-center justify-center py-2.5 px-6 ${
                theme.palette.mode === "light"
                  ? "bg-white text-gray-200 hover:bg-black hover:text-white"
                  : "bg-midnightblue-100 border-none text-white"
              } box-border hover:bg-[#D5D5E2]    hover:rounded-lg hover:text-black cursor-pointer border-[2px] border-solid border-lightgray-100`}
            >
              <div className=" text-ft15 font-semibold">Claim XBR Rewards</div>
            </button>
          </div>
        </div>
        <div
          className={` ${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white text-gray-200"
              : ""
          } self-stretch md:flex hidden rounded-3xl bg-midnightblue-300  flex-col items-start justify-start p-[1.5rem] gap-[1rem]`}
        >
          <div className="self-stretch flex flex-row items-start justify-start gap-[1rem]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }  tracking-[0.15px] leading-[1.5rem]`}
              >
                LPT deposit value
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                $0.00
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }  tracking-[0.15px] leading-[1.5rem]`}
              >
                Annual XBR Yield
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                180 / 3000 USD
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }  tracking-[0.15px] leading-[1.5rem]`}
              >
                APY
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                ... %
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[1rem]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }  tracking-[0.15px] leading-[1.5rem]`}
              >
                1 LPT
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                $0.00
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }  tracking-[0.15px] leading-[1.5rem]`}
              >
                Yield per block
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                {parseFloat(0.00001664)} XBR
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                }  tracking-[0.15px] leading-[1.5rem]`}
              >
                LP staked
              </div>
              <div
                className={`self-stretch  text-[1.5rem] font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                3212 LPT
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white text-gray-200"
              : ""
          } self-stretch md:hidden flex rounded-3xl bg-midnightblue-300  flex-col items-start justify-start p-[1.5rem] gap-[1rem] sm:flex-col`}
        >
          {/* First Row */}
          <div className="flex  items-start justify-between w-full">
            <div className="flex flex-col w-full sm:w-auto sm:flex-1 items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } tracking-[0.15px] leading-[1.5rem]`}
              >
                LPT deposit value
              </div>
              <div
                className={`self-stretch text-xl font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                $0.00
              </div>
            </div>
            <div className="flex flex-col w-full sm:w-auto sm:flex-1 items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } tracking-[0.15px] leading-[1.5rem]`}
              >
                Annual XBR Yield
              </div>
              <div
                className={`self-stretch text-xl font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                180 / 3000 USD
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="flex  items-start justify-between w-full">
            <div className="flex flex-col w-full sm:w-auto sm:flex-1 items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } tracking-[0.15px] leading-[1.5rem]`}
              >
                Yield per block
              </div>
              <div
                className={`self-stretch text-xl font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                320 XBR
              </div>
            </div>
            <div className="flex flex-col w-full sm:w-auto sm:flex-1 items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } tracking-[0.15px] leading-[1.5rem]`}
              >
                LP staked
              </div>
              <div
                className={`self-stretch text-xl font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                3212 LPT
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="flex  items-start justify-between w-full">
            <div className="flex flex-col w-full sm:w-auto sm:flex-1 items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } tracking-[0.15px] leading-[1.5rem]`}
              >
                APY
              </div>
              <div
                className={`self-stretch text-xl font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                ... %
              </div>
            </div>
            <div className="flex flex-col w-full sm:w-auto sm:flex-1 items-start justify-start gap-[0.5rem]">
              <div
                className={`self-stretch ssm:text-ft1 text-ft5 ${
                  theme.palette.mode === "light"
                    ? "text-gray-10"
                    : "text-gray-50"
                } tracking-[0.15px] leading-[1.5rem]`}
              >
                1 LPT
              </div>
              <div
                className={`self-stretch text-xl font-semibold ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-mediumslateblue-500"
                } `}
              >
                $0.00
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={` mobile:mt-10  ${
          theme.palette.mode === "light"
            ? "border border-lightgray-500 bg-white text-gray-200"
            : ""
        } md:w-[45%] w-full fullScreen:w-[600px] max-w-[700px]  bg-midnightblue-300 flex flex-col items-center justify-start p-[1.5rem] box-border gap-[1rem] text-lightgray-100 rounded-3xl`}
      >
        <div
          className={` ${
            theme.palette.mode === "light"
              ? " text-gray-200"
              : "text-midnightblue-500"
          } flex flex-row ml-auto justify-end items-end gap-[0.5rem]`}
        >
          <div className="tracking-[0.15px] leading-[1.5rem]">Volume 24hrs</div>
          <b className="leading-[1.5rem]">$139.7K</b>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-start justify-start">
            <div
              className={`flex flex-row  ${
                theme.palette.mode === "light"
                  ? " text-gray-200"
                  : "text-midnightblue-500"
              } items-center justify-center py-[0.5rem] px-[1rem]  border-b-[3px] ${
                activeTab === "latest"
                  ? "border-mediumslateblue-500 font-semibold"
                  : "border-transparent"
              }`}
              onClick={() => setActiveTab("latest")}
            >
              <div className="text-ft5 cursor-pointer  ">Latest Trades</div>
            </div>
            <div
              className={`flex  flex-row ${
                theme.palette.mode === "light"
                  ? " text-gray-200"
                  : "text-midnightblue-500"
              } items-center justify-center py-[0.5rem] px-[1.25rem]  ${
                activeTab === "your"
                  ? "border-b-[3px] border-mediumslateblue-500 font-semibold"
                  : "border-b-[3px] border-transparent"
              }`}
              onClick={() => setActiveTab("your")}
            >
              <div className="text-ft5   ">Your Trades</div>
            </div>
          </div>
        </div>
        <div className=" w-full  flex-1 overflow-x-auto hide-scrollbar flex flex-col items-start justify-start gap-[0.25rem]  ">
          <LpFarmTable
            activeTab={activeTab}
            connectedWalletAddress={connectedAccount}
            token="XBR"
            lpData={activeTab === "your" ? userFarmTxs : farmTrxns}
          />
        </div>
      </div>
    </div>
  );
};

export default LPFarmComponent;
