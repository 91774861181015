import React from 'react'
import Navbar from '../../components/Navbar'
import TransferNodeComponent from './transferNode'
// import ConfirmNode from './confirmNode'

function TransferNode() {
    return (
        <>
            <div className='w-full h-full'>
                {/* <Navbar /> */}
                <div>
                    <TransferNodeComponent />
                    {/* <ConfirmNode /> */}
                </div>

            </div>

        </>
    )
}

export default TransferNode