import { createSlice } from '@reduxjs/toolkit';

const initialAccount = localStorage.getItem('connectedAccount') || '';

const initialState = {
  mode: "dark",
  account: initialAccount,
  userBalance: null,
  providerData: null,
  quantity: 1,
  selectedWallet: "",
  metaMaskAccount: null,
  currentChain: "",
  isWalletConnect: false,

  currentDisplay: "Connect Wallet",
  isMetaMaskConnected: false,
  registeredUser: false,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setProvider: (state, action) => {
      state.providerData = action.payload;
    },
    setUserBalance: (state, action) => {
      state.userBalance = { ...state.userBalance, ...action.payload };
      // console.log(action.payload);
    },
    setWalletAddress: (state, action) => {
      state.account = action.payload;
    },
    disconnectWallet: (state) => {
      localStorage.removeItem('connectedAccount');
      localStorage.removeItem('walletName');
      localStorage.removeItem('isWallet');
      state.account = null;
      state.selectedWallet = "Connect Wallet";
      state.metaMaskAccount = null;
      state.isWalletConnect = false;
      window.location.reload();
    },
    incrementQuantity: (state) => {
      state.quantity = Math.min(state.quantity + 1, 10);
    },
    decrementQuantity: (state) => {
      state.quantity = Math.max(state.quantity - 1, 1);
    },
    setSelectedWallet: (state, action) => {
      state.selectedWallet = action.payload;
    },
    setMetaMaskAccount: (state, action) => {
      state.metaMaskAccount = action.payload;
    },
    setIsWalletConnect: (state, action) => {
      state.isWalletConnect = action.payload;
    },
    setCurrentDisplay: (state, action) => {
      state.currentDisplay = action.payload;
    },
    setIsMetaMaskConnected: (state, action) => {
      state.isMetaMaskConnected = action.payload;
    },
    setRegisteredUser: (state, action) => {
      state.registeredUser = action.payload;
    },
    setCurrentChain: (state, action) => {
      state.currentChain = action.payload;
    },
  },
});

export const {
  setMode,
  setAccount,
  setProvider,
  setUserBalance,
  setWalletAddress,
  disconnectWallet,
  incrementQuantity,
  decrementQuantity,
  setSelectedWallet,
  setMetaMaskAccount,
  setIsWalletConnect,
  setCurrentDisplay,
  setIsMetaMaskConnected,
  setRegisteredUser,
  setCurrentChain
} = walletSlice.actions;

export const selectQuantity = (state) => state.wallet.quantity;
export const selectSelectedWallet = (state) => state.wallet.selectedWallet;
export const selectMetaMaskAccount = (state) => state.wallet.metaMaskAccount;
export const selectIsWalletConnect = (state) => state.wallet.isWalletConnect;
export const selectUserBalance = (state) => state.wallet.userBalance;

export default walletSlice.reducer;
