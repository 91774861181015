import React, { useMemo, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
// import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
} from "@web3modal/ethers/react";
import { balanceOfFromContract } from "./uniswap/utils.js";
import {
  metadata,
  projectId,
  polygonMainnet,
} from "./walletConnect/connect.js";

import { themeSettings } from "./theme";
import { selectThemeMode } from "./redux/theme/themeSlice.js";
import Navbar from "./components/Navbar";

import Home from "./pages/Home.jsx";
import NewFarm from "./pages/newFarm.jsx";
import NodeComponent from "./pages/node.jsx";
import WalletConnect from "./pages/connectWallet/wallet.jsx";
import NewBuyNode from "./pages/newBuyNode/index.jsx";
import ConfirmNode from "./pages/confirmNode/index.js";
import TransferNode from "./pages/transferNode/index.js";
import ClaimXbrComp from "./pages/claimXbr/claimxbr.jsx";
import PageNotFound from "./components/PageNotFound.jsx";

const App = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();
  console.log("App.js", walletProvider);

  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [polygonMainnet],
    projectId,
    enableAnalytics: true,
  });

  const { open } = useWeb3Modal(web3Modal);
  const themeMode = useSelector(selectThemeMode);
  const { mode } = useSelector((state) => state.wallet);
  const localWallet = localStorage.getItem("isWallet");
  const account = localStorage.getItem("connectedAccount");
  const nodeCount = localStorage.getItem("nodeCount");
  const theme = useMemo(
    () => createTheme(themeSettings(themeMode)),
    [themeMode]
  );
  useEffect(() => {
    document.getElementById("root").style.backgroundColor =
      theme.palette.mode === "light" ? "#FFFFFF" : "#080628";
  }, [theme]);
  useEffect(() => {
    checkAndSwitchNetwork();
  }, []);

  const checkAndSwitchNetwork = async () => {
    const { ethereum } = window;
    if (ethereum) {
      try {
        const currentChainId = await ethereum.request({
          method: "eth_chainId",
        });
        if (currentChainId !== process.env.REACT_APP_CHAIN_ID) {
          await switchToPolygonNetwork();
        }
      } catch (error) {
        console.error("Could not check or switch network", error);
      }
    }
  };

  const switchToPolygonNetwork = async () => {
    console.log(
      "Switching to Polygon network...",
      process.env.REACT_APP_CHAIN_ID,
      process.env.REACT_APP_RPC_URL,
      process.env.REACT_APP_CHAIN_NAME
    );
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: process.env.REACT_APP_CHAIN_ID,
                chainName: process.env.REACT_APP_CHAIN_NAME,
                nativeCurrency: {
                  name: process.env.REACT_APP_NATIVE_CURRENCY_NAME,
                  symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL,
                  decimals: parseInt(
                    process.env.REACT_APP_NATIVE_CURRENCY_DECIMALS,
                    10
                  ),
                },
                rpcUrls: [process.env.REACT_APP_RPC_URL],
                blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL],
              },
            ],
          });
        } catch (addError) {
          console.error("Failed to add the Polygon network:", addError);
        }
      } else {
        console.error("Failed to switch to the Polygon network:", error);
      }
    }
  };

  useEffect(() => {
    checkAndSwitchNetwork();
  }, [address]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<WalletConnect open={open} />} />
          <Route path="/buy-node" element={<NewBuyNode />} />
          <Route path="/confirm" element={<ConfirmNode />} />
          <Route path="/home" element={<Navigate to="/node" replace />} />
          {/* <Route path="/farm" element={<NewFarm />} /> */}
          <Route
            path="/connect-wallet"
            element={<WalletConnect open={open} />}
          />
          <Route path="/node" element={<NodeComponent />} />
          <Route path="/swap" element={<NewFarm />} />
          <Route path="/transfer-node" element={<TransferNode />} />
          <Route path="/claim-xbr" element={<ClaimXbrComp />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
