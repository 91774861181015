import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import {
  clear,
  ClosePop,
  NewCheck,
  NewCheckLight,
  SuccessStake,
  Unstake,
} from "../../assets";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import {
  getEstimatedLPTokens,
  getUserStakedTokenIds,
  unStake,
} from "../../uniswap/farm";
import { BiLoaderAlt } from "react-icons/bi"; // import loader icon
import { isAddress } from "ethers";

const ClaimXbrComp = (props) => {
  const { walletProvider } = useWeb3ModalProvider();
  const theme = useTheme();
  const navigate = useNavigate();
  const alt = theme.palette.background.alt;
  const { account } = useSelector((state) => state.wallet);
  const [selectedNFT, setSelectedNFT] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [positionId, setPositionIds] = useState("");
  const [lpTokens, setLpTokens] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isUnstaked, setIsUnstaked] = useState(false); // New state to track unstaking status

  const handleClaim = async () => {
    try {
      setIsLoading(true);
      // Replace with actual claim rewards logic
      setTimeout(() => {
        setIsLoading(false);
        toast.success("Rewards claimed successfully!");
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      console.error("handleClaim error", error);
      toast.error("An error occurred.");
    }
  };

  useEffect(() => {
    if (isModalOpen || isSuccessOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen, isSuccessOpen]);

  useEffect(() => {
    const fetchStakedPositionIds = async () => {
      try {
        const connectedAccount = localStorage.getItem("connectedAccount");
        if (!isAddress(connectedAccount)) {
          return;
        }

        const positions = await getUserStakedTokenIds(walletProvider);
        console.log("positions: ", positions);
        setPositionIds(positions);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      }
    };
    fetchStakedPositionIds();
  }, []);

  const handleUnstake = async (positionId) => {
    try {
      setSelectedPosition(positionId);
      const lpTokens = await getEstimatedLPTokens(
        positionId,
        process.env.REACT_APP_FARMV2_CONTRACT_ADDRESS
      );
      setLpTokens(lpTokens);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching LP tokens:", error);
      toast.error("An error occurred.");
    }
  };

  const confirmUnstake = async () => {
    try {
      setIsLoading(true);
      await unStake(walletProvider, selectedPosition, 0);
      setIsLoading(false);

      setIsUnstaked(true);
        setTimeout(() => {
          setIsModalOpen(false);
          navigate("/swap");
        }, 5000);
    } catch (error) {
      setIsLoading(false);
      console.error("Error in confirmUnstake:", error);
      toast.error("Failed to unstake NFT.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const iconSrc = theme.palette.mode === "light" ? NewCheckLight : Unstake;

  return (
    <div
      style={{ background: alt }}
      className="min-h-screen h-screen pt-[5%] flex flex-col items-center overflow-hidden"
    >
      <div className="flex flex-col items-center overflow-y-hidden gap-4 font-inter py-13">
        <div
          className={`p-8 ${
            theme.palette.mode === "light"
              ? "bg-white border border-[#D6D7E3]"
              : "bg-[#070A47]"
          } rounded-3xl md:max-w-[590px] mobile:max-w-[400px] mobile:min-w-[380px] md:min-w-[590px] h-[330px] mobile:w-full`}
        >
          <div className="flex flex-col text-ft22 font-bold mb-6 justify-center items-center">
            Claim XBR Rewards
          </div>
          {positionId?.length === 0 ? (
            <div className="flex flex-col w-[500px] h-[116px] items-center justify-center text-center">
              <div className="text-grey-10 font-bold">No NFTs available</div>
            </div>
          ) : (
            <div className="flex hide-scrollbar overflow-x-auto gap-4">
              {positionId.map((positionId) => (
                <div
                  key={positionId}
                  className={`flex flex-col justify-between h-[120px] items-start min-w-[168px] max-w-[168px] mobile:w-full mb-2 p-4 border-2 rounded-[24px] cursor-pointer ${
                    selectedNFT === positionId
                      ? "border-[#5663F2] bg-[#21246A]"
                      : "border-transparent"
                  } ${
                    theme.palette.mode === "light"
                      ? "bg-[#E6E7F0]"
                      : "bg-[#050840]"
                  }`}
                  onClick={() => setSelectedNFT(positionId)}
                >
                  <div className="font-normal text-[20px]">Farm NFT</div>
                  <div className="text-ft5 font-normal text-[#13CC95]">
                    {positionId}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="w-full">
            <button
              onClick={() => handleUnstake(selectedNFT)}
              className={`py-3 w-70per flex justify-center mt-10  mx-auto mobile:w-full items-center text-ft5 px-4 h-[43px] rounded-xl ${
                !selectedNFT ? "bg-[#5663F2] opacity-40" : "bg-[#5663F2]"
              } font-semibold text-white`}
              disabled={!selectedNFT}
            >
              Unstake
            </button>
          </div>
        </div>
        <button
          className="text-ft5 font-semibold font-inter text-[#5B5C71] mt-4 px-4 py-2 rounded-xl hover:border hover:border-[#5B5C71]"
          onClick={() => navigate("/swap")}
        >
          Back
        </button>
      </div>
      <Toaster />

      {isModalOpen && !isSuccessOpen && (
        <div className="fixed inset-0 bg-black-0 bg-opacity-80 flex justify-center items-center z-50 backdrop-blur-lg">
          <div
            className={`${
              theme.palette.mode === "light"
                ? "bg-white text-black"
                : "bg-[#0B0E4A] text-white"
            } h-[450px] rounded-[24px] p-8 w-[546px]`}
          >
            <div className="flex">
              <img
                alt=""
                src={iconSrc}
                className="m-auto mt-2 rounded-full w-[80px] h-[80px]"
              />
              <button
                onClick={closeModal}
                className="h-6 w-6 text-white mb-auto"
              >
                <img className="" src={ClosePop} alt="" />
              </button>
            </div>
            <div className="flex justify-between gap-2 flex-col items-center mb-4">
              <h2 className="text-[25px] text-center font-bold">
                Are you sure you want to unstake your LP tokens?
              </h2>
              <p className="text-[16px] text-[#ACADBB]">
                Position ID: {selectedPosition}
              </p>
            </div>
            <hr className="border border-[#21246A]" />
            <div
              className={`mb-4 mt-4 text-[16px] ${
                theme.palette.mode === "light"
                  ? "text-[#000000]"
                  : "text-[#ACADBB]"
              } font-normal font-paragraph-p1-regular`}
            >
              <div className="flex mt-5 justify-between">
                <span>LPT amount</span>
                <span>{lpTokens} LPT</span>
              </div>
            </div>
            <div className="flex justify-end mt-[75px] text-[15px] w-full  gap-4">
              <button
                onClick={closeModal}
                className="px-4 h-[43px] w-50per py-2 font-paragraph-p1-regular font-semibold bg-[#1A0304] text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={confirmUnstake}
                className={`px-4 w-50per py-2 font-semibold items-center font-paragraph-p1-regular font-semibold bg-[#5663F2] text-white rounded-lg ${
                  isLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <BiLoaderAlt className="animate-spin mx-auto" />
                ) : isUnstaked ? (
                  "Unstaked"
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimXbrComp;
