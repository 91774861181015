import React, { useEffect, useState } from "react";
import { useTheme, Button, IconButton, CircularProgress } from "@mui/material";
import { MinusIcon, PlusIcon, MinusLight, AddLight } from "../../assets";
import ReactPlayer from "react-player";
import { isAddress } from "ethers";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal.jsx";
import { BiLoaderAlt } from "react-icons/bi";
import termsData from "../../data.js";
import axios from "axios";
import {
  setIsValidReferral,
  setReferralAddress,
} from "../../redux/data/dataSlice.js";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { balanceOfFromContract, isValidReferralAddress } from "../../uniswap/utils.js";

const NewBuyNodeComponent = (props) => {
  const dispatch = useDispatch();
  const { walletProvider } = useWeb3ModalProvider();
  const { currentDisplay } = useSelector((state) => state.wallet);
  const referralAddress = useSelector((state) => state.data.referralAddress);
  const isValidReferral = useSelector((state) => state.data.isValidReferral);
  const navigate = useNavigate();
  const theme = useTheme();
  const account=localStorage.getItem('connectedAccount');

  const alt = theme.palette.background.alt;
  const { address } = useWeb3ModalAccount();
  const [wallet, setWallet] = useState(account);
  const [nodesCount, setNodesCount] = useState(0);
  const [quantity, setQuantity] = useState(1); // Initial quantity for nodes
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state

  

  const apiUrl = process.env.REACT_APP_API;
  const [checkBoxes, setCheckBoxes] = useState({
    terms1: false,
    terms2: false,
    terms3: false,
  });
  const [checkboxErrors, setCheckboxErrors] = useState({
    terms1: false,
    terms2: false,
    terms3: false,
  });

  const handleIncrement = () => {
     console.log('nodesCount', nodesCount)
        if (quantity + nodesCount < 10) {
           console.log("Increment");
          setQuantity((prev) => prev + 1);
        }
  };

  const handleDecrement = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev)); // Prevent quantity from going below 1
  };

  const handleCheckboxChange = (name) => {
    setCheckBoxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: !prevCheckboxes[name],
    }));
    setCheckboxErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleProceed = async (address) => {
    console.log("Proceed", address);
    const newErrors = {
      terms1: !checkBoxes.terms1,
      terms2: !checkBoxes.terms2,
      terms3: !checkBoxes.terms3,
    };

    setCheckboxErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      toast.error("Please agree to all terms", { duration: 2000 });
      return;
    }

    if (isAddress(address)) {
      setIsLoading(true); // Start loading
      console.log("nodes Address", nodesCount);
      navigate("/confirm", { state: { quantity, address } });
    } else {
      console.log("Invalid Address");
      toast.error("Invalid Address", { duration: 500 });
    }
  };

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleTermClick = (term) => {
    setSelectedTerm(term);
    setIsModalOpen(true);
  };
  const handleReferralInputChange = async (e) => {
    const newReferralAddress = e.target.value;
    dispatch(setReferralAddress(newReferralAddress));
    localStorage.setItem("referralAddress", newReferralAddress);

    try {
      const isValid = await verifyReferralWallet(newReferralAddress);
      dispatch(setIsValidReferral(isValid));
    } catch (error) {
      console.error("Error occurred while verifying referral wallet:", error);
    }
  };
  const verifyReferralWallet = async (referralAddress) => {
    try {
      // const response = await axios.post(
      //   `${apiUrl}/v1/xbr/verify-referral-wallet`,
      //   {
      //     referralWalletAddress: referralAddress,
      //   }
      // );

      // return response.data.exists;
      if (isAddress(referralAddress)) {
        const isValid = await isValidReferralAddress(referralAddress);
        return isValid;
      }
      return false;
    } catch (error) {
      console.error("Error verifying referral wallet:", error);
      return false;
    }
  };
 useEffect(() => {
   const fetchNodesCount = async () => {
     const count = await balanceOfFromContract(account);
      setNodesCount(Number(count)); 
   };
   if (account) {
     fetchNodesCount();
   }
 }, [account]);
  useEffect(() => {
    setWallet(currentDisplay);
  }, [walletProvider]);
  return (
    <div
      style={{ background: alt, height: "100% " }}
      className="min-h-screen h-full"
    >
      <div className="flex-col flex items-center gap-4 font-inter py-13">
        {account ? (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            {shortenAddress(account)}
          </div>
        ) : (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            Not Connected
          </div>
        )}
        <TermsAndConditionsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          termsData={termsData}
          selectedTerm={selectedTerm}
        />
        <div
          className={`p-6 ${
            theme.palette.mode === "light"
              ? "bg-white border border-[#D6D7E3] text-black"
              : "bg-[#070A47] text-[#F7F7FE]"
          } rounded-3xl max-w-[524px] w-full relative `}
        >
          <div className="flex text-ft22 font-bold mb-4 justify-center ">
            BUY FOUNDER'S NODES
          </div>

          {theme.palette.mode !== "light" && (
            <div className="absolute top-0 left-21 md:left-35 w-[234px] h-full flex justify-center items-center z-10">
              <ReactPlayer
                url={`${process.env.PUBLIC_URL}/node-animated-video.mp4`}
                playing
                loop
                muted
                width="234px"
                height="244px"
                controls={false}
                playsinline
                style={{ pointerEvents: "none", opacity: 0.1 }}
              />
            </div>
          )}

          <div className="flex relative justify-center text-[#ACADBB] items-center gap-4 z-30">
            <button
              onClick={handleDecrement}
              className={` ${
                theme.palette.mode === "light" ? "bg-[#E6E7F0]" : "bg-[#02042C]"
              } h-12 w-12 flex justify-center rounded-xl items-center p-3`}
            >
              {theme.palette.mode === "light" ? (
                <img src={MinusLight} alt="" className="h-6 w-6" />
              ) : (
                <img src={MinusLight} alt="" className="h-6 w-6" />
              )}
            </button>
            <div className="rounded-xl">
              <input
                value={quantity}
                readOnly
                type="text"
                className={`text-right font-semibold font-inter text-ft5 h-12 w-full rounded-xl ${
                  theme.palette.mode === "light"
                    ? "bg-[#E6E7F0] text-black "
                    : "bg-[#21246A] text-white"
                }  px-3 max-w-[134px] outline-none`}
              />
            </div>
            <button
              onClick={handleIncrement}
              className={` ${
                theme.palette.mode === "light" ? "bg-[#E6E7F0]" : "bg-[#02042C]"
              } h-12 w-12 flex justify-center rounded-xl items-center p-3`}
            >
              {theme.palette.mode === "light" ? (
                <img src={AddLight} alt="" className="h-6 w-6" />
              ) : (
                <img src={PlusIcon} alt="" className="h-6 w-6" />
              )}
            </button>
          </div>
          <div className="flex mt-4 mb-10 relative justify-center items-center text-ft5 font-normal z-30">
            {quantity * Number(process.env.REACT_APP_NODE_PRICE)} USDT / Node
          </div>
          <div
            className={`flex flex-col gap-4.5 relative z-40 ${
              theme.palette.mode === "light"
                ? "text-slate-950"
                : "text-[#ACADBB]"
            } md:ml-7 mb-1  text-ft5 font-normal`}
          >
            <div className="flex items-center ">
              <input
                type="checkbox"
                id="checkbox1"
                className="styled-checkbox h-6 w-6"
                checked={checkBoxes.terms3}
                onChange={() => handleCheckboxChange("terms3")}
              />
              <label
                style={{
                  color: theme.palette.mode === "light" ? "black" : "#ACADBB",
                }}
                htmlFor="checkbox1"
              >
                I have read, understand and agree that X Bull Run Founder’s
                Nodes are{" "}
                <span
                  className="text-[#BF60CB] font-normal cursor-pointer"
                  onClick={() => handleTermClick("terms3")}
                >
                  Not investments
                </span>
              </label>
              {checkboxErrors.terms3 && (
                <p className="text-red-600 text-sm">
                  Please agree that nodes are not investments.
                </p>
              )}
            </div>

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="checkbox2"
                checked={checkBoxes.terms1}
                onChange={() => handleCheckboxChange("terms1")}
                className="styled-checkbox h-6 w-6"
              />
              <label
                style={{
                  color: theme.palette.mode === "light" ? "black" : "#ACADBB",
                }}
                htmlFor="checkbox2"
              >
                I have read, understand and agree to the{" "}
                <span
                  className="text-[#BF60CB] font-normal cursor-pointer"
                  onClick={() => handleTermClick("terms1")}
                >
                  Terms of Service
                </span>
              </label>
              {checkboxErrors.terms1 && (
                <p className="text-red-600 text-sm">
                  Please agree to the Terms of Service.
                </p>
              )}
            </div>

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="checkbox3"
                checked={checkBoxes.terms2}
                onChange={() => handleCheckboxChange("terms2")}
                className="styled-checkbox h-6 w-6"
              />
              <label
                style={{
                  color: theme.palette.mode === "light" ? "black" : "#ACADBB",
                }}
                htmlFor="checkbox3"
              >
                I have read, understand and agree to the{" "}
                <span
                  className="text-[#BF60CB] font-normal cursor-pointer"
                  onClick={() => handleTermClick("terms2")}
                >
                  Privacy Policy.
                </span>
              </label>
              {checkboxErrors.terms2 && (
                <p className="text-red-600 text-sm">
                  Please agree to the Privacy Policy.
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-1.5 mt-4 relative  flex-col z-30">
            <label className="text-ft5 ">Referral address</label>
            <input
              className={`  ${
                theme.palette.mode === "light"
                  ? " bg-[#E6E7F0] "
                  : " bg-[#21246A]"
              }  outline-none px-4 py-3 rounded-xl text-ft5 font-normal placeholder:text-[#9192A3]`}
              placeholder="Enter your referral address"
              value={referralAddress}
              onChange={handleReferralInputChange}
            />
          </div>
          <div className="mt-3">
            {referralAddress && (
              <div>
                {isValidReferral ? (
                  <p className="text-center py-1 text-[#5E6B89] text-[13px]">
                    Valid referral wallet address
                  </p>
                ) : (
                  <p className="text-center py-1 text-[#FF0000] text-[13px]">
                    Invalid referral wallet address
                  </p>
                )}
              </div>
            )}
          </div>
          <button
            disabled={
              isLoading ||
              !isValidReferral ||
              !checkBoxes.terms1 ||
              !checkBoxes.terms2 ||
              !checkBoxes.terms3 ||
              !isValidReferral
            } // Disable button if any checkbox is not checked or if loading
            className={`w-full relative z-30 rounded-xl mt-4  text-ft5 font-semibold whitespace-normal px-5 py-3 ${
              theme.palette.mode === "light"
                ? isLoading ||
                  !checkBoxes.terms1 ||
                  !checkBoxes.terms2 ||
                  !checkBoxes.terms3
                  ? "bg-[#5663F2] bg-opacity-70 cursor-not-allowed text-white"
                  : "bg-[#5663F2] hover:bg-opacity-75 text-white"
                : isLoading ||
                  !checkBoxes.terms1 ||
                  !checkBoxes.terms2 ||
                  !checkBoxes.terms3
                ? "bg-[#5663F2] bg-opacity-70 cursor-not-allowed text-white"
                : "bg-[#5663F2] hover:bg-opacity-75 text-white"
            }`}
            onClick={() => handleProceed(referralAddress)}
          >
            {isLoading ? (
              <div className="flex justify-center">
                <BiLoaderAlt className="animate-spin w-5 h-5 " />
              </div>
            ) : (
              "Proceed"
            )}
          </button>
        </div>

        <button
          className="text-ft5 font-semibold font-inter text-[#5B5C71] mt-4 px-4 py-2 rounded-xl hover:border hover:border-[#5B5C71] "
          onClick={() => navigate("/node")}
        >
          Back
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default NewBuyNodeComponent;
