import { BrowserProvider } from "ethers";


export async function getWalletConnection(walletName,providers) {
  let signer, address;
  if (walletName === "MetaMask") {
    ({ signer, address } = await connectMetamask(providers));
  } else if (walletName === "TrustWallet") {
    ({ signer, address } = await connectTrustWallet(providers));
  } else {
    throw new Error("Unsupported wallet provider");
  }
  return { signer, address };
}
export async function connectMetamask(providers) {
  try {
    console.log("providers", providers);
    const providerWithInfo = providers.find(
      (provider) => provider.info.name === "MetaMask"
    );

    console.log("providerWithInfo", providerWithInfo);
    await providerWithInfo.provider.request({
      method: "eth_requestAccounts",
    });

    const ethersProvider = new BrowserProvider(await providerWithInfo.provider);

    const signer = await ethersProvider.getSigner();
    const address = await signer.getAddress();

    return { signer, address };
  } catch (error) {
    console.error("Error checking MetaMask connection:", error);
    return null;
  }
}

export async function connectTrustWallet(providers) {
  try {
    console.log("providers", providers);
    const providerWithInfo = await providers.find(
      (provider) => provider.info.name === "Trust Wallet"
    );
    console.log("providerWithInfo", providerWithInfo);

    const accounts = await providerWithInfo.provider.request({
      method: "eth_requestAccounts",
    });
    console.log("accounts", accounts);

    const ethersProvider = new BrowserProvider(await providerWithInfo.provider);

    const signer = await ethersProvider.getSigner();
    const address = await signer.getAddress();

    return { signer, address };
  } catch (error) {
    console.error("Error checking Trust Wallet connection:", error);
    return null;
  }

}

  export async function clearLocalStorage ()  {
    localStorage.removeItem("isWallet");
    localStorage.removeItem("walletName");
    localStorage.removeItem("connectedAccount");
    localStorage.removeItem("referralAddress");
    window.location.reload();
  };

