// color design tokens export
export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#DDE0FD",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#22246A",
    800: "#080628",
    900: "#0A0A0A",
    1000: "#000000",
    1100: "#4C63F3",
    1200: "#FFFFFF",
    1300: "#151447",
    1400: "#181640",
    1500: "#36345D",
    1600: "#E6E7F0",
  },
  primary: {
    50: "#FFFFFF",
    100: "#CCF7FE",
    200: "#99EEFD",
    300: "#66E6FC",
    400: "#33DDFB",
    500: "#00D5FA",
    600: "#00A0BC",
    700: "#006B7D",
    800: "#151447",
    900: "#001519",
    1000: "#5763F3",
    1100: "#FFFFFF",
    1200: "#AFB3C0",
    1300: "rgb(210 215 224)",
    1400: "#FFFFFF",
    1500: "#D9DDE7",
    1600: "#E6E7F0",
    1700:'#1D205F',
  },
};

export const getInitialMode = () => {
  const savedMode = localStorage.getItem('themeMode');
  return savedMode ? savedMode : 'dark'; // default to 'dark' if nothing is stored
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            dark: colorTokens.primary[200],
            main: colorTokens.grey[900],
            light: colorTokens.primary[800],
            grey: colorTokens.grey[900],
          },
          
          neutral: {
            dark: colorTokens.grey[100],
            main: colorTokens.grey[200],
            mediumMain: colorTokens.grey[300],
            medium: colorTokens.grey[400],
            light: colorTokens.grey[700],
          },
          background: {
            default: colorTokens.grey[900],
            alt: colorTokens.grey[800],
            grey: colorTokens.grey[200],
          },
          disabeled: {
            back: colorTokens.grey[700]
          },
          disabeledColor: {
            backColor: colorTokens.grey[1100]
          },
          lightColor: {
            lightColors: colorTokens.grey[1200]
          },
          cardColor: {
            cardColors: colorTokens.grey[1300]
          },
          final: {
            buyColor: colorTokens.grey[1400]
          },
          bgColor: {
            modalBg: colorTokens.grey[1500]
          },
        }
        : {
          // palette values for light mode
          primary: {
            dark: colorTokens.primary[700],
            main: colorTokens.grey[50],
            light: colorTokens.primary[50],
          },
          neutral: {
            dark: colorTokens.grey[700],
            main: colorTokens.grey[500],
            mediumMain: colorTokens.grey[400],
            medium: colorTokens.grey[300],
            light: colorTokens.grey[50],
          },
          background: {
            default: colorTokens.grey[10],
            alt: colorTokens.grey[0],
            grey: colorTokens.grey[200],
          },
          disabeled: {
            back: colorTokens.primary[1000]
          },
          disabeledColor: {
            backColor: colorTokens.primary[1100]
          },
          lightColor: {
            lightColors: colorTokens.primary[1200]
          },
          cardColor: {
            cardColors: colorTokens.primary[1300]
          },
          final: {
            buyColor: colorTokens.primary[1400]
          },
          bgColor: {
            modalBg: colorTokens.primary[1500]
          }
        }),
    },
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
