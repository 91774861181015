import { parseUnits, formatUnits, BrowserProvider, Contract, isAddress } from "ethers";
import { CurrencyAmount, Percent } from "@uniswap/sdk-core";
import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";
import { QUOTER_CONTRACT_ADDRESS, USDT_TOKEN, XBR_TOKEN } from "../web3Utils";
import {
  nearestUsableTick,
  NonfungiblePositionManager,
  Pool,
  Position,
} from "@uniswap/v3-sdk";

import { CurrentConfig } from "./config";
import {
  ERC20_ABI,
  MAX_FEE_PER_GAS,
  MAX_PRIORITY_FEE_PER_GAS,
  NONFUNGIBLE_POSITION_MANAGER_ABI,
  NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
} from "../web3Utils";
import { fromReadableAmount } from "./conversion";
import { findPoolAddress, getPoolInfo } from "./pool";
import { fetchTokenObject } from "./liquidity";
import { connectWallet, getProviders, subscribeToProviders } from "./farm";
const positionManagerAddress = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";

// NonfungiblePositionManager ABI
const positionManagerAbi = [
  "function positions(uint256 tokenId) view returns (" +
    "uint96 nonce, address operator, address token0, address token1, uint24 fee, " +
    "int24 tickLower, int24 tickUpper, uint128 liquidity, " +
    "uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, " +
    "uint128 tokensOwed0, uint128 tokensOwed1)",
];

export async function getTokenTransferApproval(
  token,
  value,
  walletProvider
) {
  
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("getTokenTransferApproval", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const tokenContract = new Contract(
      token.address,
      ERC20_ABI,
      signer,
    );

    const approveTx = await tokenContract.approve(
      NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value
    );
    console.log("approveTx", approveTx);
    const receipt =  await approveTx.wait();
    // Check transaction status
    if (receipt.status === 1) {
     console.log("LP- Transaction was successful!");
   } else {
     console.log("Transaction failed.");
   }

   console.log("receipt", receipt);
    return approveTx.hash;
  } catch (error) {
    console.error(error);
    return "Failed";
  }
}

export async function constructPosition(
  token0Amount,
  token1Amount,
  poolAddress,
  walletProvider
) {
  try {
    console.log("token0Amount", token0Amount);
    console.log("token1Amount", token1Amount);
    console.log("constructPool", poolAddress)
    // get pool info
    const poolInfo = await getPoolInfo(poolAddress, walletProvider);

    console.log("poolInfo", poolInfo);

    // construct pool instance
    const configuredPool = new Pool(
      token0Amount.currency,
      token1Amount.currency,
      poolInfo.fee,
      poolInfo.sqrtPriceX96.toString(),
      poolInfo.liquidity.toString(),
      poolInfo.tick
    );

    console.log("configuredPool", configuredPool);

    // create position using the maximum liquidity from input amounts
    return Position.fromAmount0({
      pool: configuredPool,
      tickLower:
        nearestUsableTick(poolInfo.tick, poolInfo.tickSpacing) -
        poolInfo.tickSpacing * 2,
      tickUpper:
        nearestUsableTick(poolInfo.tick, poolInfo.tickSpacing) +
        poolInfo.tickSpacing * 2,
      amount0: token0Amount.quotient,
      // amount1: token1Amount.quotient,
      useFullPrecision: true,
    });
  } catch (error) {
    console.error("Error at constructPosition", error);
    return null;
  }
}
// UPDATE - NOT USING CURRENTLY
export async function modifyPosition(positionId, token0Amount, token1Amount, walletProvider) {
  
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("modifyPosition", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const address = await signer?.getAddress();
    console.log("props", token0Amount, token1Amount);

    // const getPositionData = await getPositionIds(provider, address);
    // const positionId = bigNumberToNumber(getPositionData[0]._hex);
    // console.log(positionId); // Output: 288181

    const positionToIncreaseBy = await constructPosition(
      CurrencyAmount.fromRawAmount(
        CurrentConfig.tokens.token0,
        fromReadableAmount(
          // Number(token0Amount) * CurrentConfig.tokens.fractionToAdd,
          Number(token0Amount),
          CurrentConfig.tokens.token0.decimals
        )
      ),
      CurrencyAmount.fromRawAmount(
        CurrentConfig.tokens.token1,
        fromReadableAmount(
          // Number(token1Amount) * CurrentConfig.tokens.fractionToAdd,
          Number(token1Amount),
          CurrentConfig.tokens.token1.decimals
        )
      ),
      "0x762e64B12061bA23Dc63e4E24eA50bb0A747A5D4",
      walletProvider
    );

    console.log("positionToIncreaseBy", positionToIncreaseBy);

    const addLiquidityOptions = {
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(50, 10_000),
      tokenId: positionId,
    };
    console.log("addLiquidityOptions", addLiquidityOptions);

    // get calldata for increasing a position
    const { calldata, value } = NonfungiblePositionManager.addCallParameters(
      positionToIncreaseBy,
      addLiquidityOptions
    );

    // build transaction
    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value,
      from: address,
      maxFeePerGas: MAX_FEE_PER_GAS,
      maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
    };
    console.log("transaction", transaction);

    const tx = await signer?.sendTransaction(transaction);;

    const receipt = await tx.wait();
    return receipt.status !== null ? tx.hash : null;
  } catch (error) {
    console.error("Error at minting position:", error);
  }
}

export async function getPositionIds(walletProvider) {
  try {
    const connectedAccount = localStorage.getItem("connectedAccount");
    if (!isAddress(connectedAccount)) {
      return;
    }

    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }
    const positionContract = new Contract(
      NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      NONFUNGIBLE_POSITION_MANAGER_ABI,
      signer
    );

    // console.log("tokenIds", tokenIds);

    // Fetch the position data
    const positionManagerContract = new Contract(
      positionManagerAddress,
      positionManagerAbi,
      signer
    );

    const address = await signer?.getAddress();

    // Get number of positions
    const balance = await positionContract.balanceOf(address);
    // console.log("balance", balance);

    // Get all positions
    const tokenIds = [];
    for (let i = 0; i < balance; i++) {
      const tokenOfOwnerByIndex = await positionContract.tokenOfOwnerByIndex(
        address,
        i
      );

      // Fetch the position
      const position = await positionManagerContract.positions(
        tokenOfOwnerByIndex
      );
      const { token0, token1 } = position;

      // find pool address
      console.log("token0", token0, "token1", token1);
      const { poolAddress } = await findPoolAddress(token0, token1);
      console.log("poolAddress", poolAddress);
      if (poolAddress === '0x762e64B12061bA23Dc63e4E24eA50bb0A747A5D4')
        tokenIds.push(tokenOfOwnerByIndex.toString());
    }

    return tokenIds;
  } catch (error) {
    console.error("Error at getPositionIds:", error);
  }
}


export async function quoteIn(poolAddress, amountIn, inDecimals, outDecimals, walletProvider) {
  try {
    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }
    console.log(
      "Quote-in",
      "amountIn",
      amountIn,
      "inDecimals",
      inDecimals,
      "outDecimals",
      outDecimals
    );
    const quoterContract = new Contract(
      QUOTER_CONTRACT_ADDRESS,
      Quoter.abi,
      signer
    );
    const poolConstants = await getPoolInfo(poolAddress, walletProvider);

    console.log("poolConstants-QuoteIn", poolConstants);

    const quotedAmount = await quoterContract.quoteExactInputSingle.staticCall(
      poolConstants.token0,
      poolConstants.token1,
      poolConstants.fee,
      parseUnits(parseFloat(amountIn).toFixed(inDecimals), inDecimals === 18 ? 18 : 6).toString(),
      // fromReadableAmount(amountIn, inDecimals).toString(),
      0
    );

    console.log("QUOTE RESULT", formatUnits(quotedAmount, outDecimals));

    const outAmount = formatUnits(quotedAmount, outDecimals);

    // const truncatedNumber = await removeDecimal(outAmount);
    // console.log("turncatedNumber", truncatedNumber);

    // // Convert the integer value to a string
    // let integerValueString = truncatedNumber.toString();

    // // Concatenate '000' to the last three digits of the string
    // let formattedValue = integerValueString.slice(0, -3) + "000";

    console.log("formattedValue :", outAmount);

    return outAmount;
  } catch (error) {
    console.error("Error at quote:", error);
  }
}

export async function quoteOut(poolAddress, amountIn, inDecimals, outDecimals, walletProvider) {
  try {
    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }
    console.log(
      "Quote-out",
      "amountIn",
      amountIn,
      "inDecimals",
      inDecimals,
      "outDecimals",
      outDecimals
    );

    const quoterContract = new Contract(
      QUOTER_CONTRACT_ADDRESS,
      Quoter.abi,
      signer
    );
    const poolConstants = await getPoolInfo(poolAddress, walletProvider);

    console.log("poolConstants", {
      token0: poolConstants.token0,
      token1: poolConstants.token1,
      fee: poolConstants.fee
    });

    const quotedAmount = await quoterContract.quoteExactInputSingle.staticCall(
      poolConstants.token1,
      poolConstants.token0,
      poolConstants.fee,
      parseUnits(amountIn.toString(), inDecimals === 18 ? 18 : 6).toString(), // Updated for ethers v6
      0
    );
    console.log("quotedAmount", quotedAmount);

    console.log("QUOTE RESULT", formatUnits(quotedAmount, outDecimals));

    return formatUnits(quotedAmount, outDecimals);
  } catch (error) {
    console.error("Error at quote:", error);
  }
}

export async function mintPosition(token0Amount, token1Amount, walletProvider) {
  
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("mintPosition-connectWallet", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }


    const address = await signer?.getAddress();

    console.log("props", token0Amount, token1Amount);
    const { poolAddress } = await findPoolAddress(
      USDT_TOKEN.address,
      XBR_TOKEN.address,
      walletProvider
    );

    const positionToMint = await constructPosition(
      CurrencyAmount.fromRawAmount(
        CurrentConfig.tokens.token0,
        fromReadableAmount(
          Number(token0Amount),
          CurrentConfig.tokens.token0.decimals
        )
      ),
      CurrencyAmount.fromRawAmount(
        CurrentConfig.tokens.token1,
        fromReadableAmount(
          Number(token1Amount),
          CurrentConfig.tokens.token1.decimals
        )
      ),
      poolAddress,
      walletProvider
    );

    // console.log("positionToIncreaseBy", positionToIncreaseBy);
    console.log("positionToMint", positionToMint);

    const mintOptions = {
      recipient: address,
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(50, 10_000),
    };
    console.log("mintOptions", mintOptions);

    // get calldata for increasing a position
    const { calldata, value } = NonfungiblePositionManager.addCallParameters(
      // positionToIncreaseBy,
      // addLiquidityOptions
      positionToMint,
      mintOptions
    );

    const fees = await provider?.getFeeData();
    // const maxPriorityFeePerGas = await provider.getFeeData(true);

    console.log("maxFeePerGas", formatUnits(fees.maxFeePerGas, "wei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(fees.maxPriorityFeePerGas, "wei")
    );

    // build transaction
    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value,
      from: address,
      maxFeePerGas: MAX_FEE_PER_GAS, // formatUnits(fees.maxFeePerGas, "wei"), //MAX_FEE_PER_GAS,
      maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS, // formatUnits(fees.maxPriorityFeePerGas, "wei"), // MAX_PRIORITY_FEE_PER_GAS,
    };
    let gasLimit = await signer?.estimateGas(transaction);
    console.log("Estimated gas limit:",  formatUnits(gasLimit, "gwei"));
    transaction.gasLimit = gasLimit;
    console.log("transaction", transaction);

    const tx = await signer?.sendTransaction(transaction);

    const receipt = await tx.wait();
    // Check transaction status
    if (receipt.status === 1) {
      console.log("Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }
    console.log("receipt", receipt);
    return tx.hash;
  } catch (error) {
    console.error("Error at minting position:", error);
    return "Failed";
  }
}

//CHANGE THIS TO DYNAMIC
export async function removeLiquidity(
  token0Address,
  token1Address,
  token0Amount,
  token1Amount,
  positionId, 
  walletProvider
) {
  if (positionId.length === 0) {
    alert("No Positions Selected!");
  }
  console.log(
    "removeLiquidity",
    token0Address,
    token1Address,
    token0Amount,
    token1Amount,
    positionId
  );

  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("removeLiquidity-connectWallet", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }
    
    const address = await signer.getAddress();

    const token0 = await fetchTokenObject(token0Address, signer);
    const token1 = await fetchTokenObject(token1Address, signer);

    const { poolAddress, fee } = await findPoolAddress(
      token0Address,
      token1Address,
      walletProvider
    );

    const currentPosition = await constructPosition(
      CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount(
          parseInt(token0Amount).toFixed(2), // token0Amount,
          token0.decimals
        )
      ),
      CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount(
          parseInt(token1Amount).toFixed(2), //token1Amount,
          token1.decimals
        )
      ),
      poolAddress,
      walletProvider
    );

    console.log("currentPosition", currentPosition);

    const collectOptions = {
      expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(token0, 0),
      expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(token1, 0),
      recipient: address,
    };

    const percent = fee / 100;
    console.log("slippagePercent", percent);

    const removeLiquidityOptions = {
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(percent, 10_000),
      tokenId: positionId,
      // percentage of liquidity to remove
      liquidityPercentage: new Percent(1),
      collectOptions,
    };
    // get calldata for minting a position
    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(
      currentPosition,
      removeLiquidityOptions
    );

    const fees = await provider?.getFeeData();
    // const maxPriorityFeePerGas = await provider.getFeeData(true);

    console.log("maxFeePerGas", formatUnits(fees.maxFeePerGas, "wei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(fees.maxPriorityFeePerGas, "wei")
    );

    // build transaction
    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value,
      from: address,
      maxFeePerGas: MAX_FEE_PER_GAS, // formatUnits(fees.maxFeePerGas, "wei"),
      // maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS, // formatUnits(fees.maxPriorityFeePerGas, "wei"),
    };
    let gasLimit = await signer.estimateGas(transaction);
    transaction.gasLimit = gasLimit;

    const tx = await signer?.sendTransaction(transaction);
    const receipt = await tx.wait();
    // Check transaction status
    if (receipt.status === 1) {
      console.log("RemoveLiquidity- Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }
    console.log("receipt", receipt);
    return tx.hash;
  } catch (error) {
    console.error("Error at removing liquidity:", error);
  }
}
