import React from "react";
import { useTheme } from "@mui/material";

function TermsAndConditionsModal({ isOpen, onClose, termsData, selectedTerm }) {
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const modalColor = theme.palette.bgColor.modalBg;
  const loaderBackgroundColor =
    theme.palette.type === "dark"
      ? theme.palette.primary.light
      : theme.palette.neutral.light;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
      style={{ scrollbarWidth: "thin" }} // For Firefox
    >
      {/* For WebKit browsers */}
      <style>{`
                ::-webkit-scrollbar {
                    width: 12px;
                }
                
                ::-webkit-scrollbar-track {
                    background-color: ${alt};
                }
                
                ::-webkit-scrollbar-thumb {
                    background-color: #888;
                    border-radius: 6px;
                }
                
                ::-webkit-scrollbar-thumb:hover {
                    background-color: #555;
                }
            `}</style>

      <div
        className="fixed inset-0 overflow-y-auto"
        style={{
          background: `${modalColor}80`,
          backdropFilter: "blur(20px)",
        }}
      >
        {selectedTerm && (
          <div
            key={selectedTerm}
            className="w-full md:w-[600px] bg-[#181640]  flex flex-col items-start mx-auto rounded-lg"
            // style={{ background: alt }}
          >
            {/* Wrap the button and heading in a flex container */}
            <div className="flex justify-between w-full mb-3">
              <h2
                className="text-lg text-center font-bold text-white px-7 pt-7 mx-auto title-and-close"
                style={{ color: dark }}
              >
                {termsData[selectedTerm].title}
              </h2>
              <button
                onClick={onClose}
                style={{ color: dark, fontSize: "24px" }}
                className="p-3 text-white text-5xl focus:outline-none title-and-close"
              >
                &times;
              </button>
            </div>
            <div
              style={{
                borderBottom: "1px solid white",
                width: "100%",
                borderColor: dark,
              }}
            ></div>
            {termsData[selectedTerm].sections.map((section, index) => (
              <div
                key={index}
                className="mb-4 p-4 md:p-5"
                style={{ color: dark }}
              >
                {section.title && (
                  <h3
                    className="text-lg font-semibold mb-2 text-white"
                    style={{ color: dark }}
                  >
                    {section.title}
                  </h3>
                )}
                {/* Add margin-bottom to the <h1> element */}
                {section.title === "Founder's Node" && (
                  <h1 style={{ marginBottom: "10px" }}>
                    <u>{section.title}</u>
                  </h1>
                )}
                <div
                  dangerouslySetInnerHTML={{ __html: section.content }}
                  className="text-white"
                  style={{ color: dark }}
                />
              </div>
            ))}
            {/* <button onClick={onClose} className="mt-1 ml-7 px-4 py-2 bg-red-200 rounded-lg text-white hover:bg-red-300 transition duration-300 ease-in-out">
    Close
</button> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default TermsAndConditionsModal;
