import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main styles
import "react-date-range/dist/theme/default.css"; // theme styles
import { swapVert1 } from "../../assets";
import { addDays, format } from "date-fns";
import { useTheme } from "@mui/material";
import { fetchLiquidityTransactions } from "../../uniswap/liquidityTx";
import { BiLoaderAlt } from "react-icons/bi";

const formatTimeAgo = (timestamp) => {
  const timeDifference = Date.now() - new Date(timestamp * 1000).getTime();
  const minutes = Math.floor(timeDifference / (60 * 1000));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d ${hours % 24}h ago`;
  if (hours > 0) return `${hours}h ${minutes % 60}m ago`;
  return `${minutes}m ago`;
};

const formatAmount = (amount) => {
  if (!amount) return "N/A";
  const num = parseFloat(amount);
  return num.toFixed(2);
};

const LiquidityTable = ({ activeTab, connectedWalletAddress, token }) => {
  const [tradesData, setTradesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "descending",
  });

  useEffect(() => {
    const fetchTrades = async () => {
      setIsLoading(true);

      try {
        let data;
        if (activeTab === "your") {
          data = await fetchLiquidityTransactions(connectedWalletAddress);
        } else if (activeTab === "latest") {
          data = await fetchLiquidityTransactions();
        }

        setTradesData(
          data.map((item) => ({
            transactionHash: item.transactionHash,
            block_timestamp: formatTimeAgo(item.timeStamp),
            kind: item.functionName,
            from_token_amount: formatAmount(item.amount0Desired),
            to_token_amount: formatAmount(item.amount1Desired),
            price_to_in_currency_token: formatAmount(item.amount0Min),
          }))
        );
      } catch (err) {
        console.error("Error fetching trades:", err);
      }
      setIsLoading(false);
    };
    fetchTrades();
  }, [activeTab, connectedWalletAddress, sortConfig]);

  const sortedTrades = tradesData.sort((a, b) => {
    if (
      sortConfig.key &&
      a[sortConfig.key] !== undefined &&
      b[sortConfig.key] !== undefined
    ) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const theme = useTheme();

  return (
    <div
      className={`overflow-x-auto hide-scrollbar relative-container h-[897px] relative ${
        theme.palette.mode === "light"
          ? "bg-white text-gray-10"
          : "bg-midnightblue-300 text-gray-50"
      } `}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <BiLoaderAlt
            className="animate-spin"
            size={50}
            color={theme.palette.mode === "light" ? "#000" : "#fff"}
          />
        </div>
      ) : (
        <table className="w-full h-[500px] text-sm text-left hide-scrollbar">
          <thead
            className={`sticky top-0 text-xs border-t border-b uppercase ${
              theme.palette.mode === "light"
                ? "bg-white text-gray-10 border-[#D6D7E3]"
                : "bg-midnightblue-300 text-gray-50 border-midnightblue-100"
            } z-10`}
          >
            <tr className="flex w-full">
              <th className="flex-1 min-w-[80px] py-[12.5px] px-3 flex justify-between">
                Date
              </th>
              <th className="flex-1 min-w-[120px] py-[12.5px] px-3 flex justify-start gap-3">
                Tx. Hash
                <img
                  src={swapVert1}
                  className="w-5 h-4 cursor-pointer"
                  onClick={() => requestSort("transactionHash")}
                />
              </th>
              <th className="flex-1 min-w-[120px] py-[12.5px] px-3 flex justify-start gap-3">
                USDT
                <img
                  src={swapVert1}
                  className="w-5 h-4 cursor-pointer"
                  onClick={() => requestSort("from_token_amount")}
                />
              </th>
              <th className="flex-1 min-w-[120px] py-[12.5px] px-3 flex justify-start gap-3">
                Type
                <img
                  src={swapVert1}
                  className="w-5 h-4 cursor-pointer"
                  onClick={() => requestSort("kind")}
                />
              </th>
              <th className="flex-1 min-w-[120px] py-[12.5px] px-3 flex justify-start gap-3">
                XBR
                <img
                  src={swapVert1}
                  className="w-5 h-4 cursor-pointer"
                  onClick={() => requestSort("to_token_amount")}
                />
              </th>
              <th className="flex-1 min-w-[120px] py-[12.5px] px-3 flex justify-start gap-3">
                Price
                <img
                  src={swapVert1}
                  className="w-5 h-4 cursor-pointer"
                  onClick={() => requestSort("price_to_in_currency_token")}
                />
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedTrades.map((row, index) => (
              <tr
                key={index}
                className={`flex w-full ${
                  row.kind === "Increase Liquidity"
                    ? "text-mediumseagreen"
                    : "text-[#E32028]"
                }`}
              >
                <td
                  className={`flex-1 min-w-[80px] py-3 px-4 truncate ${
                    theme.palette.mode === "light"
                      ? "text-[#5B5C71]"
                      : "text-green-50"
                  }`}
                >
                  {row.block_timestamp}
                </td>
                <td className="flex-1 min-w-[120px] py-3 px-4 truncate">
                  {row.transactionHash}
                </td>
                <td className="flex-1 min-w-[120px] py-3 px-4 truncate">
                  {row.from_token_amount}
                </td>
                <td className="flex-1 min-w-[120px] py-3 px-4 truncate">
                  {row.kind}
                </td>
                <td className="flex-1 min-w-[120px] py-3 px-4 truncate">
                  {row.to_token_amount}
                </td>
                <td className="flex-1 min-w-[120px] py-3 px-4 truncate">
                  {row.price_to_in_currency_token}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LiquidityTable;
