import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main styles
import "react-date-range/dist/theme/default.css"; // theme styles
import { calendarToday, swapVert1 } from "../assets";
import { addDays } from "date-fns";
import { useTheme } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";

const ClaimXBRTable = ({ claimData }) => {
  const [nodesData, setNodesData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (claimData && claimData.length > 0) {
      setNodesData(claimData);
      setIsLoading(false);
    }
  }, [claimData]);

  console.log(nodesData, "nodesData");
  console.log(claimData, "claim");

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedNodes = nodesData.sort((a, b) => {
    if (!sortConfig.key) return 0;
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const theme = useTheme();
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  const formatTimeAgo = (timestamp) => {
    const timeDifference =
      Date.now() - new Date(parseInt(timestamp) * 1000).getTime();
    const minutes = Math.floor(timeDifference / (60 * 1000));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d ${hours % 24}h ago`;
    if (hours > 0) return `${hours}h ${minutes % 60}m ago`;
    return `${minutes}m ago`;
  };

  return (
    <div
      className={`overflow-x-auto hide-scrollbar relative-container  relative ${
        theme.palette.mode === "light"
          ? "bg-white text-gray-10"
          : "bg-[#0B0E4A] text-gray-50"
      }`}
    >
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-transparent">
          <BiLoaderAlt
            className="animate-spin"
            size={50}
            color={theme.palette.mode === "light" ? "#000" : "#fff"}
          />
        </div>
      )}
      <table
        className={`w-full  text-sm text-left hide-scrollbar ${
          isLoading ? "opacity-50" : ""
        }`}
      >
        <thead
          className={`sticky top-0 text-xs border-t border-b uppercase ${
            theme.palette.mode === "light"
              ? "bg-white text-black border-[#D6D7E3]"
              : "bg-[#0B0E4A] text-gray-50 border-midnightblue-100"
          } z-10`}
        >
          <tr className="flex w-full">
           
          <th className="flex-1 min-w-[250px] mobile:min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
             Node ID
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("nodeId")}
              />
            </th>
          
            <th className="flex-1 min-w-[250px] mobile:min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              Amount
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("rewardPricePerBlock")}
              />
            </th>
            <th className="flex-1 min-w-[250px] mobile:min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              Block No.
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("claimedBlock")}
              />
            </th>
            <th className="flex-1 min-w-[250px] mobile:min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              Tx.Hash{" "}
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("txHash")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedNodes.length > 0 ? (
            sortedNodes.map((row, index) => (
              <tr
                key={index}
                className={`flex w-full ${
                  theme.palette.mode === "light"
                    ? "bg-white text-[#3E4157]"
                    : "bg-[#0B0E4A] text-[#ACADBB]"
                }`}
              >
                  <td className={`flex-1 min-w-[250px] mobile:min-w-[130px] py-3 px-4 truncate `}>
                  {row.nodeId}
                </td>
                <td className={`flex-1 min-w-[250px] mobile:min-w-[130px] py-3 px-4 truncate `}>
                  {row.rewardPricePerBlock}
                </td>
                  <td className={`flex-1 min-w-[250px] mobile:min-w-[130px] py-3 px-4 truncate `}>
                  {row.claimedBlock}
                </td>
                
               
                <td className="flex-1 min-w-[250px] mobile:min-w-[180px] py-3 px-4 truncate">
                  <a
                    href={`https://polygonscan.com/tx/${row.txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    {shortenAddress(row.txHash)}
                  </a>
                </td>
              
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center py-3">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ClaimXBRTable;
