import { useTheme } from "@mui/material";
import { footerNavigation } from "../constants/Data";
import { NavLink } from "react-router-dom";

export const ExternalNavigation = ({ className }: { className?: string }) => {
  const theme = useTheme();
 
  const isLightMode = theme.palette.mode === 'light';

  return (
    <div
      className={`grid w-full lg:w-full xl:w-fit lg:justify-center  sm:grid-cols-2 lg:grid-cols-[repeat(4,11rem)] gap-y-10 gap-6  mb-5 bg-inherit ${className}`}
    >
      {footerNavigation.map(({ heading, links }) => (
        <div key={heading} className="flex flex-col gap-4 lg:gap-6 lg:pl-6">
          <span
            className="text-sm uppercase font-medium"
            style={{
              color: isLightMode ? '#97979B' : '#77798C',
            }}
          >
            {heading}
          </span>
          <ul className="flex flex-col gap-3 lg:gap-4">
            {links.map(({ name, href }) => (
              <li key={name}>
                <NavLink
                  className="text-base transition-300 hover:opacity-60"
                  to={href}
                  style={{
                    color: isLightMode ? '#181821' : 'white',
                  }}
                >
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
