// web3js
import {Contract, parseUnits, isAddress} from "ethers"
import { toast } from "react-toastify";
import { SupportedChainId, Token } from "@uniswap/sdk-core";
import { connectWallet } from "./uniswap/farm";
import axios from "axios";

export const XBR_TOKEN_ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "initialOwner",
        type: "address",
      },
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allowance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientAllowance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address",
      },
    ],
    name: "ERC20InvalidApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "ERC20InvalidReceiver",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSender",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSpender",
    type: "error",
  },
  {
    inputs: [],
    name: "EnforcedPause",
    type: "error",
  },
  {
    inputs: [],
    name: "ExpectedPause",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "OwnableInvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "token",
        type: "uint256",
      },
    ],
    name: "Claim",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "xbrNodeContractAddress",
        type: "address",
      },
    ],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const USDT_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_quantity",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_referralWallet",
        type: "address",
      },
    ],
    name: "buyNode",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const SWAP_ROUTER_ADDRESS = "0xE592427A0AEce92De3Edee1F18E0157C05861564";

const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const XBR_CONTRACT_ADDRESS = process.env.REACT_APP_XBR_CONTRACT_ADDRESS;

// console.log(USDT_ADDRESS);
// console.log(CONTRACT_ADDRESS);

// recipient = referral wallet address

export const transferNFT = async (from, to, tokenId) => {
  // console.log(from, to, tokenId, provider);
  try {
    const { signer } = await connectWallet();
    // Create a contract instance
    const contract = new Contract(
      CONTRACT_ADDRESS,
      CONTRACT_ABI,
      signer
    );

    const gasLimit = await contract.estimateGas.safeTransferFrom(
      from,
      to,
      tokenId
    );

    const gasPrice = await signer.getGasPrice();

    const transferTx = await contract.safeTransferFrom(from, to, tokenId, {
      gasPrice: gasPrice.mul(2), // Adjust multiplier as needed
      gasLimit: gasLimit.mul(2), // Adjust multiplier as needed
    });
    await transferTx.wait();

    console.log("Transfer successful", transferTx.hash);

    const tokenURI = await contract.tokenURI(tokenId);
    console.log("tokenURI", tokenURI);

    //check the transferTx and got the transactionHash
    // call save-transfers-api
    const response = await callSaveTransfersApi(
      from,
      to,
      transferTx.hash, /* should change to txhash */
      tokenId,
      tokenURI
    );
    console.log("response", response);
  } catch (error) {
    console.error(`Failed to transfer NFT: ${error}`);
  }
};

const callSaveTransfersApi = async (
  from,
  to,
  transactionHash,
  tokenId,
  tokenURI
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API}/v1/xbr/save-transfers`;
    console.log("apiUrl", apiUrl);

    const requestBody = {
      from,
      to,
      transactionHash,
      tokenId,
      tokenURI,
    };
    console.log("requestBody", requestBody);
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    
    console.log("callSaveTransfersApi", response.json());
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const approveUSDT = async (approveAmount, provider, account) => {
  try {
    // Validate the approve amount
    if (isNaN(approveAmount) || approveAmount <= 0) {
      console.error("Please enter a valid amount to approve.");
      return;
    }

    if (!provider || account === "Not connected") {
      console.error("Wallet is not connected");
      return;
    }

    // USDT has 6 decimal places, so we multiply the amount by 1e6 to get the actual amount
    const actualAmount = parseUnits(approveAmount.toString(), 6);
    console.log("actualAmount", actualAmount.toString());
    // Create a contract instance for USDT
    const usdt = new Contract(USDT_ADDRESS, USDT_ABI, provider);

    // Get the signer from the provider
    const signer = await provider.getSigner();

    // Approve the contract to spend tokens on behalf of the user
    const tx = await usdt
      .connect(signer)
      .approve(CONTRACT_ADDRESS, actualAmount);
    console.log("Approval successful");

    // Wait for the transaction to be mined
    await tx.wait();
    return { success: true };
  } catch (error) {
    console.error("Error during USDT approval:", error);
    return { success: false, error: "Error during buyNode transaction" };
    // Handle error appropriately, e.g., show a user-friendly message
  }
};

export const buyNode = async (
  quantity,
  recipient,
  approveAmount,
  provider,
  account
) => {
  console.log(quantity, recipient, approveAmount, provider, account);
  try {
    // Validate the recipient wallet address
    if (!isAddress(recipient)) {
      console.error("Please enter a valid address for the recipient wallet.");
      return;
    }

    // Validate the quantity
    if (isNaN(quantity) || quantity <= 0) {
      console.error("Please enter a valid quantity for buying nodes.");
      return;
    }

    if (!provider || account === "Not connected") {
      console.error("Wallet is not connected");
      return;
    }

    const approvalSuccess = await approveUSDT(approveAmount, provider, account);
    console.log("approvalSuccess", approvalSuccess);
    if (!approvalSuccess.success) {
      toast.error("Approval failed. Aborting transaction.");
      return; // Stop execution if approval failed
    }

    const contract = new Contract(
      CONTRACT_ADDRESS,
      CONTRACT_ABI,
      provider
    );
    const signer = await provider.getSigner();
    console.log("quantity: ", quantity);
    // Call the buyNode function after USDT approval
    const tx = await contract
      .connect(signer)
      .buyNode(quantity, recipient, { gasLimit: 600000 });

    console.log("Transaction sent", tx.hash);
    // Wait for the transaction to be mined
    const receipt = await tx.wait();
    console.log("Transaction receipt", receipt);
    return receipt;
  } catch (error) {
    console.error("Error during buying nodes:", error);
    toast.error("Buy node failed. Aborting transaction.");

    return;

    // Handle error appropriately, e.g., show a user-friendly message
  }
};

// export const transferUSDT = async (recipient, amount, provider, account) => {
//     // console.log(recipient);
//     // console.log(account);
//     // console.log(provider);
//     // Validate the recipient's address
//     if (!isAddress(recipient)) {
//         alert("Please enter a valid Ethereum address.");
//         return;
//     }

//     // Validate the amount
//     if (isNaN(amount) || amount <= 0) {
//         alert("Please enter a valid amount.");
//         return;
//     }
//     if (!provider || account === "Not connected") {
//         console.log("Wallet is not connected");
//         return;
//     }
//     // Create a contract instance
//     const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);
//     // console.log(contract);
//     // Get the signer from the provider
//     const signer = provider.getSigner();
//     // console.log(signer);
//     // Connect the contract to the signer
//     const contractWithSigner = contract.connect(signer);
//     // console.log(contractWithSigner);
//     // USDT has 6 decimal places, so we multiply the amount by 1e6 to get the actual amount
//     const actualAmount = parseUnits(amount.toString(), 6);
//     // console.log(actualAmount);
//     // Approve the contract to spend tokens on behalf of the user
//     const usdt = new Contract(USDT_ADDRESS, USDT_ABI, provider);
//     // console.log(usdt);
//     await usdt.connect(signer).approve(CONTRACT_ADDRESS, actualAmount);
//     // Call the transfer function
//     const tx = await contractWithSigner.sendUSDT(actualAmount, recipient, { gasLimit: 600000 });
//     // console.log(tx);
//     // Wait for the transaction to be mined
//     const receipt = await tx.wait();
//     console.log("Transaction receipt", receipt);
//     return receipt
// };

export const storeUSDTTransactionDetails = async (transactionDetails) => {
  // console.log(transactionDetails);
  try {
      const apiUrl = process.env.REACT_APP_API;
      // Make an API call to your backend server to store the transaction details
      const response = await axios.post(
          `${apiUrl}/v1/xbr/usdt-transaction`,
          transactionDetails
      );
      console.log("storeUSDTTransactionDetails", storeUSDTTransactionDetails.data)
      return response;
      // console.log("Transaction details stored successfully:", response.data);
  } catch (error) {
      console.error("Error storing transaction details:", error);
  }
};
   export  const proceedMintAndClaim = async (
      fromAddress,
      toAddress,
      quantity,
      transactionHash,
      tokenIds
    ) => {
      try {
          const apiUrl = process.env.REACT_APP_API;
        const response = await axios.post(`${apiUrl}/v1/xbr/mint`, {
          fromAddress: fromAddress,
          toAddress: toAddress,
          quantity: quantity,
          transactionHash: transactionHash,
          tokenIds: tokenIds,
        });

        console.log("Mint API Response:", response.data);
      } catch (error) {
        console.error("Error calling mint API:", error);
      }
    };
export const mintAndClaim = async (
  amount,
  tokenId,
  xbrNodeContractAddress = CONTRACT_ADDRESS
) => {
  try {
    const { signer, address } = await connectWallet();
    const xbrContract = new Contract(
      XBR_CONTRACT_ADDRESS,
      XBR_TOKEN_ABI,
      signer
    );
    // utils
    // .parseUnits(amountIn.toString(), inDecimals === 18 ? "ether" : "6")
    console.log("amount", amount);

    let claimAmount = parseUnits(amount.toString(), "18");
    console.log("claimAmount", claimAmount);
    const gasLimit = await xbrContract.estimateGas.claim(
      address,
      claimAmount,
      tokenId,
      xbrNodeContractAddress
    );
    const gasPrice = await signer.getGasPrice();

    const mintAndClaimTx = await xbrContract.claim(
      address,
      claimAmount,
      tokenId,
      xbrNodeContractAddress,
      {
        gasPrice: gasPrice.mul(2), // Adjust multiplier as needed
        gasLimit: gasLimit.mul(2), // Adjust multiplier as needed
      }
    );
    await mintAndClaimTx.wait();
    console.log("Transaction sent for mintAndClaimTx", mintAndClaimTx.hash);

    await callClaimApi(address, amount, tokenId, mintAndClaimTx.hash);

  } catch (error) {
    console.error("Error during mintAndClaim:", error);
  }
};

export const callClaimApi = async (to, amount, tokenId, transactionHash) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API}/v1/xbr/claim`;
    console.log("apiUrl-claim", apiUrl);

    const requestBody = {
      to,
      amount: amount.toString(),
      tokenId,
      transactionHash,
    };
    console.log("requestBody-Claim", requestBody);
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    console.log("callClaimApi", response?.body);
    return response?.body;
  } catch (error) {
    console.error("Error during callClaimApi:", error);
  }
};

export const POOL_FACTORY_CONTRACT_ADDRESS =
  "0x1F98431c8aD98523631AE4a59f267346ea31F984";
export const POOL_CONTRACT_ADDRESS =
  "0xc0Afb7CF1B8B865bfB24ff49bB612a4cd7B6B106";
export const NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS =
  "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";
export const QUOTER_CONTRACT_ADDRESS =
  "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6";

// Currencies and Tokens

export const USDT_TOKEN = new Token(
  SupportedChainId.POLYGON,
  process.env.REACT_APP_USDT_ADDRESS,
  // "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  6,
  "USDT",
  "USD//T"
);

export const DAI_TOKEN = new Token(
  SupportedChainId.POLYGON,
  "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  18,
  "DAI",
  "Dai Stablecoin"
);

export const XBR_TOKEN = new Token(
  SupportedChainId.POLYGON,
  process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
  // "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  18,
  "XBR",
  "XBR"
);

export const TBA_TOKEN = new Token(
  SupportedChainId.POLYGON,
  process.env.REACT_APP_TBA_CONTRACT_ADDRESS,
  // "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  18,
  "TBA",
  "TBA"
);


// Transactions

export const MAX_FEE_PER_GAS = "100000000000";
export const MAX_PRIORITY_FEE_PER_GAS = "100000000000";
export const TOKEN_AMOUNT_TO_APPROVE_FOR_TRANSFER = 1000000000000;

// ABI's

export const ERC20_ABI = [
  // Read-Only Functions
  "function balanceOf(address owner) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function symbol() view returns (string)",

  // Authenticated Functions
  "function transfer(address to, uint amount) returns (bool)",
  "function approve(address _spender, uint256 _value) returns (bool)",

  // Events
  "event Transfer(address indexed from, address indexed to, uint amount)",
];

export const NONFUNGIBLE_POSITION_MANAGER_ABI = [
  // Read-Only Functions
  "function balanceOf(address _owner) view returns (uint256)",
  "function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256)",
  "function tokenURI(uint256 tokenId) view returns (string memory)",

  "function approve(address to, uint256 tokenId)",

  "function positions(uint256 tokenId) external view returns (uint96 nonce, address operator, address token0, address token1, uint24 fee, int24 tickLower, int24 tickUpper, uint128 liquidity, uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, uint128 tokensOwed0, uint128 tokensOwed1)",
];


export const xbrMiningAbi = [
  "function approve(address to, uint256 tokenId)",
  "function transferFrom(address from, address to, uint256 tokenId)",
  "function ownerOf(uint256 tokenId) view returns (address)",
  "function startMining() external",
  "function checkAndPushNodeId(uint256 nodeId) internal",
  "function registerNodesForRewards(uint256[] nodeIds, address nodeOwner) external",
  "function claimXBR(uint256 nodeId, address nodeOwner, uint256 reward) external",
  "function isMiningStarted() public view returns (bool)",
  "function balance() view returns (uint256 left)",
  "function getAllNodeIds() view returns (uint256[] memory nodeIds)",
  "function getTotalRewardsDistributed() view returns (uint256 totalRewards)",
  "function getNodeDetails(uint256) public view returns (uint256 nodeId, uint256 miningStartedBlockNumber, uint256 lastlyClaimedRewardsBlock, uint256 totalRewardsClaimed, bool isActive)",

  "event Register(uint256 nodeId, address user, uint256 registeredTime, uint256 registeredBlock)",
  "event ClaimXBR(uint256 nodeId, address user, uint256 claimedBlock, uint256 rewardPricePerBlock)",
  "event MiningStarted(uint256 startTime, uint256 blockNumber)",
  "event RewardPerBlockChanged(uint256 time, uint256 blockNumber, uint256 newRewardPerBlock)",
];


export const xbrNodeAbi = [
  "constructor(string _baseTokenURI, address _usdtTokenAddress, address initialOwner)",
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function totalSupply() view returns (uint256)",
  "function balanceOf(address owner) view returns (uint256)",
  "function ownerOf(uint256 tokenId) view returns (address)",
  "function safeTransferFrom(address from, address to, uint256 tokenId)",
  "function transferFrom(address from, address to, uint256 tokenId)",
  "function approve(address to, uint256 tokenId)",
  "function getApproved(uint256 tokenId) view returns (address)",
  "function setApprovalForAll(address operator, bool approved)",
  "function isApprovedForAll(address owner, address operator) view returns (bool)",
  "function tokenURI(uint256 tokenId) view returns (string)",
  "function setBaseURI(string _newBaseURI)",
  "function buyNode(uint256 _quantity, address _referralWallet)",
  "function calculateTotalCost(uint256 _quantity) view returns (uint256)",
  "function getCurrentNodePrice() view returns (uint256)",
  "function hasXBRNodeNFT(address _address) view returns (bool)",
  "function grantXBRNodeHolderStatus(address _holder)",
  "function revokeXBRNodeHolderStatus(address _holder)",
  "function setInitialReferral(address _referralWallet)",
  "function removeInitialReferral()",
  "function balanceOf(address _owner) view returns (uint256)",
  "function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256)",
  "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
  "event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId)",
  "event ApprovalForAll(address indexed owner, address indexed operator, bool approved)"
];
