import React, { useEffect, useState , useRef } from "react";
import {
  XBR,
  USDT,
  addNew,
  layer,
  Expand,
  NewCheck,
  LightmodeUSDT,
  LightmodeXBR,
} from "../assets";
import { toast } from "react-hot-toast";
import {
  getPositionIds,
  getTokenTransferApproval,
  mintPosition,
  modifyPosition,
  removeLiquidity,
} from "../uniswap/position";
import {
  formatUnits,
  Contract,
  BrowserProvider,
  formatEther,
  parseUnits,
  isAddress,
} from "ethers";
import { USDT_ABI } from "../text";
import { ERC20_ABI } from "../web3Utils";
import { useTheme } from "@mui/material";
import { estimateLiquidityPrice } from "../uniswap/conversion";
import PopUpDialog from "../components/popUp";
import ErrorPopUPDialog from "../components/errorPopUp.jsx";
import { getLiquidity } from "../uniswap/liquidity.js";
import { BiLoaderAlt } from "react-icons/bi";
import LiquidityTable from "../components/trade/liquidityTable.js";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { fetchLiquidityTransactions } from "../uniswap/liquidityTx.js";
import { getProviders, subscribeToProviders } from "../uniswap/farm.js";


const LiquidityComponent = () => {
  const {walletProvider} = useWeb3ModalProvider();

  const theme = useTheme();
  const alt = theme.palette.background.alt;
  const textColor = theme.palette.text.primary;
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtAmountinCard, setUsdtAmountinCard] = useState("");
  const [xbrAmount, setXbrAmount] = useState("");
  const [xbrAmountinCard, setXbrAmountinCard] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [positionSelectedOption, setPositionSelectedOption] = useState(null);

  const [qtyAmount, setQtyAmount] = useState(0);
  const [address, setAddress] = useState("");
  const [selectedPercentage, setSelectedPercentage] = useState(25);
  const [removeLiquidityValue, setRemoveLiquidityValue] = useState(25);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [usdtBalanceinCard, setUsdtBalanceinCard] = useState(0);
  const [maticBalance, setMaticBalance] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [xbrBalance, setXbrBalance] = useState(0);
  const [xbrBalanceInCard, setXbrBalanceInCard] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [positionIds, setPositionIds] = useState([]);
  const options = [
    { label: "XBR", value: "xbr", imageData: { XBR } },
    { label: "USDT", value: "usdt", imageData: { USDT } },
  ];
  const [isLoadingInCard, setIsLoadingInCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInCard, setLoadingInCard] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [activeTab, setActiveTab] = useState(() => {
    const storedTab = localStorage.getItem("activeTabs");
    return storedTab || "latest";
  });
  useEffect(() => {
    localStorage.setItem("activeTabs", activeTab);
  }, [activeTab]);

  const [apiData, setApiData] = useState(null);
  const [isUSDTApproved, setIsUSDTApproved] = useState(false);
  const [isUSDTApprovedInCard, setIsUSDTApprovedInCard] = useState(false);
  const [isXBRApproved, setIsXBRApproved] = useState(false);
  const [isXBRApprovedinCard, setIsXBRApprovedinCard] = useState(false);
  const [positionId, setPositionId] = useState("");
  const [slippageTolerance, setSlippageTolerance] = useState(0.5);
  const [liquidityCheck, setLiquidityCheck] = useState(false);
  const [liquidityCheckinCard, setLiquidityCheckinCard] = useState(false);
  const [dialogData, setDialogData] = useState({
    transactionHash: "",
    usdtAmount: "",
    xbrAmount: "",
  });
  const [dialogDatainCard, setDialogDatainCard] = useState({
    transactionHash: "",
    usdtAmountinCard: "",
    xbrAmountinCard: "",
  });
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [openSuccessfully, setOpenSuccessfully] = useState(false);
  const [openError, setOpenError] = useState(false);
  // liquidity
  const [token0LiquidityAmount, setToken0LiquidityAmount] = useState("0.00");
  const [token1LiquidityAmount, setToken1LiquidityAmount] = useState("0.00");
  const [token0Name, setToken0Name] = useState("USDT");
  const [token1Name, setToken1Name] = useState("XBR");
  const [removeToken0Liquidity, setRemoveToken0Liquidity] = useState(0);
  const [removeToken1Liquidity, setRemoveToken1Liquidity] = useState(0);
  const [token0Address, setToken0Address] = useState("");
  const [token1Address, setToken1Address] = useState("");
  const[outputpool , setOutputpool] = useState();
  const [removeFee, setRemoveFee] = useState(0);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const dropdownRef = useRef(null);

  const [activeButton, setActiveButton] = useState(1);
  const [usdtLoadingInCard, setUsdtLoadingInCard] = useState(false);
  const [xbrLoadingInCard, setXbrLoadingInCard] = useState(false);
  const [liquidityInCard, setLiquidityInCard] = useState(false);
  const [isXBRLoader, setIsXBRLoader] = useState(false);
  const [isUSDTLoader, setIsUSDTLoader] = useState(false);
  const[liquidityLoader,setLiquidityLoader] = useState(false);

  const trackStyle = {
    background:
      theme.palette.mode === "light"
        ? `linear-gradient(to right, #B4B8F9 0%, #B4B8F9 ${
            (100 * (removeLiquidityValue - 0.1)) / 100
          }%,  #FFFFFF ${
            (100 * (removeLiquidityValue - 0.5)) / 100
          }%,  #FFFFFF 100%)`
        : `linear-gradient(to right, #5763F3 0%, #5763F3 ${
            (100 * (removeLiquidityValue - 0.1)) / 100
          }%, #080628 ${
            (100 * (removeLiquidityValue - 0.5)) / 100
          }%, #080628 100%)`,
  };

  const sliderClass = `custom-slider ${
    theme.palette.mode === "light" ? "light-theme" : ""
  }`;

  // Example options, replace with your data

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    // setIsOpen(false);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const getLiquidityData = async () => {
      console.log("positionSelectedOption", positionSelectedOption);
      if (positionSelectedOption === null) return;
      const { token0, token1, fee } = await getLiquidity(
        Number(positionSelectedOption),
        walletProvider
      );

      setToken0LiquidityAmount(token0?.amount);
      setToken1LiquidityAmount(token1?.amount);
      setToken0Name(token0?.name);
      setToken1Name(token1?.name);

      // set removable amount
      setRemoveToken0Liquidity(calculateRemoveLiquidity(token0LiquidityAmount));
      setRemoveToken1Liquidity(calculateRemoveLiquidity(token1LiquidityAmount));
      setToken0Address(token0?.address);
      setToken1Address(token1?.address);
      setRemoveFee(fee);

      const amount1 = parseFloat(token0LiquidityAmount);
      const amount2 = parseFloat(token1LiquidityAmount);

      setUsdtBalanceinCard(amount1);
      setXbrBalanceInCard(amount2);
  
      setOutputpool((Math.max(amount1, amount2) / Math.min(amount1, amount2)).toFixed(5));
    };
    getLiquidityData();
    fetchLiquidityTransactions('0x399B7b6eDf079D6388701fA3318F1146F23b1722');
  }, [handleOptionClick]);

  useEffect(() => {
    if (positionIds?.length === 1) {
      setPositionSelectedOption(positionIds[0]);
    }
  }, [positionIds]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const calculateRemoveLiquidity = (balance) => {
    return (balance * removeLiquidityValue) / 100;
  };

  const handlePercentageButtonClick = (percentage) => {
    setRemoveLiquidityValue(percentage);
    setRemoveToken0Liquidity(calculateRemoveLiquidity(token0LiquidityAmount));
    setRemoveToken1Liquidity(calculateRemoveLiquidity(token1LiquidityAmount));
  };
  useEffect(() => {
    const fetchPositionIds = async () => {
      try {
        const isConnected = localStorage.getItem("connectedAccount") !== null;
        if (!isConnected) {
          setPositionIds([]);
          return;
        }
        const positions = await getPositionIds(walletProvider);
        setPositionIds(positions);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      }
    };
    fetchPositionIds();
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/pairs/polygon/0x58f4112b2f44185d2613313bf2f0c6736a2717a6,0x7e1ae068ac4e0ffd7a84750c2fb093a0eae9aea2"
        );
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };

    fetchApiData();
  }, []);
  const tradesData = {
    latest: [],
    your: [],
  };

  if (apiData && apiData.pairs) {
    tradesData.latest = apiData.pairs.map((pair) => ({
      date: pair.pairCreatedAt,
      usdt: pair.liquidity.usd,
      xbr: pair.priceUsd,
      lpt: pair.liquidity.usdt,
    }));
  }
  const handleApprove = async (address, type) => {
    try {
      console.log("LQ")
      // Set loading state to true

      // Approve the uniswap router to spend tokens
      const token = { address };
      let tokenAmount;
      let weiType;

      if (type === "XBR") {
        tokenAmount = Number(xbrAmount) + 1;
        weiType = 18;
        setIsXBRLoader(true);
      }

      if (type === "USDT") {
        tokenAmount = Number(usdtAmount) + 1;
        weiType = 6;
        setIsUSDTLoader(true);
      }

      if (tokenAmount === 0 || isNaN(tokenAmount) || tokenAmount === "") {
        toast.error("Please enter a valid token amount");
        return;
        // throw new Error("Please enter a valid token amount");
      }

      const weiAmount = parseUnits(tokenAmount.toString(), weiType);
      const approval = await getTokenTransferApproval(
        token,
        weiAmount,
        walletProvider
      );
      if (
        approval === null &&
        approval === "" &&
        approval === undefined &&
        approval === "Failed"
      ) {
        setOpen1(true);
        setTimeout(handlePopClose, 15000);
      }
      if (
        approval !== null &&
        approval !== "" &&
        approval !== undefined &&
        approval !== "Failed"
      ) {
        type === "XBR" ? setIsXBRApproved(true) : setIsUSDTApproved(true);
      }

      console.log("Approval", approval);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setIsUSDTLoader(false);
      setIsXBRLoader(false);
      // Handle errors
    } finally {
      // Set loading state to false after approval process is complete
      setIsUSDTLoader(false);
      setIsXBRLoader(false);
    }
  };
  const handleApproveInCard = async (address, type) => {
    try {
      setIsLoadingInCard(true);

      const token = { address };
      let tokenAmount;
      let weiType;

      if (type === "XBR") {
        tokenAmount = Number(xbrAmountinCard) + 1;
        weiType = 18;
        setXbrLoadingInCard(true);
      }

      if (type === "USDT") {
        tokenAmount = Number(usdtAmountinCard) +  1;
        weiType = 6;
        setUsdtLoadingInCard(true);
      }

      if (tokenAmount === 0 || isNaN(tokenAmount) || tokenAmount === "") {
        toast.error("Please enter a valid token amount");
        return;
      }

      const weiAmount = parseUnits(tokenAmount.toString(), weiType);
      const approval = await getTokenTransferApproval(
        token,
        weiAmount,
        walletProvider
      );
      if (
        approval === null &&
        approval === "" &&
        approval === undefined &&
        approval === "Failed"
      ) {
        setOpen1(true);
        setTimeout(handlePopClose, 15000);
      }
      if (
        approval !== null &&
        approval !== "" &&
        approval !== undefined &&
        approval !== "Failed"
      ) {
        type === "XBR" ? setIsXBRApprovedinCard(true) : setIsUSDTApprovedInCard(true);
      }

      console.log("Approval", approval);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setUsdtLoadingInCard(false);
      setXbrLoadingInCard(false);
    } finally {
      setIsLoadingInCard(false);
      setUsdtLoadingInCard(false);
      setXbrLoadingInCard(false);
    }
  };
  const connectedAccount = localStorage.getItem("connectedAccount");
  const handleAddLiquidity = async () => {
    try {
      
      setLiquidityLoader(true);
      
      // Get the signer from the connected wallet
    
      const mint = await mintPosition(Number(usdtAmount), Number(xbrAmount), walletProvider);
      console.log("mintPositions: ", mint);
      if (mint === "Failed") {
        setOpen1(true);
        setTimeout(handlePopClose, 15000);
      }
      if (
        mint === "Failed" ||
        mint === null ||
        mint === "" ||
        mint === undefined
      ) {
        setOpen1(true);
        setTimeout(() => setOpen1(false), 15000);
      }
      if (
        mint !== "Failed" &&
        mint !== null &&
        mint !== "" &&
        mint !== undefined
      ) {
        setDialogData({
          transactionHash: mint,
          usdtAmount: usdtAmount,
          xbrAmount: xbrAmount,
        });
        setLiquidityCheck(true);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setIsXBRApproved(false);
          setIsUSDTApproved(false);
          setUsdtAmount("");
          setXbrAmount("");
          window.location.reload();
        }, 15000);
      }
    } catch (error) {
      setLiquidityLoader(false);
      setOpen1(true);
      setTimeout(() => setOpen1(false), 15000);
      console.error("Error at Add Liquidity:", error);
    } finally {
      setLiquidityLoader(false);
    }
  };
  const handleAddLiquidityinCard = async () => {
    try {
        setLiquidityInCard(true);
        
        const mint = await modifyPosition(positionSelectedOption, Number(usdtAmountinCard), Number(xbrAmountinCard), walletProvider);
        console.log("mintPositions: ", mint);

        if (mint === "Failed" || mint === null || mint === "" || mint === undefined) {
            setOpenError(true);
            setTimeout(() => setOpenError(false), 15000);
        } else {
            setDialogDatainCard({
                transactionHash: mint,
                usdtAmountinCard: usdtAmountinCard,
                xbrAmountinCard: xbrAmountinCard,
            });
            setLiquidityCheckinCard(true);
            setOpenSuccessfully(true);
            setTimeout(() => {
                setOpenSuccessfully(false);
                setIsXBRApprovedinCard(false);
                setIsUSDTApprovedInCard(false);
                setUsdtAmountinCard("");
                setXbrAmountinCard("");
                window.location.reload(); 
            }, 15000);
        }
    } catch (error) {
        setLiquidityInCard(false);

        setOpenError(true);
        setTimeout(() => setOpenError(false), 15000);
        console.error("Error at Add Liquidity:", error);
    } finally {
        setLiquidityInCard(false);
    }
};



  const onChangeFromToInput = async (value, type) => {
    try {
      const regex = /^(?!-)[0-9]*[.]?[0-9]*$/;
      let xbrAmountVal = 0;

      // Ensure the value is positive or empty
      if (regex.test(value)) {
        if (type === "XBR") {
          setXbrAmount(value);
        } else {
          xbrAmountVal = Number(value) * Number(outputpool);
          if (isNaN(xbrAmountVal)) {
            xbrAmountVal = 0;
          } else {
            xbrAmountVal = xbrAmountVal
          }
          setUsdtAmount(value);
          setXbrAmount(xbrAmountVal);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onChangeFromToInputinCard = async (value, type) => {
    try {
      const regex = /^(?!-)[0-9]*[.]?[0-9]*$/;

      if (regex.test(value)) {
        if (type === "XBR") {
          setXbrAmountinCard(value);
        } else {
          const usdtValue = Number(value);
          const outputpoolValue = Number(outputpool);
          const xbrAmountVal = isNaN(usdtValue * outputpoolValue) ? "0.00" : (usdtValue * outputpoolValue)
          setUsdtAmountinCard(value);
          setXbrAmountinCard(xbrAmountVal);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onChangeQtyInput = async (value, type) => {
    try {
      setQtyAmount(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const xbrBalance = await fetchTokenBalances(
          process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
          ERC20_ABI,
          18
        );
        const usdtBalance = await fetchTokenBalances(
          process.env.REACT_APP_USDT_ADDRESS,
          ERC20_ABI,
          6
        );
        // console.log("LIQUDITY FETCH", xbrBalance, usdtBalance);
        setXbrBalance(isNaN(xbrBalance) ? "0.00" : xbrBalance);
        setUsdtBalance(isNaN(usdtBalance) ? "0.00" : usdtBalance);
      } catch (error) {
        console.error("Error: ", error);
      }
    };
    if (window.ethereum) {
      setAddress(localStorage.getItem("connectedAccount"));
      fetchBalance();
      updateTokenBalances();
    }
  }, []);

  const updateTokenBalances = async () => {
    try {
      if (
        !localStorage.getItem("connectedAccount") ||
        !localStorage.getItem("walletName")
      ) {
        console.log("Wallet is not connected");
        setUsdtBalance(0);
        setMaticBalance(0);
        setXbrBalance(0);
        return;
      }

      const provider = new BrowserProvider(window?.ethereum);
      const network = await provider.getNetwork();
      const account = localStorage.getItem("connectedAccount");

      if (
        Number(network.chainId) !== 137 &&
        Number(network.chainId) !== 80001
      ) {
        console.log(
          "Connected to a network other than Polygon mainnet or testnet. Balances will not be fetched."
        );
        // Update state variables to set balances to 0
        setUsdtBalance(0);
        setMaticBalance(0);
        setUserBalance(0);
        return;
      }

      // Fetch Matic (ETH) balance
      const ethBalance = await provider.getBalance(account);
      setMaticBalance(parseFloat(formatEther(ethBalance)));

      // Fetch USDT balance
      const usdtContract = new Contract(
        process.env.REACT_APP_USDT_ADDRESS,
        USDT_ABI,
        provider
      );
      const usdtBalanceBigNumber = await usdtContract.balanceOf(account);
      const usdtBalanceFormatted = formatUnits(usdtBalanceBigNumber, 6);
      setUsdtBalance(parseFloat(usdtBalanceFormatted));

      // Fetch XBR balance only if not on Polygon Mainnet
      if (process.env.REACT_APP_CHAIN_NAME !== "Polygon Mainnet") {
        const XBRContractAddress = process.env.REACT_APP_XBR_CONTRACT_ADDRESS;
        const XBRContract = new Contract(
          XBRContractAddress,
          ["function balanceOf(address owner) view returns (uint256)"],
          provider
        );
        const XBRBalance = await XBRContract.balanceOf(account);
        const xbrBalanceFormatted = parseFloat(formatEther(XBRBalance));
        setXbrBalance(xbrBalanceFormatted);
      } else {
        setXbrBalance(0); // Set XBR balance to 0 if on Polygon Mainnet
      }
    } catch (error) {
      console.error("Error checking balance:", error);
    }
  };

  const fetchTokenBalances = async (contractAddress, ABI, tokenDecimals) => {
    try {
      // Initialize ethers provider
      const connectedAccount = localStorage.getItem("connectedAccount");
      if (!isAddress(connectedAccount)) {
        return;
      }
      let signer;
      let provider;
      if (walletProvider) {
        console.log("Connected to Wallet Connect", "Provider", walletProvider);
        provider = new BrowserProvider(walletProvider);
        signer = await provider?.getSigner();
      } else {
        console.log("connecting without walletConnect!");
        await subscribeToProviders();
        const providers =  await getProviders();
        const wallet = localStorage.getItem("walletName");
    
        const providerWithInfo = await providers.find(
          (provider) => provider.info.name === wallet
        );
        console.log("claimXBRRewardsForNodes", providerWithInfo);
        provider = new BrowserProvider(providerWithInfo?.provider);
        signer = await provider?.getSigner();
      }
      const contract = new Contract(contractAddress, ABI, provider);
      const address = await signer.getAddress();
      return parseFloat(
        formatUnits(await contract.balanceOf(address), tokenDecimals)
      ).toFixed(3);
    } catch (error) {
      console.error("Error fetching user balance:", error);
    }
  };

  const formatBalance = (balance) => {
    // Ensure the balance is treated as a number
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return num.toFixed(2).replace(/\.00$/, ""); // Output as a string with two decimal places
    }
  };


  const max = async (type) => {
    try {
      const balance = await fetchTokenBalances(
        type === "XBR"
          ? process.env.REACT_APP_XBR_CONTRACT_ADDRESS
          : process.env.REACT_APP_USDT_ADDRESS,
        ERC20_ABI,
        type === "XBR" ? 18 : 6
      );
      await onChangeFromToInput(balance, type);
    } catch (error) {
      console.error("Error getting max balances");
    }
  };
  const maxInCard = async (type) => {
    try {
      const balance = await fetchTokenBalances(
        type === "XBR"
          ? token1Address
          : token0Address,
        ERC20_ABI,
        type === "XBR" ? 18 : 6
      );
      await onChangeFromToInputinCard(balance, type);
    } catch (error) {
      console.error("Error getting max balances");
    }
  };

  const handlePopClose = () => {
    setOpen(false);
    setOpen1(false);
  };

  const XbriconSrc = theme.palette.mode === "light" ? LightmodeXBR : XBR;
  const USDTiconSrc = theme.palette.mode === "light" ? LightmodeUSDT : USDT;

  const cardStyle =
    theme.palette.mode === "light"
      ? {
          backgroundColor: "#E6E7F0",
          primaryLight: theme.palette.primary.light,
          color: theme.palette.text.grey,
        }
      : {
          backgroundColor: theme.palette.dark,
          color: theme.palette.text.primary,
        };


  const handleXbrInputChange = (event) => {
    let inputValue = event.target.value;

    // Remove any non-numeric characters except dot (.)
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure only one dot (.) is allowed
    const dotCount = (inputValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      inputValue = inputValue.slice(0, -1);
    }

    // Prevent negative values
    if (inputValue.startsWith("-")) {
      inputValue = "";
    }

    // Update state with the sanitized input value
    setXbrAmount(inputValue);
  };
  const handleXbrInputChangeInCard = (event) => {
    let inputValue = event.target.value;

    // Remove any non-numeric characters except dot (.)
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure only one dot (.) is allowed
    const dotCount = (inputValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      inputValue = inputValue.slice(0, -1);
    }

    // Prevent negative values
    if (inputValue.startsWith("-")) {
      inputValue = "";
    }

    // Update state with the sanitized input value
    setXbrAmount(inputValue);
  };
  const handleSectionClick = (sectionNumber) => {
    setActiveButton(sectionNumber);
    //  localStorage.setItem("activeTab", sectionNumber.toString());
  };
  return (
    <div
      style={{ backgroundColor: alt, color: textColor }}
      className="md:w-[87%] 3xl:w-full flex-col md:flex-row justify-center m-auto mt-[25px] bg-gray-200 overflow-y text-left text-[1rem] text-white font-paragraph-p1-regular font-normal flex gap-[2%]"
    >
      <div className="md:w-[45%] w-full fullScreen:w-[600px] max-w-[700px]">
        <div
          className={` ${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white"
              : ""
          } rounded-3xl bg-midnightblue-300 flex flex-col items-start justify-start mobile:p-4 p-[1.5rem] box-border w-full gap-[1rem] mb-4`}
        >
          <div className=" flex mobile:w-full flex-col items-start justify-start  gap-[1rem] w-full">
            <div
              style={cardStyle}
              className=" flex justify-between w-full rounded-2.5 mobile:rounded-2.5 bg-midnightblue-100  flex-row items-center  p-[1.5rem] z-[0]"
            >
              <div className="flex flex-col items-start justify-center gap-[0.75rem]">
           
                <div
                  className={`rounded-2.5 ${
                    theme.palette.mode === "light"
                      ? "bg-lightgray-400"
                      : " bg-gray-400"
                  }   flex flex-row items-center justify-center py-[0.75rem] pr-[1.25rem] pl-[0.75rem] gap-[0.5rem] border-[1px] border-solid border-gray-300 w-32`}
                >
                  <img
                    className="w-[1.5rem] h-[1.5rem] object-cover"
                    alt=""
                    src={USDTiconSrc}
                  />
                  <div className=" text-base leading-[19px]">USDT</div>
                </div>
                <div
                  className={` ${
                    theme.palette.mode === "light"
                      ? "text-gray-10"
                      : "text-gray-50"
                  } whitespace-nowrap mobile:text-ft1_5  text-ft5`}
                >
                  Balance: {formatBalance(parseFloat(usdtBalance).toFixed(2))}{" "}
                  USDT
                </div>
              </div>
              <div className=" flex flex-col   items-end justify-center gap-[0.75rem] text-right ">
                <input
                  className={`
          relative bg-midnightblue-100 ${
            theme.palette.mode === "light"
              ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
              : "  text-white bg-transparent placeholder:text-white"
          } text-right  outline-none
          font-inter text-3xl md:text-ft8
          w-36 md:w-80per
          font-bold                   
        `}
                  type="text"
                  placeholder="0.00"
                  value={usdtAmount}
                  onWheel={(e) => e.preventDefault()}
                  onChange={(event) => {
                    onChangeFromToInput(
                      event.target.value.replace(/,/g, ""),
                      "USDT"
                    );
                  }}
                />
                <div
                  className={` ${
                    theme.palette.mode === "light"
                      ? "text-gray-10"
                      : "text-gray-50"
                  } text-ft5`}
                >
                  ≈ $0.0000
                </div>
                <div
                  className={` text-ft5 font-bold ${
                    theme.palette.mode === "light"
                      ? "text-red-30"
                      : "text-red-10"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => max("USDT")}
                >
                  Max
                </div>
              </div>
            </div>
            <div className="w-[2.5rem] absolute self-center 3md:mt-37.5 mt-[8.5rem] 4md:mt-[8.5rem]  rounded-full bg-mediumslateblue-300 h-[2.5rem] flex flex-row items-center justify-center z-[2]">
              <img className="h-5 cursor-pointer" alt="" src={addNew} />
            </div>
            <div
              style={cardStyle}
              className=" rounded-2.5 bg-midnightblue-100 w-full flex flex-row round items-center justify-between lg:justify-start p-[1.5rem] z-[0]"
            >
              <div className="flex flex-col items-start  justify-center gap-[0.75rem]">
                <div className=" leading-[1.5rem]"></div>
                <div
                  className={`rounded-2.5 ${
                    theme.palette.mode === "light"
                      ? "bg-lightgray-400"
                      : " bg-gray-400"
                  }  flex flex-row items-center justify-center py-[0.75rem] pr-[1.25rem] pl-[0.75rem] gap-[0.5rem] border-[1px] border-solid border-gray-300 w-32`}
                >
                  <img
                    className="w-[1.5rem]  h-[1.5rem] object-cover"
                    alt=""
                    src={XbriconSrc}
                  />
                  <div className=" leading-[1.5rem]">XBR</div>
                </div>
                <div
                  className={` ${
                    theme.palette.mode === "light"
                      ? "text-gray-10"
                      : "text-gray-50"
                  } whitespace-nowrap mobile:text-ft1_5 text-ft5`}
                >
                  Balance: {formatBalance(parseFloat(xbrBalance).toFixed(2))}{" "}
                  XBR
                </div>
              </div>
              <div className="flex-1 flex flex-col w-[20%]  items-end justify-center gap-[0.75rem] text-right ">
                {" "}
                <input
                  style={cardStyle}
                  className={`
      relative bg-midnightblue-100 text-right ${`${
        theme.palette.mode === "light"
          ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
          : "  text-white placeholder:text-white"
      }`} outline-none
      font-inter text-3xl md:text-ft8
      w-36 md:w-80per
      font-bold                   
      `}
                  placeholder="0.00"
                  type="text" // Changed to text
                  maxLength="20"
                  value={xbrAmount} // Use the sanitized input value directly
                  onChange={handleXbrInputChange} // Changed to handleXbrInputChange
                  onWheel={(e) => e.preventDefault()} // Prevent scrolling
                />
                <div
                  className={` ${
                    theme.palette.mode === "light"
                      ? "text-gray-10"
                      : "text-gray-50"
                  } text-ft5`}
                >
                  ≈ $0.0000
                </div>
                <div
                  className={` text-ft5 font-bold ${
                    theme.palette.mode === "light"
                      ? "text-red-30"
                      : "text-red-10"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => max("XBR")}
                >
                  Max
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:w-70per  mt-[15px] mx-auto w-full justify-center items-center  gap-[0.75rem] text-mediumslateblue-300">
            <button
              className={`flex-1 h-[47px] min-h-[47px]  rounded-2.5 flex flex-row  ${
                theme.palette.mode === "light"
                  ? "text-mediumslateblue-300 hover:text-white"
                  : "text-mediumslateblue-500"
              } items-center justify-center py-3 px-[0.75rem] border-[2px] border-solid border-mediumslateblue-300  ${
                isUSDTApproved
                  ? "border-transparent text-green-500 cursor-default"
                  : "border-mediumslateblue-300 hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
              }`}
              onClick={() => {
                if (!isUSDTApproved && !isUSDTLoader) {
                  handleApprove(process.env.REACT_APP_USDT_ADDRESS, "USDT");
                }
              }}
              disabled={isUSDTLoader || !usdtAmount} // Disable button when loading
            >
              <div className="relative font-paragraph-p1-regular text-ft5 font-semibold flex">
                {isUSDTLoader ? (
                  // Show loader when loading
                  <div>
                    <BiLoaderAlt className=" animate-spin text-gray-50" />
                  </div>
                ) : // Show approval status or button text
                isUSDTApproved ? (
                  <>
                    <img
                      alt="NewCheck"
                      src={NewCheck}
                      className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                    ></img>
                    USDT Approved
                  </>
                ) : (
                  "Approve USDT"
                )}
              </div>
            </button>

            <div
              className={`flex-1 md:w-70per h-[47px] min-h-[47px]  w-full justify-center mobile:text-base rounded-2.5 ${
                theme.palette.mode === "light"
                  ? "text-mediumslateblue-300"
                  : "text-mediumslateblue-500"
              }  flex flex-row items-center justify-center py-[0.5rem] px-[0.75rem] border-[2px] border-solid border-mediumslateblue-300  ${
                isXBRApproved
                  ? "border-transparent text-green-500 cursor-default"
                  : isUSDTApproved
                  ? "hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                  : "border-mediumslateblue-300"
              }`}
              onClick={() => {
                if (isUSDTApproved && !isXBRLoader) {
                  handleApprove(
                    process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
                    "XBR"
                  );
                }
              }}
              disabled={isXBRLoader} // Disable button when isXBRLoader
            >
              <div className="relative font-paragraph-p1-regular text-ft15 font-semibold flex">
                {isXBRLoader ? (
                  // Show loader when loading
                  <div>
                    <BiLoaderAlt className=" animate-spin text-gray-50" />
                  </div>
                ) : // Show approval status or button text
                isXBRApproved ? (
                  <>
                    <img
                      alt="NewCheck"
                      src={NewCheck}
                      className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                    ></img>
                    XBR Approved
                  </>
                ) : (
                  "Approve XBR"
                )}
              </div>
            </div>
          </div>
          {/* <div className=" flex flex-row items-start justify-start gap-[0.75rem] text-mediumslateblue-300">
          <div
            className={`flex-1 rounded-xl flex flex-row items-center justify-center py-[0.75rem] px-[1.25rem] border-[2px] border-solid ${
              isUSDTApproved
                ? "border-transparent text-green-500 cursor-default"
                : "border-mediumslateblue-300 hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
            }`}
            onClick={() => {
              if (!isUSDTApproved) {
                handleApprove(process.env.REACT_APP_USDT_ADDRESS, "USDT");
              }
            }}
          >
            <div className="leading-[1.5rem] font-semibold">
              {isUSDTApproved ? "USDT Approved" : "Approve USDT"}
            </div>
          </div>
          <div
            className={`flex-1 rounded-xl flex flex-row items-center justify-center py-[0.75rem] px-[1.25rem] border-[2px] border-solid ${
              isXBRApproved
                ? "border-transparent text-green-500 cursor-default"
                : "border-mediumslateblue-300 hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
            }`}
            onClick={() => {
              if (!isXBRApproved) {
                handleApprove(
                  process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
                  "XBR"
                );
              }
            }}
          >
            <div className="leading-[1.5rem] font-semibold">
              {isXBRApproved ? "XBR Approved" : "Approve XBR"}
            </div>
          </div>
        </div> */}
          {open && (
            <PopUpDialog
              isOpen={open}
              handleClose={() => setOpen(false)}
              title="Liquidity Added!"
              description="You've successfully added liquidity to the XBR-USDT pool."
              transactionDetails={[
                {
                  label: "Transaction ID",
                  value: dialogData.transactionHash,
                  color: "#13CC95",
                },
                {
                  label: "Deposited",
                  value: `${dialogData.usdtAmount} USDT`,
                  color: "#ACADBB",
                },
                {
                  label: "Received LP Tokens",
                  value: `${dialogData.xbrAmount} XBR`,
                  color: "#ACADBB",
                },
              ]}
              footerText="View LP Tokens"
              onFooterClick={() => setOpen(false)}
            />
          )}
          {open1 && (
            <ErrorPopUPDialog
              isOpen={open1}
              image="1"
              handleClose={handlePopClose}
              title="Something went wrong!"
              description="Check your wallet or internet connection. You can also contact us."
              footerText="Contact Us"
              onFooterClick={handlePopClose}
            />
          )}
          <div className="w-full flex flex-row justify-center items-center">
            <div
              onClick={() => {
                if (isXBRApproved) handleAddLiquidity();
              }}
              className={` rounded-2.5 md:w-[37%] w-full bg-mediumslateblue-300 text-white h-[3rem] flex flex-row items-center justify-center py-[0.75rem] px-[1.25rem] box-border  ${
                isXBRApproved
                  ? "hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                  : liquidityCheck
                  ? "border-transparent text-green-500 cursor-default"
                  : ""
              }`}
            >
              <div className=" text-ft15 font-semibold flex">
                {" "}
                {liquidityLoader ? (
                      <div>
                        <BiLoaderAlt className=" animate-spin text-gray-50" />
                      </div>
                    ) : liquidityCheck ? (
                      <>
                        <img
                          alt="NewCheck"
                          src={NewCheck}
                          className="w-5 h-5 mr-2 fill-current mt-[2px] rounded-lg"
                        ></img>
                        Liquidity Added
                      </>
                    ) : (
                      "Increase Liquidity"
                    )}
              </div>
            </div>
          </div>

          <div className=" flex-1 flex flex-row items-end justify-start gap-[0.25rem] text-lightslategray-300">
            <div className="w-[1.5rem] h-[1.5rem] flex flex-row items-center justify-center ">
              <img
                className="w-[1.063rem] !m-[0] top-[0rem] left-[0.188rem] h-[1.25rem] overflow-hidden shrink-0 z-[0]"
                alt=""
                src={layer}
              />
            </div>
            <div className="w-[5.625rem]  leading-[1.5rem] inline-block h-[1.5rem] shrink-0">{`Powered by `}</div>
            <b className="w-[4.813rem]  leading-[1.5rem] uppercase inline-block h-[1.5rem] shrink-0">
              Uniswap
            </b>
          </div>
        </div>
        <div
          className={`${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white"
              : ""
          } rounded-3xl bg-midnightblue-300 flex flex-col items-start justify-start mobile:p-4 p-[1.5rem] box-border gap-[0.5rem] mb-4`}
        >
          <div
            className={`${
              theme.palette.mode === "light"
                ? "text-gray-10 bg-white"
                : "text-gray-50 bg-black"
            } flex m-auto rounded-[12px] h-[51px] gap-1 xl:w-auto w-full items-center px-3  `}
          >
            <button
              className={`flex my-auto text-[14px] h-[35px] w-[48%]  md:text-[16px] xl:min-w-[198.67px] justify-center items-center rounded-[8px] ${
                activeButton === 1
                  ? "bg-[#21246A] text-white"
                  : "hover:bg-[#21246A] hover:text-white"
              }`}
              onClick={() => handleSectionClick(1)}
            >
              INCREASE LIQUIDITY
            </button>
            <button
              className={`flex my-auto h-[35px] text-[14px] md:text-[16px] w-[48%] xl:min-w-[198.67px] justify-center items-center rounded-[8px] ${
                activeButton === 2
                  ? "bg-[#21246A] text-white"
                  : "hover:bg-[#21246A] hover:text-white"
              }`}
              onClick={() => handleSectionClick(2)}
            >
              REMOVE LIQUIDITY
            </button>
          </div>

          {activeButton === 2 && (
            <div
              style={{
                // backgroundColor: primaryLight,
                color: theme.palette.text.primary,
              }}
              className={` ${
                theme.palette.mode === "light"
                  ? " text-gray-200 bg-white"
                  : "bg-midnightblue-300"
              }  rounded-3xl w-full  md:mt-4`}
            >
              <div
                className={` ${
                  theme.palette.mode === "light" ? "bg-gray-5" : "bg-[#21246A]"
                } flex p-6 rounded-3xl flex-col   `}
              >
                <div className="flex items-center justify-between">
                  <div
                    className={`  ${
                      theme.palette.mode === "light"
                        ? " bg-white "
                        : "bg-gray-400 text-lightgray-400  font-normal"
                    } w-fit items-center  flex font-paragraph-p1-regular text-gray-50 gap-1  rounded-2.5 px-5 py-4 text-ft5`}
                  >
                    <img className="w-5 h-5" src={USDTiconSrc} alt="chart" />
                    <img
                      className="w-5 h-5"
                      src={XbriconSrc}
                      alt="chart"
                    />{" "}
                    <p className="ml-2">USDT / XBR</p>{" "}
                  </div>
                  <div className="flex flex-col md:hidden gap-1">
                    <div className="text-right font-normal text-ft5 text-gray-30">
                      Amount
                    </div>
                    <div
                      className={`${`${
                        theme.palette.mode === "light"
                          ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
                          : "  text-white"
                      }`} text-4xl font-paragraph-p1-regular font-bold`}
                    >
                      {removeLiquidityValue}%
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col pt-2">
                    <div
                      className={`${
                        theme.palette.mode === "light"
                          ? "text-gray-10"
                          : "text-gray-50"
                      } pb-2`}
                    >
                      Position ID
                    </div>
                    <div
                    ref={dropdownRef}
                      className="relative w-60"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <button
                        disabled={(positionIds || []).length === 0}
                        className={`${
                          theme.palette.mode === "light"
                            ? "text-gray-10 bg-white"
                            : "text-gray-50 bg-gray-200"
                        } text-gray-50 w-60 truncate  placeholder:text-gray-50 outline-mediumslateblue-300 bg-gray-200 rounded-lg py-2 px-4 cursor-pointer mb-1 flex justify-between items-center `}
                      >
                        {positionSelectedOption
                          ? positionSelectedOption.slice(0, 20) +
                            (positionSelectedOption?.length > 20 ? "..." : "")
                          : "Select Position ID"}

                        <img
                          src={Expand}
                          alt=""
                          className={`w-3 h-3 transform ${
                            isOpen ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                      {isOpen && positionIds?.length > 0 && (
                        <div
                          style={cardStyle}
                          className="absolute mt-1 w-full max-h-40 overflow-y-auto bg-midnightblue-100 rounded-lg shadow-lg z-10"
                        >
                          {positionIds.map((option) => (
                            <div
                              key={option}
                              className={`py-2 px-4 text-ft3 hover:bg-gray-200 font-paragraph-p1-regular cursor-pointer truncate ${
                                theme.palette.mode === "light"
                                  ? " text-gray-10 hover:bg-black hover:text-white"
                                  : "text-gray-50"
                              } ${
                                positionSelectedOption === option
                                  ? "bg-gray-200"
                                  : ""
                              }`}
                              onClick={() => {
                                handleOptionClick(option);
                                setIsOpen(false);
                                setPositionSelectedOption(option);
                              }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="md:flex flex-col hidden gap-1">
                    <div className="text-right font-normal text-ft5 text-gray-30">
                      Amount
                    </div>
                    <div
                      className={`${`${
                        theme.palette.mode === "light"
                          ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
                          : "  text-white"
                      }`} text-3xl font-paragraph-p1-regular font-bold`}
                    >
                      {removeLiquidityValue}%
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center mobile:flex-col">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    step="5"
                    value={removeLiquidityValue}
                    className="custom-slider"
                    style={trackStyle}
                    onChange={(e) =>
                      setRemoveLiquidityValue(parseFloat(e.target.value))
                    }
                  />
                  <div className="flex gap-2 text-ft5 md:ml-3 mobile:mt-3  ">
                    {[25, 50, 75, 100].map((percentage) => (
                      <div
                        key={percentage}
                        className={`cursor-pointer bg-${
                          removeLiquidityValue === percentage
                            ? `${
                                theme.palette.mode === "light"
                                  ? "black-0"
                                  : "white"
                              }`
                            : "none"
                        } font-${
                          removeLiquidityValue === percentage
                            ? "semibold"
                            : "normal"
                        } text-${
                          removeLiquidityValue === percentage
                            ? `${
                                theme.palette.mode === "light"
                                  ? "black"
                                  : "black"
                              } `
                            : `${
                                theme.palette.mode === "light" ? "gray-10" : ""
                              }`
                        } w-auto px-2.5 py-2 rounded-3xl ${
                          removeLiquidityValue === percentage
                            ? `${
                                theme.palette.mode === "light"
                                  ? "bg-black text-white"
                                  : "bg-white text-black"
                              }`
                            : `${
                                theme.palette.mode === "light"
                                  ? "bg-gray-30 "
                                  : ""
                              } `
                        }`}
                        onClick={() => handlePercentageButtonClick(percentage)}
                      >
                        {percentage === 100 ? "Max" : `${percentage}%`}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={`flex mt-4 px-1 flex-col gap-4 font-paragraph-p1-regular ${
                  theme.palette.mode === "light"
                    ? "text-gray-10 font-semibold"
                    : "text-lightgray-500"
                } text-ft5`}
              >
                <div className="flex justify-between">
                  <span>Pooled {token0Name}</span>
                  <span>{token0LiquidityAmount}</span>
                </div>
                <div className="flex justify-between">
                  <span>Pooled {token1Name}</span>
                  <span>{token1LiquidityAmount}</span>
                </div>
                <div className="border-b border-gray-50 opacity-40"></div>
                <div className="flex justify-between">
                  <span>Removable {token0Name}</span>
                  <span>{parseFloat(removeToken0Liquidity).toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Removable {token1Name}</span>
                  <span>{parseFloat(removeToken1Liquidity).toFixed(2)}</span>
                </div>
              </div>
              <button
                className={`flex-1 py-3 mt-3 md:w-[35%] w-full mx-auto   rounded-2.5 ${
                  theme.palette.mode === "light"
                    ? "bg-black text-white hover:bg-mediumslateblue-600"
                    : "bg-midnightblue-300 hover:rounded-lg hover:text-white hover:bg-[#5763F3] border-[2px] border-solid border-mediumslateblue-300"
                }  flex flex-row items-center justify-center      cursor-pointer`}
                onClick={() =>
                  removeLiquidity(
                    token0Address,
                    token1Address,
                    Number(removeToken0Liquidity),
                    Number(removeToken1Liquidity),
                    Number(positionSelectedOption),
                    walletProvider
                  )
                }
              >
                <div className=" relative text-ft15 font-semibold">
                  Remove Liquidity
                </div>
              </button>
            </div>
          )}
          {activeButton === 1 && (
            <div
              className={` ${
                theme.palette.mode === "light"
                  ? "border border-lightgray-500 bg-white"
                  : ""
              } rounded-3xl bg-midnightblue-300 flex flex-col items-start justify-start mobile:p-4 pt-4  box-border gap-[1rem] mb-4 w-full`}
            >
               <div className="flex mx-auto pt-2">
                  
                    <div
                    ref={dropdownRef}
                      className="relative w-60"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <button
                        disabled={(positionIds || []).length === 0}
                        className={`${
                          theme.palette.mode === "light"
                            ? "text-gray-10 bg-white"
                            : "text-gray-50 bg-gray-200"
                        } text-gray-50 w-60 truncate  placeholder:text-gray-50 outline-mediumslateblue-300 bg-gray-200 rounded-lg py-2 px-4 cursor-pointer mb-1 flex justify-between items-center `}
                      >
                        {positionSelectedOption
                          ? positionSelectedOption.slice(0, 20) +
                            (positionSelectedOption?.length > 20 ? "..." : "")
                          : "Select Position ID"}

                        <img
                          src={Expand}
                          alt=""
                          className={`w-3 h-3 transform ${
                            isOpen ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                      {isOpen && positionIds?.length > 0 && (
                        <div
                          style={cardStyle}
                          className="absolute mt-1 w-full max-h-40 overflow-y-auto bg-midnightblue-100 rounded-lg shadow-lg z-10"
                        >
                          {positionIds.map((option) => (
                            <div
                              key={option}
                              className={`py-2 px-4 text-ft3 hover:bg-gray-200 font-paragraph-p1-regular cursor-pointer truncate ${
                                theme.palette.mode === "light"
                                  ? " text-gray-10 hover:bg-black hover:text-white"
                                  : "text-gray-50"
                              } ${
                                positionSelectedOption === option
                                  ? "bg-gray-200"
                                  : ""
                              }`}
                              onClick={() => {
                                handleOptionClick(option);
                                setIsOpen(false);
                                setPositionSelectedOption(option);
                              }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
              <div className=" flex mobile:w-full flex-col items-start justify-start  gap-[1rem] w-full">
                <div
                  style={cardStyle}
                  className=" flex justify-between w-full rounded-2.5 mobile:rounded-2.5 bg-midnightblue-100  flex-row items-center  p-[1.5rem] z-[0]"
                >
                  <div className="flex flex-col items-start justify-center gap-[0.75rem]">
                    
                    <div
                      className={`rounded-2.5 ${
                        theme.palette.mode === "light"
                          ? "bg-lightgray-400"
                          : " bg-gray-400"
                      }   flex flex-row items-center justify-center py-[0.75rem] pr-[1.25rem] pl-[0.75rem] gap-[0.5rem] border-[1px] border-solid border-gray-300 w-32`}
                    >
                      <img
                        className="w-[1.5rem] h-[1.5rem] object-cover"
                        alt=""
                        src={USDTiconSrc}
                      />
                      <div className=" text-base leading-[19px]">USDT</div>
                    </div>
                    <div
                      className={` ${
                        theme.palette.mode === "light"
                          ? "text-gray-10"
                          : "text-gray-50"
                      } whitespace-nowrap mobile:text-ft1_5  text-ft5`}
                    >
                      Balance:{" "}
                      {formatBalance(parseFloat(usdtBalanceinCard).toFixed(2))} USDT
                    </div>
                  </div>
                  <div className=" flex flex-col   items-end justify-center gap-[0.75rem] text-right ">
                    <input
                      className={`
          relative bg-midnightblue-100 ${
            theme.palette.mode === "light"
              ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
              : "  text-white bg-transparent placeholder:text-white"
          } text-right  outline-none
          font-inter text-3xl md:text-ft8
          w-36 md:w-80per
          font-bold                   
        `}
                      type="text"
                      placeholder="0.00"
                      value={usdtAmountinCard}
                      disabled={!positionSelectedOption}
                      onWheel={(e) => e.preventDefault()}
                      onChange={(event) => {
                        onChangeFromToInputinCard(
                          event.target.value.replace(/,/g, ""),
                          "USDT"
                        );
                      }}
                    />
                    <div
                      className={` ${
                        theme.palette.mode === "light"
                          ? "text-gray-10"
                          : "text-gray-50"
                      } text-ft5`}
                    >
                      ≈ $0.0000
                    </div>
                    <div
                      className={` text-ft5 font-bold ${
                        theme.palette.mode === "light"
                          ? "text-red-30"
                          : "text-red-10"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => maxInCard("USDT")}
                    >
                      Max
                    </div>
                  </div>
                </div>
                <div className="w-[2.5rem] absolute self-center 3md:mt-37.5 mt-[8.5rem] 4md:mt-[8.5rem]  rounded-full bg-mediumslateblue-300 h-[2.5rem] flex flex-row items-center justify-center z-[2]">
                  <img className="h-5 cursor-pointer" alt="" src={addNew} />
                </div>
                <div
                  style={cardStyle}
                  className=" rounded-2.5 bg-midnightblue-100 w-full flex flex-row round items-center justify-between lg:justify-start p-[1.5rem] z-[0]"
                >
                  <div className="flex flex-col items-start  justify-center gap-[0.75rem]">
                    <div className=" leading-[1.5rem]"></div>
                    <div
                      className={`rounded-2.5 ${
                        theme.palette.mode === "light"
                          ? "bg-lightgray-400"
                          : " bg-gray-400"
                      }  flex flex-row items-center justify-center py-[0.75rem] pr-[1.25rem] pl-[0.75rem] gap-[0.5rem] border-[1px] border-solid border-gray-300 w-32`}
                    >
                      <img
                        className="w-[1.5rem]  h-[1.5rem] object-cover"
                        alt=""
                        src={XbriconSrc}
                      />
                      <div className=" leading-[1.5rem]">XBR</div>
                    </div>
                    <div
                      className={` ${
                        theme.palette.mode === "light"
                          ? "text-gray-10"
                          : "text-gray-50"
                      } whitespace-nowrap mobile:text-ft1_5 text-ft5`}
                    >
                      Balance:{" "}
                      {formatBalance(parseFloat(xbrBalanceInCard).toFixed(2))} XBR
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col w-[20%]  items-end justify-center gap-[0.75rem] text-right ">
                    {" "}
                    <input
                      style={cardStyle}
                      className={`
      relative bg-midnightblue-100 text-right ${`${
        theme.palette.mode === "light"
          ? " text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
          : "  text-white placeholder:text-white"
      }`} outline-none
      font-inter text-3xl md:text-ft8
      w-36 md:w-80per
      font-bold                   
      `}
                      placeholder="0.00"
                      type="text" // Changed to text
                      maxLength="20"
                      disabled={!positionSelectedOption}
                      value={xbrAmountinCard} // Use the sanitized input value directly
                      onChange={handleXbrInputChangeInCard} // Changed to handleXbrInputChange
                      onWheel={(e) => e.preventDefault()} // Prevent scrolling
                    />
                    <div
                      className={` ${
                        theme.palette.mode === "light"
                          ? "text-gray-10"
                          : "text-gray-50"
                      } text-ft5`}
                    >
                      ≈ $0.0000
                    </div>
                    <div
                      className={` text-ft5 font-bold ${
                        theme.palette.mode === "light"
                          ? "text-red-30"
                          : "text-red-10"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => maxInCard("XBR")}
                    >
                      Max
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex md:w-70per  mt-[15px] mx-auto w-full justify-center items-center  gap-[0.75rem] text-mediumslateblue-300">
                <button
                  className={`flex-1 h-[47px] min-h-[47px]  rounded-2.5 flex flex-row  ${
                    theme.palette.mode === "light"
                      ? "text-mediumslateblue-300 hover:text-white"
                      : "text-mediumslateblue-500"
                  } items-center justify-center py-3 px-[0.75rem] border-[2px] border-solid border-mediumslateblue-300  ${
                    isUSDTApprovedInCard
                      ? "border-transparent text-green-500 cursor-default"
                      : "border-mediumslateblue-300 hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                  }`}
                  onClick={() => {
                    if (!isUSDTApprovedInCard && !usdtLoadingInCard) {
                      handleApproveInCard(process.env.REACT_APP_USDT_ADDRESS, "USDT");
                    }
                  }}
                  disabled={usdtLoadingInCard || !usdtAmountinCard} // Disable button when loading
                >
                  <div className="relative font-paragraph-p1-regular text-ft5 font-semibold flex">
                    {usdtLoadingInCard ? (
                      // Show loader when loading
                      <div>
                        <BiLoaderAlt className=" animate-spin text-gray-50" />
                      </div>
                    ) : // Show approval status or button text
                    isUSDTApprovedInCard ? (
                      <>
                        <img
                          alt="NewCheck"
                          src={NewCheck}
                          className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                        ></img>
                        USDT Approved
                      </>
                    ) : (
                      "Approve USDT"
                    )}
                  </div>
                </button>

                <div
                  className={`flex-1 md:w-70per h-[47px] min-h-[47px]  w-full justify-center mobile:text-base rounded-2.5 ${
                    theme.palette.mode === "light"
                      ? "text-mediumslateblue-300"
                      : "text-mediumslateblue-500"
                  }  flex flex-row items-center justify-center py-[0.5rem] px-[0.75rem] border-[2px] border-solid border-mediumslateblue-300  ${
                    isXBRApprovedinCard
                      ? "border-transparent text-green-500 cursor-default"
                      : isUSDTApprovedInCard
                      ? "hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                      : "border-mediumslateblue-300"
                  }`}
                  onClick={() => {
                    if (isUSDTApprovedInCard && !xbrLoadingInCard) {
                      handleApproveInCard(
                        process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
                        "XBR"
                      );
                    }
                  }}
                  disabled={xbrLoadingInCard} // Disable button when loading
                >
                  <div className="relative font-paragraph-p1-regular text-ft15 font-semibold flex">
                    {xbrLoadingInCard ? (
                      // Show loader when loading
                      <div>
                        <BiLoaderAlt className=" animate-spin text-gray-50" />
                      </div>
                    ) : // Show approval status or button text
                    isXBRApprovedinCard ? (
                      <>
                        <img
                          alt="NewCheck"
                          src={NewCheck}
                          className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                        ></img>
                        XBR Approved
                      </>
                    ) : (
                      "Approve XBR"
                    )}
                  </div>
                </div>
              </div>

              {openSuccessfully && (
                <PopUpDialog
                  isOpen={openSuccessfully}
                  handleClose={() => setOpenSuccessfully(false)}
                  title="Liquidity Added!"
                  description="You've successfully added liquidity to the XBR-USDT pool."
                  transactionDetails={[
                    {
                      label: "Transaction ID",
                      value: dialogDatainCard.transactionHash,
                      color: "#13CC95",
                    },
                    {
                      label: "Deposited",
                      value: `${dialogDatainCard.usdtAmountinCard} USDT`,
                      color: "#ACADBB",
                    },
                    {
                      label: "Received LP Tokens",
                      value: `${dialogDatainCard.xbrAmountinCard} XBR`,
                      color: "#ACADBB",
                    },
                  ]}
                  footerText="View LP Tokens"
                  onFooterClick={() => setOpenSuccessfully(false)}
                />
              )}
              {openError && (
                <ErrorPopUPDialog
                  isOpen={openError}
                  image="1"
                  handleClose={handlePopClose}
                  title="Something went wrong!"
                  description="Check your wallet or internet connection. You can also contact us."
                  footerText="Contact Us"
                  onFooterClick={handlePopClose}
                />
              )}
              <div className="w-full flex flex-row justify-center items-center">
                <div
                  onClick={() => {
                    if (isXBRApprovedinCard) handleAddLiquidityinCard();
                  }}
                  className={` rounded-2.5 md:w-[37%] w-full bg-mediumslateblue-300 text-white h-[3rem] flex flex-row items-center justify-center py-[0.75rem] px-[1.25rem] box-border  ${
                    isXBRApprovedinCard
                      ? "hover:bg-opacity-80 hover:rounded-lg  hover:text-white cursor-pointer"
                      : liquidityCheck
                      ? "border-transparent text-green-500 cursor-default"
                      : ""
                  }`}
                >
                  <div className=" text-ft15 font-semibold flex">
                    {liquidityInCard ? (
                      <div>
                        <BiLoaderAlt className=" animate-spin text-gray-50" />
                      </div>
                    ) : liquidityCheckinCard ? (
                      <>
                        <img
                          alt="NewCheck"
                          src={NewCheck}
                          className="w-5 h-5 mr-2 fill-current mt-[2px] rounded-lg"
                        ></img>
                        Liquidity Added
                      </>
                    ) : (
                      "Increase Liquidity"
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="md:w-[45%] w-full fullScreen:w-[600px] max-w-[700px] ">
        <div
          className={` ${
            theme.palette.mode === "light"
              ? "border border-lightgray-500 bg-white text-gray-200"
              : ""
          }  -mb-0.5 mobile:mb-30per  bg-midnightblue-300 flex flex-col items-center justify-start p-[1.5rem] box-border gap-[1rem] text-lightgray-100 rounded-3xl`}
        >
          <div
            className={` ${
              theme.palette.mode === "light"
                ? " text-gray-200"
                : "text-midnightblue-500"
            } flex flex-row ml-auto justify-end items-end gap-[0.5rem]`}
          >
            <div className="tracking-[0.15px] leading-[1.5rem]">
              Volume 24hrs
            </div>
            <b className="leading-[1.5rem]">$139.7K</b>
          </div>
          <div className="flex flex-row w-full ">
            <div
              className={`flex flex-row whitespace-nowrap items-center justify-start py-[0.5rem] px-[1rem]  border-b-[3px] ${
                theme.palette.mode === "light"
                  ? " text-gray-200"
                  : "text-midnightblue-500"
              } ${
                activeTab === "latest"
                  ? "border-mediumslateblue-500 font-semibold"
                  : "border-transparent"
              }`}
              onClick={() => setActiveTab("latest")}
            >
              <div className="text-ft5 cursor-pointer  ">Latest Trades</div>
            </div>
            <button
              className={`flex cursor-default flex-row whitespace-nowrap items-center justify-center py-[0.5rem] px-[1rem]  border-b-[3px] ${
                theme.palette.mode === "light"
                  ? " text-gray-200"
                  : "text-midnightblue-500"
              } ${
                activeTab === "your"
                  ? "border-mediumslateblue-500 font-semibold"
                  : "border-transparent"
              }`}
              onClick={() => setActiveTab("your")}
            >
              <div className="text-ft5  ">Your Trades</div>
            </button>
          </div>
          <div className="w-full  flex-1 overflow-x-auto flex-col items-start justify-start gap-[0.25rem] ">
            <LiquidityTable
              activeTab={activeTab}
              connectedWalletAddress={connectedAccount}
              token="XBR"
            />
          </div>
        </div>
        {/* <Toaster /> */}
      </div>
    </div>
  );
};

export default LiquidityComponent;
