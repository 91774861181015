import React from "react";
import notFoundImage from "../assets/404-NotFound.png";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";
import smiley from '../assets/smiley.svg'
import Footer from "./Footer";

const PageNotFound = () => {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  return (
    <div className={`w-full h-full flex flex-col  items-center 
     font-inter text-center p-2 ${themeMode==='dark'?"bg-[#02042C]":""}`}>
         {themeMode === 'light' && 
        <div className="flex items-center gap-4 mt-[10vh]">
          <span className="text-black text-[20vw] sm:text-[150px] font-[700] leading-[120%] tracking-[-5px]">4</span>
          <img
            src={smiley}
            alt="Page Not Found"
            className="h-[20vw] sm:h-[150px] max-w-full"
          />
          <span className="text-black text-[20vw] sm:text-[150px] font-[700] leading-[120%] tracking-[-5px]">4</span>
        </div>
      }
      {themeMode === 'dark'&&
      <div className="mb-4">
      <img
        src={notFoundImage}
        alt="Page Not Found"
        className="max-w-full h-[50vh]"
      />
    </div>}
      <div
        className={`text-${themeMode === "light" ? "black" : "#9BA1F7"} text-[50px] ${themeMode !== "light" ? "-mt-15" : "my-2"
        } font-[700] leading-[120%] tracking-[-2px]`}
      >
        OH SNAP!
      </div>
      <p
        className={`text-${
          themeMode === "light" ? "black" : "#C5C5D1"
        }eading-[120%] text-[16px] mt-5`}
      >
        Something went wrong with this page. It’s look like you're lost
      </p>
      <Link to="/">
        <span className="flex justify-center py-[12px] px-[20px] gap-4 w-[180px] rounded-[12px] bg-[#5663F2] text-[#F7F7FE] mt-8">
          Go Back to Home
        </span>
      </Link>
      <Footer/>
    </div>
  );
};

export default PageNotFound;