import walletConnectIcon from "../assets/Logo.svg";
export const polygonMainnet = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: "https://polygon-mainnet.infura.io/v3/e38dcd945e074bb3856193f6db0db99e",
};

export const metadata = {
    name: "XBR Foundation Lab",
    description: "XBR Foundation Lab",
    url: "https://xbullrun.org", // origin must match your domain & subdomain
    icons: [walletConnectIcon],
};

export const projectId = "58ebf507662e42d51ade0fc8d65ef0f1";







