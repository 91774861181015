import { useState } from "react";
import { useTheme } from "@mui/material";
import { MetaMask_Fox, trustWalletNew, walletConnectNew } from "../../assets";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useDisconnect,
} from "@web3modal/ethers/react";
import { metadata, projectId, polygonMainnet } from "../../walletConnect/connect";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BrowserProvider } from "ethers";
import {
  setAccount,
  setCurrentDisplay,
  setIsMetaMaskConnected,
  setProvider,
  // setProviderParams,
  setIsWalletConnect,
  setSelectedWallet,
  setUserBalance,
} from "../../redux/wallet/walletSlice";
import { useDispatch } from "react-redux";
import { useSyncProviders } from "../../hooks/useSyncProvider";
import { balanceOfFromContract, getNFTCount } from "../../uniswap/utils";

const WalletConnect = (props) => {
  const {address, chainId } = useWeb3ModalAccount();
  const {open} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const providers = useSyncProviders();
  const { isWalletConnect, account } = useSelector((state) => state.wallet);
  const [wallet, setWallet] = useState(account);
    const [nodesCount, setNodesCount] = useState(0);
     const nodes = localStorage.getItem("nodeCount");
  const {walletProvider} = useWeb3ModalProvider();
  console.log(walletProvider, "walletProvider");
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [polygonMainnet],
    projectId,
    enableAnalytics: true,
  });

  // const { open } = useWeb3Modal(web3Modal);
  const { disconnect } = useDisconnect();
  
      const fetchNodesCount = async (account) => {
        try {
          console.log("Fetching nodes count...");
          const count = await balanceOfFromContract(account);
          console.log("count: " + count);
          setNodesCount(Number(count));
          
           localStorage.setItem("nodeCount", count);
               if (count > 0) {
                 navigate("/node");
               } else {
                 navigate("/buy-node");
               }
        } catch (error) {
          console.error("Failed to fetch nodes count", error);
        }
      };
  useEffect(() => {
    if (address) {
      localStorage.setItem("connectedAccount", address);
      localStorage.setItem("isWallet", "true");
      localStorage.setItem("walletName", "Wallet Connect");
      dispatch(setProvider(chainId));
      dispatch(setAccount(address));
      dispatch(setCurrentDisplay(address));
      dispatch(setIsWalletConnect(true));
          fetchNodesCount(address);
      const isWallet = localStorage.getItem("isWallet");
      console.log("isWallet", isWallet);
      // window.location.reload();
      // if (nodes > 0) {
      //   navigate("/node");
      // } else {
      //   console.log('//----')
      //   navigate("/");
      // }
    }
  }, [address, chainId, dispatch, navigate]);

  const handleWalletConnect = async () => {
    try {
      await open();

      if (address) {
        console.log('address', address);
        // dispatch(setProvider(chainId));
        // dispatch(setAccount(address));
        // dispatch(setCurrentDisplay(address));
        // dispatch(setIsWalletConnect(true));

        localStorage.setItem("connectedAccount", address);
        localStorage.setItem("walletName", "Wallet Connect");

      // if (nodes > 0) {
      //   navigate("/node");
      // } else {
      //   navigate("/");
      // }
      } else {
        console.error("Failed to get wallet address.");
      }
    } catch (error) {
      console.error('Error in handleWalletConnect:', error);
    }
  };


  const handleMetamask = async (providers) => {
    try {

      console.log("handleMetamask============");
      console.log("providers", providers);
      const providerWithInfo = providers.find(
        (provider) => provider.info.name === "MetaMask"
      );

      if (!providerWithInfo) {
        toast.error("You don't have MetaMask installed", { duration: 1000 });
        return;
      }
      const accounts = await providerWithInfo.provider.request({
        method: "eth_requestAccounts",
      });

      // dispatch(setProviderParams(await providerWithInfo.provider));

      const ethersProvider = new BrowserProvider(
        await providerWithInfo.provider
      );

      const providerData = {
        network: Number((await ethersProvider?.getNetwork()).chainId),
      };
      console.log("accounts", accounts);

      dispatch(setProvider( providerData));
      dispatch(setAccount(await accounts[0]));
      dispatch(setCurrentDisplay(await accounts[0]));
      dispatch(setIsMetaMaskConnected(true));
      dispatch(setIsWalletConnect(true));

      localStorage.setItem("connectedAccount", accounts[0]);
      localStorage.setItem("isWallet", "true");
      localStorage.setItem("walletName", "MetaMask");
      // dispatch(setCurrentDisplay(localStorage.getItem("connectedAccount")));
      // dispatch(setAccount(localStorage.getItem("connectedAccount")));
      const connectedAccount = localStorage.getItem("connectedAccount");
     await fetchNodesCount(connectedAccount);
        //  if (nodes > 0) {
        //    navigate("/node");
        //  } else {
        //    navigate("/");
        //  }
      console.log(
        "LocalStorage Account:",
        localStorage.getItem("connectedAccount")
      );
            console.log(
              "LocalStorage Wallet:",
              localStorage.getItem("isWallet")
            );
      console.log(
        "LocalStorage WalletName:",
        localStorage.getItem("walletName")
      );
    } catch (error) {
      console.error("Error checking MetaMask connection:", error);
    }
  };


  const handleTrustWallet = async (providers) => {
    try {
      const providerWithInfo = providers.find(
        (provider) => provider.info.name === "Trust Wallet"
      );

      if (!providerWithInfo) {
        toast.error("You don't have Trust Wallet installed", {
          duration: 1000,
        });
        return;
      }

      const accounts = await providerWithInfo.provider.request({
        method: "eth_requestAccounts",
      });

      const ethersProvider = new BrowserProvider(
        await providerWithInfo.provider
      );

      const providerData = {
        network: Number((await ethersProvider.getNetwork()).chainId),
      };

      dispatch(setProvider(providerData));
      dispatch(setAccount(accounts[0]));
      dispatch(setCurrentDisplay(accounts[0]));
      dispatch(setIsMetaMaskConnected(false));
      dispatch(setIsWalletConnect(true));

      localStorage.setItem("connectedAccount", accounts[0]);
      localStorage.setItem("isWallet", "true");
      localStorage.setItem("walletName", "Trust Wallet");
       const connectedAccount = localStorage.getItem("connectedAccount");
       await fetchNodesCount(connectedAccount);
          if (nodes > 0) {
            navigate("/node");
          } else {
            navigate("/");
          }
    } catch (error) {
      console.error("Error checking Trust Wallet connection:", error);
    }
  };


  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const querty = theme.palette.disabeled.back;
  const quertyColor = theme.palette.disabeledColor.backColor;
  const modalColor = theme.palette.bgColor.modalBg;

  const handleBackButton = async () => {
      navigate("/swap");
    return;
  };
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };
 
  return (
    <div style={{ background: alt }} className="min-h-screen h-full">
      {/* <Navbar /> */}
      <div className="flex-col flex items-center gap-6 py-13">
        {account ? (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            Connected to {shortenAddress(wallet)}
          </div>
        ) : (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            You're not connected
          </div>
        )}
        <div
          className={`${
            theme.palette.mode === "light"
              ? "bg-white text-black  border border-gray-30 "
              : "bg-[#0B0E4A] text-white"
          } p-8 rounded-3xl max-w-[437px] w-full flex flex-col gap-4`}
        >
          <div
            className={`flex text-xl font-semibold justify-center ${
              theme.palette.mode === "light" ? "text-black" : "text-[#9BA1F7]"
            } `}
          >
            LOGIN WITH WEB3
          </div>
          <button
            className={`flex w-full p-3 ${
              theme.palette.mode === "light"
                ? "hover:bg-black hover:text-white"
                : "text-[#9BA1F7] hover:bg-[#5763F3] hover:text-white"
            } items-center`}
            style={{
              borderRadius: "12px",
              border:
                theme.palette.mode === "light"
                  ? "2px solid var(--Neutral-200, #D6D7E3)"
                  : "2px solid var(--Dark-100, #1D205F)",
            }}
            onClick={() => handleMetamask(providers)}
          >
            <img src={MetaMask_Fox} alt="Metamask" className="mr-2 h-6 w-6" />
            <p className="text-ft5 font-medium font-inter">Metamask</p>
          </button>

          <button
            className={`flex mobile:hidden w-full p-3 ${
              theme.palette.mode === "light"
                ? "hover:bg-black hover:text-white"
                : "text-[#9BA1F7] hover:bg-[#5763F3] hover:text-white"
            } items-center`}
            style={{
              borderRadius: "12px",
              border:
                theme.palette.mode === "light"
                  ? "2px solid var(--Neutral-200, #D6D7E3)"
                  : "2px solid var(--Dark-100, #1D205F)",
            }}
            onClick={() => handleTrustWallet(providers)}
          >
            <img src={trustWalletNew} alt="trust" className="mr-2 h-6 w-6" />
            <p className="text-ft5 font-medium font-inter">Trust Wallet</p>
          </button>

          <button
            className={`flex w-full p-3 ${
              theme.palette.mode === "light"
                ? "hover:bg-black hover:text-white"
                : "text-[#9BA1F7] hover:bg-[#5763F3] hover:text-white"
            } items-center`}
            style={{
              borderRadius: "12px",
              border:
                theme.palette.mode === "light"
                  ? "2px solid var(--Neutral-200, #D6D7E3)"
                  : "2px solid var(--Dark-100, #1D205F)",
            }}
            onClick={() => handleWalletConnect()}
          >
            <img src={walletConnectNew} alt="wallet" className="mr-2 h-6 w-6" />
            <p className="text-ft5 font-medium font-inter">Wallet Connect</p>
          </button>
        </div>

        <button
          className={`text-ft5 px-4 py-2 rounded-xl w-[10%] h-[10%] ${
            theme.palette.mode === "light"
              ? "text-[#5B5C71] hover:text-black"
              : "text-[#9192A3] hover:text-white"
          }`}
          onClick={handleBackButton}
        >
          Back
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default WalletConnect;