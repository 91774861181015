import { useTheme } from "@mui/material";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import TradesTable from "./trade/tradeTable";

const LatestTrades = ({
  activeTab,
  connectedAccount,
  selectedToken,
  selectedToken2,
  swapConfirmModal
}) => {
  const theme = useTheme();
  const handleClick = () => {
    if (selectedToken.name === "XBR" || selectedToken2.name === "XBR") {
      window.open(
        "https://dexscreener.com/polygon/0x1eb8dfe76ee03fd68aa58f849cad5590b4d12617",
        "_blank"
      );
    } else if (selectedToken.name === "TBA" || selectedToken2.name === "TBA") {
      window.open(
        "https://dexscreener.com/polygon/0x58f4112b2f44185d2613313bf2f0c6736a2717a6",
        "_blank"
      );
    }
  };
  return (
    <div
      className={`w-full max-w-[628px] p-[24px] sm:p-[64px] box-border font-paragraph-p1-regular  flex flex-col items-center justify-start  relative rounded-3xl overflow-hidden`}
    >
      {/* Gradient Background for Light Mode */}
      {theme.palette.mode === "light" && (
        <div
          style={{
            width: "564px",
            height: "782px",
            position: "absolute",
            left: "-169px",
            bottom: "-170px",
            background:
              "linear-gradient(179deg, #FF81F2 19.36%, #8419FE 37.58%, #3A3FCF 59.38%, #5397FF 76.99%)",
            opacity: "0.2",
            filter: "blur(190.72238159179688px)",
            zIndex: 0,
          }}
        ></div>
      )}
      {theme.palette.mode === "dark" && (
        <>
          <div
            style={{
              width: "564px",
              height: "782px",
              position: "absolute",
              left: "-169px",
              bottom: "-170px",
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              opacity: "0.2",
              filter: "blur(190.72238159179688px)",
              zIndex: 0,
            }}
          ></div>
        </>
      )}

      {/* Content */}
      <div className="relative z-10 flex flex-col w-full">
        <h3
          className={`text-[25px] font-bold leading-[150%] ${
            theme.palette.mode === "light" ? "text-[#181821]" : "text-[#DCDCDE]"
          }`}
        >
          Latest Trades
        </h3>
        <p
          className={`text-[16px] leading-[150%] ${
            theme.palette.mode === "light" ? "text-[#56565D]" : "text-[#97979B]"
          }`}
        >
          Dive deeper. Explore the full market for comprehensive{" "}
          <span className="hidden 2xl:inline 4xl:inline">
            {" "}
            <br />{" "}
          </span>{" "}
          insights.
        </p>
      </div>

      <div className="w-full mt-16 flex-1 overflow-x-auto hide-scrollbar flex flex-col items-start justify-start gap-[0.25rem] ">
        <TradesTable
          activeTab={activeTab}
          connectedWalletAddress={connectedAccount}
          selectedToken={selectedToken}
          selectedToken2={selectedToken2}
          swapConfirmModal={swapConfirmModal}
        />
      </div>

      <div className="flex flex-row text-ft4 mt-13 justify-start gap-[0.5rem] w-full">
        <button
          onClick={handleClick}
          className={`h-[48px] w-full sm:w-[248px] rounded-[12px] font-normal text-ft5 flex justify-center items-center gap-1 z-10 ${
            theme.palette.mode === "light"
              ? " text-[#181821] bg-[#FFFFFF]"
              : " text-[#DCDCDE] bg-[#02042C]"
          }`}
        >
          Full Market Details{" "}
          <MdKeyboardArrowRight
            className={`h-[20px] w-[20px] ${
              theme.palette.mode === "light"
                ? "text-[#181821]"
                : "text-[#DCDCDE]"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default LatestTrades;
