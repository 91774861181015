import React from "react";
import { useTheme } from "@mui/material";
import { ReactComponent as BarArrowSvg } from "../assets/barArrow.svg";
import { ReactComponent as HolerSvg } from "../assets/Holder.svg";
import { ReactComponent as LiquiditySvg } from "../assets/Liquidity.svg";
import { ReactComponent as SupplySvg } from "../assets/Supply.svg";

const PathToSuccess = ({
  fdv,
  holdersCount,
  liquidity,
  totalSupply,
  selectedToken,
  selectedToken2,
}) => {
  const theme = useTheme();

  const handleSwapClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative w-full max-w-[628px] overflow-hidden rounded-3xl z-0 bg-transparent p-6 sm:p-16 flex flex-col items-center justify-start gap-[1rem] ">
      {/* Gradient Background for Light Mode */}
      {theme.palette.mode === "light" && (
        <div
          style={{
            width: "564px",
            height: "782px",
            position: "absolute",
            left: "-169px",
            bottom: "-170px",
            background:
              "linear-gradient(179deg, #FF81F2 19.36%, #8419FE 37.58%, #3A3FCF 59.38%, #5397FF 76.99%)",
            opacity: "0.3",
            filter: "blur(190.72238159179688px)",
            zIndex: 0,
          }}
        ></div>
      )}
      {theme.palette.mode === "dark" && (
        <>
          <div
            style={{
              width: "564px",
              height: "782px",
              position: "absolute",
              left: "-169px",
              bottom: "-170px",
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              opacity: "0.2",
              filter: "blur(190.72238159179688px)",
              zIndex: 0,
            }}
          ></div>
        </>
      )}

      {/* Content */}
      <div
        className={`relative w-full z-0  max-sm:mt-10 bg-transparent box-border gap-[5%] sm:gap-[20%] text-darkgray`}
      >
        <h2
          className={`text-[25px] font-bold leading-[150%] ${
            theme.palette.mode === "light" ? "text-[#181821]" : "text-[#DCDCDE]"
          }`}
        >
          Swap With Ease
        </h2>
        <span
          className={`text-[16px] leading-[150%] ${
            theme.palette.mode === "light" ? "text-[#56565D]" : "text-[#97979B]"
          }`}
        >
         Trade XBR and TBA for USDT with no hassle on our seamless platform.
        </span>
        <div className="flex gap-[12%] sm:gap-[20%] mt-6 sm:mt-16 lg:h-[262px]">
          {/* Column 1: Market Cap and Liquidity */}
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start justify-start gap-[0.5rem]">
              <BarArrowSvg
                className={`text-[40px] ${
                  theme.palette.mode === "light"
                    ? "text-[#959DF7]"
                    : "text-[#959DF7]"
                }`}
              />
              <div
                className={`leading-[120%] 4md:text-sm text-[30px] font-bold ${
                  theme.palette.mode === "light"
                    ? "text-[#181821]"
                    : "text-[#DCDCDE]"
                }`}
              >
                ${fdv}
              </div>
              <div className="tracking-[0.15px] text-[#56565D] text-ft5">
                Market Cap
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[0.5rem]">
              <LiquiditySvg
                className={`text-[40px] ${
                  theme.palette.mode === "light"
                    ? "text-[#959DF7] -ml-2"
                    : "text-[#959DF7] -ml-2"
                }`}
              />
              <div
                className={`leading-[120%] 4md:text-sm text-[30px] font-bold ${
                  theme.palette.mode === "light"
                    ? "text-[#181821]"
                    : "text-[#DCDCDE]"
                }`}
              >
                ${liquidity}
              </div>
              <div className="tracking-[0.15px] text-[#56565D] text-ft5">
                Liquidity
              </div>
            </div>
          </div>

          {/* Column 2: XBR Holders and Circulating Supply */}
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start justify-start gap-[0.5rem]">
              <HolerSvg
                className={`text-[40px] ${
                  theme.palette.mode === "light"
                    ? "text-[#959DF7] -ml-2"
                    : "text-[#959DF7] -ml-2"
                }`}
              />
              <div
                className={`leading-[120%] 4md:text-sm text-[30px] font-bold ${
                  theme.palette.mode === "light"
                    ? "text-[#181821]"
                    : "text-[#DCDCDE]"
                }`}
              >
                {holdersCount}
              </div>
              <div className="tracking-[0.15px] text-[#56565D] text-ft5">
                {selectedToken?.name === "XBR" || selectedToken2?.name === "XBR"
                  ? "XBR"
                  : selectedToken?.name === "TBA" ||
                    selectedToken2?.name === "TBA"
                  ? "TBA"
                  : "Other"}{" "}
                Holders
              </div>
            </div>
            <div className="flex flex-col justify-start gap-[0.5rem]">
              <SupplySvg
                className={`text-[40px] ${
                  theme.palette.mode === "light"
                    ? "text-[#959DF7]"
                    : "text-[#959DF7]"
                }`}
              />
              <div
                className={`leading-[120%] 4md:text-sm text-[30px] font-bold ${
                  theme.palette.mode === "light"
                    ? "text-[#181821]"
                    : "text-[#DCDCDE]"
                }`}
              >
                {totalSupply}{" "}
                {selectedToken?.name === "XBR" || selectedToken2?.name === "XBR"
                  ? "XBR"
                  : selectedToken?.name === "TBA" ||
                    selectedToken2?.name === "TBA"
                  ? "TBA"
                  : "Other"}
              </div>
              <div className="tracking-[0.15px] text-[#56565D] text-ft5 lg:text-ft2">
                Circulating supply
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={handleSwapClick}
          className="relative w-full sm:w-[248px] flex justify-center items-center gap-2 rounded-[12px] bg-[#5763F3] hover:bg-[#747FF4] transition-colors duration-300 cursor-pointer text-white px-6 py-3 text-[16px] leading-[150%] font-semibold mt-16 z-10"
        >
          Swap Now
        </button>
      </div>
    </div>
  );
};

export default PathToSuccess;
