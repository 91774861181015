import { useTheme } from "@mui/material";
import React, { useState } from "react";

const CustomTooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  const theme = useTheme();
  const isLightMode = theme.palette.mode === 'light';

  return (
    <div
      className="relative inline-block"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && (
        <div className={`absolute left-1/2 transform -translate-x-1/2 bottom-full mb-4 px-3 py-2 ${isLightMode?"bg-[#EEEFFE] text-[#4954CE]":"bg-[#181B56] text-white"}  text-sm font-medium rounded-lg z-10 whitespace-no-wrap min-w-[120px]`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
