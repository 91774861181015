import React, { useEffect, useState } from "react";
import { useTheme, Button, IconButton } from "@mui/material";
import { isAddress } from "ethers";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { approveForTransferNodes, transferNodes } from "../../uniswap/utils";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BiLoaderAlt } from "react-icons/bi";
import { NewCheck } from "../../assets";

const TransferNodeComponent = (props) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { account } = useSelector((state) => state.wallet);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const alt = theme.palette.background.alt;
  const { address } = useWeb3ModalAccount();
  const [wallet, setWallet] = useState(account);
  const [nodesCount, setNodesCount] = useState(1);
  const [referralAddress, setReferralAddress] = useState("");
  const [quantity, setQuantity] = useState(1);

  const { nodes = [] } = location.state || {}; // Provide default value for nodes
  console.log("TransferNodeComponent", nodes);

  const [selectedNode, setSelectedNode] = useState("");
  const [approve, setApprove] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeReferralAddress = async (value) => {
    setReferralAddress(value);
  };

  const handleApprove = async (address) => {
    try {
      if (!isApproved && !isLoading) {
        setIsLoading(true);
        const approval = await approveForTransferNodes(
          selectedNode,
          address,
          walletProvider
        );
        setIsLoading(false);
        if (approval) {
          setIsApproved(true);
          setApprove(true);
        }
      }
    } catch (error) {
      console.log("handleApprove error", error);
    }
  };

  const handleProceed = async (address) => {
    try {
      console.log("handleTransfer", address);
      if (isAddress(address)) {
        const tx = await transferNodes(selectedNode, address, walletProvider);
        console.log("nodes Address", nodesCount);
        if (tx) {
          navigate("/node", { state: { quantity } });
        }
      }
    } catch (error) {
      console.log("handleTransfer error", error);
    }
  };

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  return (
    <div style={{ background: alt }} className="min-h-screen h-full">
      <div className="flex-col flex items-center gap-4 font-inter py-13">
        {account ? (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            Connected to {shortenAddress(wallet)}
          </div>
        ) : (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            Not Connected
          </div>
        )}

        <div
          className={`p-6 ${
            theme.palette.mode === "light"
              ? "bg-white border border-[#D6D7E3] text-black"
              : "bg-[#070A47] text-[#F7F7FE]"
          } rounded-3xl max-w-[524px] w-full relative `}
        >
          <div className="flex text-ft22 font-bold mb-4 justify-center ">
            Transfer Node
          </div>

          <div className="flex flex-col text-[#ACADBB] gap-4 z-30 pt-4">
            <div className="text-ft4 font-normal ">Select Node</div>
            <div className="flex space-x-4 overflow-x-auto hide-scrollbar">
              {nodes.map((eachNode) => (
                <div key={eachNode.nodeId}>
                  <div
                    onClick={() => setSelectedNode(eachNode.nodeId)}
                    className={`flex flex-col justify-end items-start w-[200px] cursor-pointer h-[113px] p-5 gap-6 flex-shrink-0 rounded-2xl border-2 ${
                      selectedNode === eachNode.nodeId
                        ? "border-[#5663F2]"
                        : "border-transparent"
                    } ${
                      theme.palette.mode === "light"
                        ? "bg-[#E6E7F0] text-[#141527]"
                        : "bg-[#050840]"
                    }`}
                  >
                    <div
                      className={`font-bold mt-20 ${
                        selectedNode === eachNode.nodeId
                          ? "text-[#1422ED]"
                          : theme.palette.mode === "light"
                          ? "text-[#141527]"
                          : "text-[#ACADBB]"
                      }  text-ft22`}
                    >
                      Node {eachNode.nodeId}
                    </div>

                    <div className="flex items-center font-paragraph-p1-regular justify-between w-full">
                      {selectedNode === eachNode.nodeId && (
                        <div className="w-6 h-3 rounded-xl bg-[#5663F2] mr-2"></div>
                      )}
                      <div className="flex-grow"></div>
                      <div
                        className={`flex text-ft5 justify-end ${
                          selectedNode === eachNode.nodeId
                            ? "text-[#1422ED]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        {eachNode.totalRewardsEarned}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-1.5 mt-4 relative flex-col z-30">
            <label className="text-ft5 ">Receiver Address</label>
            <input
              className={`  ${
                theme.palette.mode === "light"
                  ? " bg-[#E6E7F0] "
                  : " bg-[#21246A]"
              }  outline-none px-4 py-3 rounded-xl text-ft5 font-normal placeholder:text-[#9192A3]`}
              placeholder="Enter your receiver address"
              onChange={(event) => onChangeReferralAddress(event.target.value)}
              value={referralAddress}
            />
          </div>

          <div className="flex gap-2 mt-4 items-center">
            <button
              className={`flex-1 w-[187px] py-3 rounded-2.5 flex flex-row ${
                theme.palette.mode === "light"
                  ? "text-mediumslateblue-300"
                  : "text-mediumslateblue-500"
              } items-center justify-center border-[2px] border-solid border-mediumslateblue-300 ${
                approve
                  ? "border-transparent text-green-500 cursor-default"
                  : `border-mediumslateblue-300 ${
                      !isLoading
                        ? "hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                        : ""
                    }`
              }`}
              onClick={() => handleApprove(referralAddress)}
              disabled={approve || isLoading}
            >
              <div className="relative font-paragraph-p1-regular text-ft15 font-semibold flex">
                {approve ? (
                  <span className="flex gap-1 flex-row items-center">
                    <img
                      alt="NewCheck"
                      src={NewCheck}
                      className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                    />
                    Approved
                  </span>
                ) : isLoading ? (
                  <div className="flex justify-center">
                    <BiLoaderAlt
                      className={`animate-spin w-5 h-5 ${
                        theme.palette.mode === "light"
                          ? "text-[#5663F2]"
                          : "text-white"
                      }`}
                    />
                  </div>
                ) : (
                  "Approve"
                )}
              </div>
            </button>
            <button
              onClick={() => handleProceed(referralAddress)}
              disabled={isLoading}
              className={`w-[187px] flex-1 rounded-xl text-ft5 font-semibold whitespace-normal ${
                theme.palette.mode === "light"
                  ? "text-[#5663F2]"
                  : "text-[#B4B8F9]"
              } border-2 border-[#5663F2] py-3 ${
                isLoading
                  ? "opacity-50 cursor-default"
                  : "hover:bg-[#5663F2] hover:text-white"
              }`}
            >
              Proceed
            </button>
          </div>
        </div>

        <button
          className="text-ft5 font-semibold font-inter text-[#5B5C71] mt-4 px-4 py-2 rounded-xl hover:border hover:border-[#5B5C71] "
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default TransferNodeComponent;
