import Logo from "../assets/xbulllogo.png";
import {ReactComponent as Divider} from "../assets/footerDivider.svg";
import gradient from "../assets/footerGradient.svg";
import gridOverlay from "../assets/footerGrid.png";
import { ExternalNavigation } from "./ExternalNavigation.jsx";
import { footerBottomLinks, socials } from "../constants/Data.jsx";
import { useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  const date = new Date();
  const currentYear = date.getFullYear();

  const isLightMode = theme.palette.mode === "light";

  return (
    <footer className="max-w-[1600px] mx-auto mt-10">
      <div
        className="relative font-sans rounded-[32px] px-6 py-5 md:py-8 md:px-16 my-10 sm:my-20 text-[16px] overflow-hidden m-2 sm:m-4 lg:m-6"
        style={{
          backgroundColor: isLightMode ? "#f8f9fb" : "#151447",
        }}
      >
        <div className="box overflow-hidden">
          <img
            className="absolute top-0 left-0 pointer-events-none blur-[5rem]"
            src={gradient}
            alt="gradient"
          />
          <img
            className="absolute left-1/2 -translate-x-1/2 sm:-translate-y-0 sm:translate-x-0 top-10 sm:top-[2.75rem] sm:left-[2.5rem] pointer-events-none max-w-[300px] sm:max-w-[306px]"
            src={gridOverlay}
            alt="gridOverlay"
          />

          {/* Footer Content */}
          <div className="flex flex-col gap-y-8 xl:flex-row justify-between items-start w-full mb-0 lg:mb-5 gap-8">
            <div className="flex flex-col gap-5">
              <div className="max-w-full lg:max-w-[29.25rem] mb-0 sm:mb-12">
                <img
                  className="max-w-[13.75rem] mb-4 lg:mb-5"
                  src={Logo}
                  alt="logo"
                />
                <p
                  className="text-[17px] leading-[150%] tracking-wide"
                  style={{
                    color: isLightMode ? "#181821" : "#ffffff",
                  }}
                >
                  XBR Foundation builds protocol infrastructure for
                  decentralized basket of token or coin, not creating or
                  endorsing any Xstaking pools or investment strategies.
                </p>
              </div>
            </div>
            <ExternalNavigation
              className={isLightMode ? "text-[#181821]" : "text-white"}
            />
          </div>
          <div className="grid row-span-4 grid-cols-2 sm:row-span-2 sm:grid-cols-4 lg:row-span-1 lg:grid-cols-8 sm:justify-center mt-5 sm:px-8 gap-1">
            {socials.map((social) => (
              <a
                key={social.id}
                className={`flex flex-col py-4 px-6 rounded-[12px] transition-colors duration-300 
                ${
                  isLightMode
                    ? "text-[#181821] fill-[#181821] hover:bg-[#EEEFFE] hover:text-[#5663F2] hover:fill-[#5663F2]"
                    : "text-white fill-white hover:bg-[#0B0E4A] hover:text-[#5663F2] hover:fill-[#5663F2]"
                } 
                `}
                href={social.href ? social.href : "#"}
                target="_blank"
              >
                <social.icon className="w-6 h-6" />
                <span className="mt-2">{social.name}</span>
              </a>
            ))}
          </div>

          <div className="-mx-6 sm:mx-0 pt-8 pb-5 ">
            <Divider className="w-full"
              style={{
                fill: isLightMode ? "#97979B" : "#ffffff",
                color: isLightMode ? "#97979B" : "#ffffff",
              }}/>
          </div>
          <div className="flex items-center gap-2 lg:gap-4 justify-between flex-col lg:flex-row">
            <span
              className="text-sm lg:text-base"
              style={{
                color: isLightMode ? "#181821" : "#ffffff",
              }}
            >
              ©{currentYear} XBR FOUNDATION LAB
            </span>
            <ul className="flex gap-8 items-center">
              {footerBottomLinks.map(({ name, href }) => (
                <li key={name}>
                  <a
                    href={href}
                    className="text-[#5663f2] transition-300 hover:opacity-60"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
