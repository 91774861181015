import { ReactComponent as TwitterIcon } from "../assets/XLogo.svg";
import { ReactComponent as TelegramIcon } from "../assets/TelegramIcon.svg";
import { ReactComponent as InstagramIcon } from "../assets/InstagramIcon.svg";
import { ReactComponent as YouTubeIcon } from "../assets/YoutubeIcon.svg";
import { ReactComponent as TikTokIcon } from "../assets/TikTokIcon.svg";
import { ReactComponent as MediumIcon } from "../assets/MediumIcon.svg";
import { ReactComponent as CertikIcon } from "../assets/CertikIcon.svg";
import { ReactComponent as ZealyIcon } from "../assets/ZealyIcon.svg";

export const mockDataToken = [
  {
    id: 1,
    token: "USDT",
    value: 0.0,
  },
  {
    id: 2,
    token: "ETH",
    value: 0.0,
  },
  {
    id: 3,
    token: "XBR",
    value: 0.0,
  },
];

export const toastType = {
  SUCCESS: "success",
  ERROR: "error",
};

export const apps = [
  {
    id: 1,
    name: "Node",
  },
  {
    id: 2,
    name: "Swap",
  },
  {
    id: 3,
    name: "Pools",
  },
  {
    id: 4,
    name: "Docs",
  },
];

export const socials = [
  {
    id: 1,
    name: "X (Twitter)",
    icon: TwitterIcon,
    href: "https://x.com/XBRLab"
  },
  {
    id: 2,
    name: "Telegram",
    icon: TelegramIcon,
    href:"https://t.me/XBRLab"
  },
  {
    id: 3,
    name: "Instagram",
    icon: InstagramIcon,
    href:"https://www.instagram.com/xbrlab/"
  },
  {
    id: 4,
    name: "YouTube",
    icon: YouTubeIcon,
    href:"https://www.youtube.com/channel/UCWqI45JHWQYegDLNoLjjXvg"
  },
  {
    id: 5,
    name: "TikTok",
    icon: TikTokIcon,
  },
  {
    id: 6,
    name: "Medium",
    icon: MediumIcon,
  },
  {
    id: 7,
    name: "Certik",
    icon: CertikIcon,
  },
  {
    id: 8,
    name: "Zealy",
    icon: ZealyIcon,
  },
];

export const footerNavigation = [
  {
    heading: "Apps",
    links: [
      { name: "Swap", href: "/swap" },
      { name: "Node", href: "/node" },
      { name: "Pools", href: "https://pool.xbullrun.org" },
    ],
  },
  {
    heading: "Company",
    links: [
      { name: "Overview", href: "#" },
      { name: "Roadmap", href: "#" },
    ],
  },
  {
    heading: "Legal",
    links: [
      { name: "Terms of Use", href: "#" },
      { name: "Privacy Policy", href: "https://xbullrun.gitbook.io/project-x/terms-of-services/privacy-policy/xbr-protocol-privacy-policy" },
      { name: "Earning Disclaimer", href: "#" },
    ],
  },
  {
    heading: "Support",
    links: [
      { name: "Request Form", href: "#" },
      { name: "Contact Support", href: "#" },
    ],
  },
];

export const footerBottomLinks = [
  {
    name: "Terms & Conditions",
    href: "#",
  },
  {
    name: "Privacy Policy",
    href: "https://xbullrun.gitbook.io/project-x/terms-of-services/privacy-policy/xbr-protocol-privacy-policy",
  },
];
