import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main styles
import "react-date-range/dist/theme/default.css"; // theme styles
import { calendarToday, swapVert1 } from "../assets";
import { addDays } from "date-fns";
import { useTheme } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";

const NodeTable = ({ activeTab, connectedWalletAddress, transactions }) => {
  const [nodesData, setNodesData] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setNodesData(transactions);
      setIsLoading(false);
    }
  }, [transactions]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setShowPicker(false); // Close picker after selection
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedNodes = nodesData.sort((a, b) => {
    if (!sortConfig.key) return 0;
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const theme = useTheme();
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  const formatTimeAgo = (timestamp) => {
    const timeDifference =
      Date.now() - new Date(parseInt(timestamp) * 1000).getTime();
    const minutes = Math.floor(timeDifference / (60 * 1000));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d ${hours % 24}h ago`;
    if (hours > 0) return `${hours}h ${minutes % 60}m ago`;
    return `${minutes}m ago`;
  };

  return (
    <div
      className={`overflow-x-auto hide-scrollbar relative-container relative ${
        theme.palette.mode === "light"
          ? "bg-white text-[#3E4157]"
          : "bg-[#0B0E4A] text-gray-50"
      }`}
    >
      <table
        className={`w-full h-auto text-sm text-left hide-scrollbar ${
          isLoading ? "opacity-50" : ""
        }`}
      >
        <thead
          className={`sticky top-0 text-xs border-t border-b uppercase ${
            theme.palette.mode === "light"
              ? "bg-white text-black border-[#D6D7E3]"
              : "bg-[#0B0E4A] text-gray-50 border-midnightblue-100"
          } z-10`}
        >
          <tr className="flex w-full">
            <th className="flex-1 min-w-[195px] mobile:min-w-[150px] py-[12.5px] px-4 flex justify-start gap-3">
              Id{" "}
              <img
                src={swapVert1}
                className="w-5 h-4 cursor-pointer"
                alt=""
                onClick={() => requestSort("blockNumber")}
              />
            </th>
            <th className="flex-1  min-w-[195px] mobile:min-w-[150px] py-[12.5px] px-4 flex justify-start">
              Date{" "}
              <img
                onClick={() => setShowPicker(!showPicker)}
                alt=""
                src={calendarToday}
                className="w-5 h-4 cursor-pointer"
              />
            </th>

            <th className="flex-1  min-w-[195px] mobile:min-w-[150px] py-[12.5px] px-4 flex justify-start gap-3">
              Node count{" "}
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("nodeIds")}
              />
            </th>
            <th className="flex-1  min-w-[195px] mobile:min-w-[150px] py-[12.5px] px-4 flex justify-start gap-3">
              Node name
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("nodeIds")}
              />
            </th>
            {/* <th className="flex-1  mobile:min-w-[150px] py-[12.5px] px-4 flex justify-start gap-3">
              Amount{" "}
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("gas")}
              />
            </th> */}
            <th className="flex-1  mobile:min-w-[150px] py-[12.5px] px-4 flex justify-start gap-3">
              Tx.Hash{" "}
              <img
                src={swapVert1}
                alt=""
                className="w-5 h-4 cursor-pointer"
                onClick={() => requestSort("tx_hash")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr className="w-full flex justify-center items-center">
              <td colSpan="5" className="flex justify-center items-center">
                <BiLoaderAlt
                  className="animate-spin"
                  size={50}
                  color={theme.palette.mode === "light" ? "#000" : "#fff"}
                />
              </td>
            </tr>
          )}
          {!isLoading && sortedNodes.length > 0 ? (
            sortedNodes.map((row, index) => (
              <tr
                key={index}
                className={`flex w-full ${
                  theme.palette.mode === "light"
                    ? "bg-white text-[#3E4157] "
                    : "bg-[#0B0E4A] text-[#ACADBB]"
                }`}
              >
                <td className="flex-1 min-w-[195px]  mobile:min-w-[150px] py-3 px-4 truncate">
                  {row.blockNumber}
                </td>
                <td className={`flex-1 min-w-[195px]  mobile:min-w-[150px] py-3 px-4 truncate `}>
                  {formatTimeAgo(row.timeStamp)}
                </td>

                <td className="flex-1 min-w-[195px]  mobile:min-w-[150px] py-3 px-4 truncate">
                  {row.nodeIds.length}
                </td>
                <td className="flex-1 min-w-[195px] mobile:min-w-[150px] py-3 px-4 truncate">
                  {/* {console.log("row", row.nodeIds)} */}
                  {"XBR Node " + row.nodeIds.join(", ")}
                </td>

                {/* <td className="flex-1  mobile:min-w-[150px] py-3 px-4 truncate">
                  {row.gas}
                </td> */}
                <td className="flex-1 min-w-[195px] mobile:min-w-[150px] py-3 px-4 truncate">
                  <a
                    href={`https://polygonscan.com/tx/${row.tx_hash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    {shortenAddress(row.tx_hash)}
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-3">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NodeTable;
