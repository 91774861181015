import React, { useState, useEffect } from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main styles
import 'react-date-range/dist/theme/default.css'; // theme styles
import { calendarToday, swapVert1 } from "../../assets";
import { addDays } from 'date-fns';
import { useTheme } from "@mui/material";

const formatTimeAgo = (timestamp) => {
  const timeDifference = Date.now() - timestamp * 1000; // Convert seconds to milliseconds
  const minutes = Math.floor(timeDifference / (60 * 1000));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d ${hours % 24}h ago`;
  if (hours > 0) return `${hours}h ${minutes % 60}m ago`;
  return `${minutes}m ago`;
};

const LpFarmTable = ({ lpData }) => {
  const [dateRange, setDateRange] = useState([{ startDate: new Date(), endDate: addDays(new Date(), 7), key: 'selection' }]);
  const [showPicker, setShowPicker] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'descending' });

  const handleSelect = ranges => {
    setDateRange([ranges.selection]);
    setShowPicker(false); // Close picker after selection
  };

  const sortedTrades = lpData.sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const theme = useTheme();
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div className={`overflow-x-auto hide-scrollbar relative-container h-[600px] relative ${theme.palette.mode === "light"
      ? "bg-white text-gray-10"
      : "bg-midnightblue-300 text-gray-50"
      } `}>
      <table className="w-full h-[600px] text-sm text-left hide-scrollbar">

        <thead className={`sticky top-0 text-xs border-t border-b uppercase ${theme.palette.mode === "light"
          ? "bg-white text-gray-10 border-[#D6D7E3]"
          : "bg-midnightblue-300 text-gray-50 border-midnightblue-100"
          } z-10`}>
          <tr className="flex w-full">
            <th className="flex-1 min-w-[180px] py-[12.5px] px-4 flex justify-between">
              Date <img src={calendarToday} className="w-5 h-4 cursor-pointer" onClick={() => requestSort('timeStamp')} />
            </th>
            <th className="flex-1 min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              Event Name <img src={swapVert1} className="w-5 h-4 cursor-pointer" onClick={() => requestSort('eventName')} />
            </th>
            <th className="flex-1 min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              Tx. Hash <img src={swapVert1} className="w-5 h-4 cursor-pointer" onClick={() => requestSort('transactionHash')} />
            </th>
            <th className="flex-1 min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              ID <img src={swapVert1} className="w-5 h-4 cursor-pointer" onClick={() => requestSort('tokenId')} />
            </th>
            <th className="flex-1 min-w-[180px] py-[12.5px] px-4 flex justify-start gap-3">
              Quantity (LPT) <img src={swapVert1} className="w-5 h-4 cursor-pointer" onClick={() => requestSort('lpTokens')} />
            </th>
          
          </tr>
        </thead>

        <tbody>
          {sortedTrades.map((row, index) => (
            <tr key={index} className={`flex w-full ${row.eventName === 'stake' ? 'text-mediumseagreen' : 'text-[#E32028]'}`}>
              <td className={`flex-1 min-w-[180px] py-3 px-4 truncate ${theme.palette.mode === "light"
                ? "text-[#5B5C71]"
                : "text-green-50"
                }`}>{formatTimeAgo(row.timeStamp)}</td>
                <td className="flex-1 min-w-[180px] py-3 px-4 truncate">{capitalizeFirstLetter(row.eventName)}</td>
              <td className="flex-1 min-w-[180px] max-w-36 py-3 px-4 truncate">{row.transactionHash}</td>
              <td className="flex-1 min-w-[180px] py-3 px-4 truncate">{row.tokenId}</td>
              <td className="flex-1 min-w-[180px] py-3 px-4 truncate">{row.lpTokens}</td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LpFarmTable;
