import axios from "axios";
import { Interface, formatUnits, isAddress } from "ethers";
const liquidityAbi = require("../abis/liquidity.json");

// const poolAbi = [
//   "event Transfer(address indexed from, address indexed to, uint256 value)",
// ];

const abi = liquidityAbi;

// const provider = new JsonRpcProvider("https://polygon-rpc.com");
const contractAddress = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";
// const poolAddress = "0x762e64B12061bA23Dc63e4E24eA50bb0A747A5D4";

const desiredToken0 = "0x90FE101390be717Feb98bB02882424D8c4Ed2756";

const desiredToken1 = "0xCb2C5738978f6B24b0834fcf24A2c030494fdc26";

// const contract = new Contract(contractAddress, liquidityAbi, provider);
// const poolContract = new Contract(contractAddress, liquidityAbi, provider);

// Create an Interface object
const iface = new Interface(abi);
// const transferIface = new Interface(poolAbi);

// PolygonScan API key
const apiKey = "2SUHUZHN7KW5RMF9W2FZQJ8PHVIVIQI6QU";

// const userAddress = "0x399B7b6eDf079D6388701fA3318F1146F23b1722";

// Function signatures for methods in Uniswap V3 Positions contract
const mintMethod = "0x88316456";


const increaseLiquidityMethod = "0x219f5d17"; //id("increaseLiquidity(uint256 tokenId, uint256 amount0Desired, uint256 amount1Desired, uint256 amount0Min, uint256 amount1Min, uint256 deadline)").slice(0, 10);
// const decreaseLiquidityMethod = "0xac9650d8";

// const increaseLiquiditySignature =
//   "0x3067048beee31b25b2f1681f88dac838c8bba36af25bfb2b7cf7473a5847e35f";
// const decreaseLiquiditySignature =
//   "0x26f6a048ee9138f2c0ce266f322cb99228e8d619ae2bff30c67f8dcf9d2377b4";

// const transferSignature =
//   "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";

// Base URL for the PolygonScan API
const baseURL = "https://api.polygonscan.com/api";

export async function fetchLiquidityTransactions(userAddress) {
  try {
    // Fetch the contract transactions
    const response = await axios.get(baseURL, {
      params: {
        module: "account",
        action: "txlist",
        address: contractAddress,
        sort: "desc",
        apikey: apiKey,
      },
    });

    // Filter transactions for the mint method
    const filteredTransactions = response.data.result.filter(
      (tx) =>
        tx.input.startsWith(mintMethod) ||
        tx.input.startsWith(increaseLiquidityMethod)
      //||
      // tx.input.startsWith(decreaseLiquidityMethod)
    );

    // Log the mint transactions
    // console.log("Mint Transactions:", transactions);

    const decodedTxData = decodeInputData(filteredTransactions, userAddress);

    console.log("decodedTxData", decodedTxData);
    return decodedTxData;
  } catch (error) {
    console.error("Error fetching transactions:", error);
  }
}

function decodeInputData(transactions, userAddress) {
  try {
    let output = [];
    // let tx = transactions;
    transactions.forEach(async (tx) => {
      let decodedData;
      let functionName;
      let txData;
      // let skip = false; // Initialize the skip flag to false

      const isValidAddress = userAddress && isAddress(userAddress);


       
      // Check if the transaction input starts with the mint method
      if (tx.input.startsWith(mintMethod)) {
        decodedData = iface.decodeFunctionData("mint", tx.input)[0];
        functionName = "Mint";
        txData = {
          transactionHash: tx.hash,
          timeStamp: tx.timeStamp,
          functionName: functionName,
          token0: decodedData[0],
          token1: decodedData[1],
          //   fee: decodedData[2],
          //   tickLower: decodedData[3],
          //   tickUpper: decodedData[4],
          amount0Desired: formatUnits(decodedData[5].toString(), 6),
          amount1Desired: formatUnits(decodedData[6].toString(), 6),
          amount0Min: formatUnits(decodedData[7].toString(), 6),
          amount1Min: formatUnits(decodedData[8].toString(), 6),          
          recipient: decodedData[9],
          //   deadline: decodedData[10].toString(),
        };
        if (
          ( txData.token0.toLowerCase() === desiredToken0.toLowerCase()) ||
          (txData.token0.toLowerCase() === desiredToken1.toLowerCase() &&
            txData.token1.toLowerCase() === desiredToken1.toLowerCase()) ||
          txData.token1.toLowerCase() === desiredToken0.toLowerCase()
        ) {
          if (isValidAddress && tx.from.toLowerCase() === userAddress.toLowerCase()) {
            output.push(txData);
          }

          if (!isValidAddress) {
            output.push(txData);
          }
        }

      } else if (tx.input.startsWith(increaseLiquidityMethod)) {
        // const isXbrTx = await findXbrIcreaseLiquidity(transactions.slice(0, 2));
        // // console.log("isXbrTx", isXbrTx);
        // if (!isXbrTx) {
        //   skip = true;
        // }

        //   if (!skip) {
        decodedData = iface.decodeFunctionData(
          "increaseLiquidity",
          tx.input
        )[0];
        functionName = "Increase Liquidity";
        txData = {
          transactionHash: tx.hash,
          timeStamp: tx.timeStamp,
          functionName: functionName,
          // tokenId: decodedData[0].toString(),
          amount0Desired: formatUnits(decodedData[1].toString(), 6),
          amount1Desired: formatUnits(decodedData[2].toString(), 6),
          amount0Min: formatUnits(decodedData[3].toString(), 6),
          amount1Min: formatUnits(decodedData[4].toString(), 6),          
          //   deadline: decodedData[5].toString(),
        };

        if (!isValidAddress) {
        output.push(txData);
        }

        if (isValidAddress && tx.from.toLowerCase() === userAddress.toLowerCase()) {
            output.push(txData);
         }
      }
      /*
        else if (tx.input.startsWith(decreaseLiquidityMethod)) {
          decodedData = iface.decodeFunctionData(
            "decreaseLiquidity",
            tx.input
          )[0];
          functionName = "Decrease Liquidity";
  
          console.log("decodedData", decodedData);
  
        }
          */
    });
    return output;
  } catch (error) {
    // console.error(Error decoding transaction: ${error});
    console.error(error);
  }
}
