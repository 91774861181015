import React from 'react'
import Navbar from '../../components/Navbar'
import NewBuyNodeComponent from './buyNode'
// import ConfirmNode from './confirmNode'

function NewBuyNode() {
    return (
        <>
            <div className='w-full h-full'>
                {/* <Navbar /> */}
                <div>
                    <NewBuyNodeComponent />
                    {/* <ConfirmNode /> */}
                </div>

            </div>

        </>
    )
}

export default NewBuyNode