import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { question, nofund } from "../assets";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";

const PopUpDialog = ({
  isOpen,
  handleClose,
  image,
  title,
  description,
  footerText,
  onFooterClick,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "90%", md: "30%" },
          maxWidth: "100%",
          backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#0B0E4A",
          backgroundImage: "none",
          borderRadius: "15px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <img
          alt=""
          src={image === "1" ? question : nofund}
          className="m-auto rounded-full w-[60px] h-[60px]"
        />
        <div className={`text-center text-${theme.palette.mode === "light" ? "#F7F7FE" : "#ACADBB"} text-[25px] font-inter font-bold p-2`}>
          {title}
        </div>
        <div className={`text-center text-${theme.palette.mode === "light" ? "#ACADBB" : "#F7F7FE"} text-[16px] font-inter font-normal p-2`}>
          {description}
        </div>
        <div
          onClick={onFooterClick}
          className={`bg-[#5663F2] ${theme.palette.mode === "light" ? "text-white" : "text-lightgray-400"} hover:bg-opacity-80 mb-2 rounded-xl py-3 w-full align-center flex mt-[15px] cursor-pointer`}
        >
          <div className="m-auto">{footerText}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpDialog;
