import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { arrow_left } from '../assets';

const WalletItemComponent = ({ icon, text, onClick, walletName, disabled }) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;
    const line = theme.palette.primary.main;
    const querty = theme.palette.disabeled.back;
    const quertyColor = theme.palette.disabeledColor.backColor;
    const lightColor = theme.palette.lightColor.lightColors;
    const cardColor = theme.palette.cardColor.cardColor

    const handleClick = () => {
        if (!disabled && onClick && typeof onClick === 'function') {
            onClick(walletName);
        }
    };
    

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Paper
            className="wallet-item flex items-center justify-between gap-2 w-full p-3 cursor-pointer transition duration-300 ease-in-out transform hover:shadow-md"
            onClick={handleClick}
            elevation={0} // Set elevation to 0 to remove the default shadow
            style={{
                background: disabled ? 'transparent' : (isHovered ? primaryLight : 'transparent'), // Adjust background based on disabled state
                color: disabled ? dark : dark, // Adjust color based on disabled state
                pointerEvents: disabled ? 'none' : 'auto', // Disable pointer events if the component is disabled
                opacity: disabled ? 0.5 : 1, // Adjust opacity based on disabled state
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="flex items-center gap-5">
                {icon} {/* Render the SVG icon directly */}
                <span className="text-sm md:text-lg">{text}</span>
            </div>
            <img src={arrow_left} alt="arrow" className="w-5 h-5 md:w-9 md:h-9 text-[#7724FB]" />
        </Paper>
    );
};

export default WalletItemComponent;
