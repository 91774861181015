import React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { loader } from '../assets';
import { CircularProgress } from '@mui/material';

const LoaderComponent = ({ transactionText }) => {
    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const primaryLight = theme.palette.primary.light;
    const loaderBackgroundColor = theme.palette.type === 'dark' ? primaryLight : neutralLight;

    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10">
            {/* Modal overlay */}
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-70" style={{ backgroundColor: loaderBackgroundColor }}></div>

            {/* Loader content */}
            <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-800 text-2xl font-bold flex items-center bg-white p-6 rounded-lg shadow-lg`} >
                <div className="mr-4">
                    {/* <svg className="animate-spin h-10 w-10 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8 8 0 0012 20v4c-6.627 0-12-5.373-12-12h4zm14-2a8 8 0 01-8 8v4c6.627 0 12-5.373 12-12h-4zm-2-5.373A8 8 0 0012 4V0c6.627 0 12 5.373 12 12h-4z"></path>
                    </svg> */}
                    <img src={loader} alt="loader" className='w-15 h-10' />
                    {/* <CircularProgress
                        variant="indeterminate"
                        color="primary" // or any other color from your theme, e.g., "secondary"
                        size={40}
                        thickness={4}
                        value={100}
                    /> */}
                </div>
                <div className='text-[15px] md:text-[18px]'>
                    {transactionText}
                </div>
            </div>
        </div>
    );
};

LoaderComponent.propTypes = {
    transactionText: PropTypes.string.isRequired
};

export default LoaderComponent;
