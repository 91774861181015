import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import Navbar from "../components/Navbar";
import FooterComponent from "../components/FooterComponent";
import Footer from "../components/Footer.jsx";
import EmailComponent from "../components/EmailComponent";
import { useNavigate } from "react-router-dom";
import {
  approveRewardsFromMediator,
  claimXBRRewardsForNodes,
  getClaimXBRDetails,
  getCurrentBlockNumber,
  getMiningRewards,
  getNodeIds,
  getRewardsFromServer,
  getTotalSupply,
  totalClaimedXBRRewards,
} from "../uniswap/utils.js";
import {
  LightmodeXBR,
  XBR,
  node,
  EditorChoice,
  PersonAdd,
  LightModeNode,
  BuyMoreNode,
  BuyMoreNodeLight,
} from "../assets/index.js";
import NodeTable from "../components/NodeTable.jsx";
import axios from "axios";
import { BiLoaderAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import ReferralHistoryTable from "../components/ReferralHistoryTable.jsx";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { Contract, JsonRpcProvider } from "ethers";
import nodeABI from "../abis/NODEABI.json";
import { fetchMarketData } from "../uniswap/utils";
import ClaimXBRtable from "../components/claimXBRtable.js";

const NodeComponent = () => {
  const { walletProvider } = useWeb3ModalProvider();
  // console.log("walletProvider", walletProvider);
  const { account } = useSelector((state) => state.wallet);
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("Transaction");
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const [addresses, setAddresses] = useState([
    "0x123abc...789xyz",
    "0x456def...012uvw",
    "0x789ghi...345rst",
    "0x012jkl...678mno",
  ]);

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const connectedAccount = localStorage.getItem("connectedAccount");
  const [xbrPrice, setXbrPrice] = useState(0);
  const [tbaPrice, setTbaPrice] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);
  const XbriconSrc = theme.palette.mode === "light" ? LightmodeXBR : XBR;
  const addAddress = (newAddress) => {
    setAddresses([...addresses, newAddress]);
  };

  const [claimTransactions, setClaimTransactions] = useState([]);

  const [selectedCard, setSelectedCard] = useState(null);
  const [nodesLeft, setNodesLeft] = useState(5000);
  const [claimedRewards, setClaimedRewards] = useState(0);
  const [earnedRewards, setEarnedRewards] = useState(0);
  const [earnedFromReferrals, setEarnedFromReferrals] = useState(0);
  const [nodes, setNodes] = useState([]);
  const [referralHistory, setReferralHistory] = useState([]);
  const [rewardsDistributed, setRewardsDistributed] = useState(0);
  const [unclaimedXBR, setUnclaimedXBR] = useState(0);
  const [claimXBRTotal, setClaimXBRTotal] = useState(0);

  console.log("earnedRewards", earnedRewards);
  console.log("xbrPrice", xbrPrice);

  const formatBalanceForPrice = (balance) => {
    // Ensure the balance is treated as a number
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return num.toFixed(4).replace(/\.00$/, ""); // Output as a string with two decimal places
    }
  };

  const fetchPrice = async (address) => {
    const URL = `${process.env.REACT_APP_API}/v1/xbr/token-prices?address=${address}`;
    const response = await axios.get(URL);
    // console.log("response", response.data.data.attributes.price_usd);

    return response?.data?.data?.attributes?.price_usd;
  };

  const getXBRAndTBA = async () => {
    const XBR = await fetchPrice("0xD44CaEBbF5C5a3848357eC05e24220BEBEF41d40");
    const TBA = await fetchPrice("0x7e1AE068Ac4e0ffD7a84750c2FB093A0EAE9aEa2");
    setXbrPrice(Number(XBR));
    setTbaPrice(Number(TBA));
  };

  const getMaticPrice = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/v1/xbr/matic-price`
    );

    // Access the property using square brackets
    const usdPrice = await response.data.usd;
    console.log("Matic", usdPrice);
    setMaticPrice(formatBalanceForPrice(usdPrice));
  };

  useEffect(() => {
    getXBRAndTBA();
    getMaticPrice();
  }, [setXbrPrice, setTbaPrice, setMaticPrice]);
  const fetchTotalSupply = async () => {
    try {
      const totalSupply = await getTotalSupply(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        walletProvider
      );
      setNodesLeft(5000 - Number(totalSupply));
    } catch (error) {
      setError("Error fetching total supply");
    }
  };

  const fetchRewards = async () => {
    try {
      const rewardsFromBlc = await getMiningRewards(walletProvider);
      const {
        totalRewards = 0,
        totalClaims = 0,
        ...dynamicObjects
      } = rewardsFromBlc || {};

      // console.log("rewardsFromBlc", rewardsFromBlc);

      const dynamicRewardsArray = Object.values(dynamicObjects).filter(
        (item) => typeof item === "object"
      );

      // console.log("dynamicRewardsArray", dynamicRewardsArray);

      const totalRewardsEarned = dynamicRewardsArray.reduce(
        (acc, reward) => Number(acc) + Number(reward.rewardsEarned),
        0
      );

      // console.log("rewards", rewards);
      // console.log("totalRewards", totalRewards);
      // console.log("totalClaims", totalClaims);
      setClaimedRewards(totalClaims);
      setEarnedRewards(parseFloat(totalRewardsEarned).toFixed(6));
      setNodes(dynamicRewardsArray);
    } catch (error) {
      setError("Error fetching rewards");
    }
  };

  const [isVerifying, setIsVerifying] = useState(false);

  const handleClaimXbrRewards = async ({ nodeId, reward }) => {
    try {
      setIsVerifying(true);
      console.log(
        "handleClaimXbrRewards",
        "nodeId:",
        nodeId,
        "reward:",
        reward
      );

      const nodeData = nodes.filter((node) => node.nodeId === nodeId)[0];
      console.log("nodeData", nodeData);
      let currentBlock = Number(await getCurrentBlockNumber());
      if (currentBlock === "NaN") {
        currentBlock = Number(await getCurrentBlockNumber());
      }
      console.log("currentBlock", currentBlock);

      // Fetch reward amount for the nodeId (assuming you have a way to get this)
      // console.log("nodeData", nodeData?.lastlyClaimedRewardsBlock)
      const actualRewards = await getRewardsFromServer(
        nodeData.lastlyClaimedRewardsBlock.toString() !== "0"
          ? nodeData.lastlyClaimedRewardsBlock.toString()
          : nodeData.miningStartedBlockNumber.toString(),
        currentBlock
      );

      console.log("actualRewards", actualRewards);

      if (Number(reward) <= Number(actualRewards)) {
        console.log("WORKING");
        const approveResult = await approveRewardsFromMediator(
          parseFloat(reward).toFixed(18)
        );
        if (approveResult != null) {
          const response = await claimXBRRewardsForNodes(
            nodeId,
            parseFloat(reward).toFixed(18),
            walletProvider
          );
          console.log("Rewards claimed successfully", response);
          window.location.reload();
        }
      }

      // if (
      //   verificationResult &&
      //   verificationResult.success &&
      //   verificationResult.isValid
      // ) {
      //   await claimXBRRewardsForNodes(nodeId.index, walletProvider);
      //   console.log("Rewards claimed successfully");
      // } else {
      //   setError("Verification failed. Cannot claim rewards.");
      // }
    } catch (error) {
      console.log("Error claiming rewards:", error);
      setError("Error claiming rewards");
    } finally {
      setIsVerifying(false);
    }
  };


  const getReferralHistory = async () => {
    try {
      const URL = process.env.REACT_APP_API;
      const response = await axios.get(
        `${URL}/v1/xbr/transactions/${connectedAccount}`
      );
      // console.log("referralHistory response:", response.data);
      setReferralHistory(response.data);
    } catch (error) {
      console.log("Error fetching referralHistory data:", error);
    }
  };

  const totalRewardsDistributed = async () => {
    const currentBlock = await getCurrentBlockNumber();
    const miningStartedBlockNumber = Number(
      process.env.REACT_APP_MINING_STARTED_BLOCK_NUMBER
    );
    const rewardPerBlock = Number(process.env.REACT_APP_REWARD_PER_BLOCK);
    const total =
      (Number(currentBlock) - miningStartedBlockNumber) * rewardPerBlock;
    setRewardsDistributed(total);
    return total;
  };

  const totalUnclaimedRewards = async () => {
    const claimedRewards = await totalClaimedXBRRewards();
    const totalDistributedRewards = await totalRewardsDistributed();
    // console.log("DIS", totalDistributedRewards);
    const total = Number(totalDistributedRewards) - Number(claimedRewards);
    // console.log("totalUnclaimedRewards", total);
    setUnclaimedXBR(total);
    setClaimXBRTotal(claimedRewards);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchTotalSupply(),
      fetchRewards(),
      getTransactionEvents(),
      getClaimXBRDetails(),
    ]).then(() => setLoading(false));
    fetchTotalSupply();
    getReferralHistory();
    getNodeIds();
    totalRewardsDistributed();
    totalUnclaimedRewards();
  }, [walletProvider]);

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const response = await getClaimXBRDetails();
        setClaimTransactions(response);
      } catch (error) {
        console.log("Error fetching claimXbrData data:", error);
      }
    };

    fetchClaimData();
  }, []);

  const getTransactionEvents = async () => {
    try {
      const contractABI = nodeABI;
      const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
      const address = localStorage.getItem("connectedAccount");

      const apiKey = "Z4C4BRJD8EB51HEP4HWPX5745FB3P2TP5I";
      const baseUrl = "https://api.polygonscan.com/api";
      const URL = `${baseUrl}?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=asc&apikey=${apiKey}`;

      const providerUrl = "https://polygon-rpc.com";
      const provider = new JsonRpcProvider(providerUrl);

      const transferEventSignature =
        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";

      const contract = new Contract(contractAddress, contractABI, provider);

      const response = await axios.get(URL);
      const allTransactions = response.data.result;

      const filteredTransactions = allTransactions.filter(
        (tx) =>
          tx.to.toLowerCase() === contractAddress.toLowerCase() ||
          tx.from.toLowerCase() === contractAddress.toLowerCase()
      );

      const txHashs = filteredTransactions.map((tx) => tx.hash);

      const results = [];

      for (const txHash of txHashs) {
        const receipt = await provider.getTransactionReceipt(txHash);
        const transferLogs = receipt.logs.filter(
          (log) => log.topics[0] === transferEventSignature
        );

        const nodeIds = transferLogs
          .map((log) => {
            try {
              const parsedLog = contract.interface.parseLog(log);
              return parsedLog.args[2].toString();
            } catch (error) {
              if (error.code === "BUFFER_OVERRUN") {
                return null;
              } else {
                console.error("Error parsing log:", error);
                return null;
              }
            }
          })
          .filter((nodeId) => nodeId !== null);

        const transaction = filteredTransactions.find(
          (tx) => tx.hash === txHash
        );
        if (nodeIds.length > 0) {
          results.push({
            tx_hash: txHash,
            blockNumber: transaction.blockNumber,
            timeStamp: transaction.timeStamp,
            nodeIds: nodeIds.map(Number),
          });
        }
      }
      setTransactions(results);
    } catch (error) {
      setError("Error fetching transaction events");
      console.error(error);
    }
  };

  const handleTransferNode = () => {
    navigate("/transfer-node", { state: { nodes } });
  };

  const handleCardClick = ({ nodeId, reward }) => {
    setSelectedCard({ nodeId, reward });
  };

  const isCardSelected = (index) => {
    return selectedCard && selectedCard.nodeId === index;
  };

  const verifyRewards = async (nftId, rewardAmount) => {
    try {
      const response = await axios.get(`http://localhost:3000/v1/xbr/verify`, {
        params: { nftId, rewardAmount },
      });
      return response.data; // Assuming the response contains the verification result
    } catch (error) {
      console.error("Error verifying rewards:", error);
      setError("Error verifying rewards");
      return null;
    }
  };

  const totalReferralAmount =
    referralHistory && referralHistory.length > 0
      ? referralHistory.reduce((acc, referral) => acc + referral.amount, 0)
      : 0;

  const addNodeIcon =
    theme.palette.mode === "light" ? BuyMoreNode : BuyMoreNodeLight;

  return (
    <div
      className="overflow-y-auto font-inter"
      style={{
        background: alt,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <div className="fixed top-0 w-full z-1000">
        <Navbar />
      </div> */}
      <div className="mt-[70px] mobile:mt-[10px] flex flex-col font-inter mx-auto pt-8 w-full max-w-[calc(80rem+2rem)] px-4 ">
        <div
          className={`flex flex-col ${
            theme.palette.mode === "light" ? "text-[#141527]" : "text-white"
          }`}
        >
          <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-16">
          
              <div className={`flex mobile:${nodes.length > 0 ? "hidden" : ""} flex-col justify-center lg:w-1/3`}>
                <div className="">
                  {theme.palette.mode === "light" ? (
                    <img
                      src={LightModeNode}
                      alt=""
                      className=" mobile:mx-auto tab:mx-auto  md:w-[264px] md:h-[272px] w-[240px] h-[240px] "
                    />
                  ) : (
                    <img
                      src={node}
                      alt=""
                      className="mobile:mx-auto tab:mx-auto  md:w-[264px] md:h-[272px] w-[240px] h-[240px]"
                    />
                  )}
                </div>
                <div className="flex flex-col w-90per">
                  <div className="font-bold text-[31px] md:text-[35px]  lg:w-[330px] font-inter  lg:text-left">
                    Enjoy a Lifetime Rewards with XBR Founder's Node
                  </div>
                  <div className="font-normal text-[16px] lg:w-[318px] opacity-50 md:text-center lg:text-left">
                    Be part of history in the making with the XBR Founder's Node,
                    experiencing lifetime rewards and exclusive perks for early
                    participants.
                  </div>
                </div>
              </div>
            <div className="w-full  lg:w-60per  flex flex-col">
              {nodes.length ? (
                <div className="flex justify-between mb-8 mobile:flex-col-reverse">
                  <span className="font-bold text-ft22 font-inter">
                    MY FOUNDER'S NODES
                  </span>
                  {/* <button
                    className=" font-semibold w-[136px] bg-[#5663F2] text-ft5 mobile:mb-4 text-white rounded-xl px-4 py-2 cursor-pointer"
                    onClick={() => navigate("/buy-node")}
                  >
                    Buy a Node
                  </button> */}
                </div>
              ) : (
                ""
              )}

              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <BiLoaderAlt
                    className={`animate-spin text-6xl ${
                      theme.palette.mode === "light"
                        ? "text-midnightblue-200"
                        : "text-white"
                    }`}
                  />
                </div>
              ) : nodes.length ? (
                <div className="flex flex-col">
                  <div className="flex space-x-4 w-full overflow-x-auto hide-scrollbar">
                    {nodes &&
                      nodes.map((eachNode) => (
                        <div className="flex flex-col" key={eachNode.nodeId}>
                          <div
                            onClick={() =>
                              handleCardClick({
                                nodeId: eachNode.nodeId,
                                reward: eachNode.rewardsEarned,
                              })
                            }
                            className={`flex flex-col justify-between items-start w-[318px] cursor-pointer h-[204px] p-5 gap-6 flex-shrink-0 rounded-3xl ${
                              theme.palette.mode === "light"
                                ? isCardSelected(eachNode.nodeId)
                                  ? "bg-[#F2F3FC]"
                                  : "bg-[#E6E7F0]"
                                : isCardSelected(eachNode.nodeId)
                                ? "bg-[#0b174a]"
                                : "bg-[#0B0E4A]"
                            } ${
                              isCardSelected(eachNode.nodeId)
                                ? "border-[#5663F2] border-2"
                                : "border-0"
                            }`}
                          >
                            <img
                              src={node}
                              alt=""
                              className="h-[66px] w-[66px]"
                            />
                            <div
                              className={`font-bold font-paragraph-p1-regular text-ft22 ${
                                theme.palette.mode === "light"
                                  ? isCardSelected(eachNode.nodeId)
                                    ? "text-[#1422ED]"
                                    : "text-[#141527]"
                                  : isCardSelected(eachNode.nodeId)
                                  ? "text-[#B4B8F9]"
                                  : "text-[#ACADBB]"
                              }`}
                            >
                              Node {eachNode.nodeId}
                            </div>
                            <div className="flex items-center font-paragraph-p1-regular justify-between w-full">
                              {isCardSelected(eachNode.nodeId) && (
                                <div className="w-6 h-3 rounded-xl bg-[#1422ED] mr-2"></div>
                              )}
                              <div className="flex-grow"></div>
                              <div
                                className={`flex text-ft5 justify-end ${
                                  theme.palette.mode === "light"
                                    ? isCardSelected(eachNode.nodeId)
                                      ? "text-[#1422ED]"
                                      : "text-[#141527]"
                                    : isCardSelected(eachNode.nodeId)
                                    ? "text-[#B4B8F9]"
                                    : "text-[#ACADBB]"
                                }`}
                              >
                                {eachNode.presentBlock}
                              </div>
                            </div>
                          </div>
                          <div className="pt-12 gap-2 font-inter">
                            <div
                              className={`text-ft31 ${
                                theme.palette.mode === "light"
                                  ? "text-[#141527]"
                                  : "text-white"
                              } font-bold mobile:text-ft24`}
                            >
                              {Number(eachNode.rewardsEarned).toFixed(6)} XBR
                            </div>
                            <div className="text-ft5 pt-2 text-[#13CC95] font-normal">
                              Total rewards to claim
                            </div>
                            <div
                              className={`font-normal pt-2 ${
                                theme.palette.mode === "light"
                                  ? "text-[#141527]"
                                  : "text-[#ACADBB]"
                              } text-ft5`}
                            >
                              ≈{" "}
                              {Number(
                                eachNode.rewardsEarned * xbrPrice
                              ).toFixed(2)}{" "}
                              USDT
                            </div>
                          </div>
                        </div>
                      ))}

                    {nodes.length < 10 && (
                      <div className="flex flex-col">
                        <div
                          onClick={() => navigate("/buy-node")}
                          className={`flex flex-col justify-between items-start w-[318px] cursor-pointer h-[204px] py-3 px-5 gap-6 flex-shrink-0 rounded-3xl ${
                            theme.palette.mode === "light"
                              ? "bg-[#E6E7F0]"
                              : "bg-[#0B0E4A]"
                          } border-0`}
                        >
                          <div className="flex flex-col justify-center items-center h-full w-full">
                            <img
                              src={addNodeIcon}
                              alt=""
                              className="h-[66px] w-[66px]"
                            />
                            <div
                              className={`font-semibold font-paragraph-p1-regular text-ft6 ${
                                theme.palette.mode === "light"
                                  ? "text-[#141527]"
                                  : "text-white"
                              }`}
                            >
                              Get Your Node
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex mobile:flex-col pt-12 gap-2 mobile:gap-3">
                    
                    <button
                      disabled={!selectedCard}
                      className={`py-2 xl:w-[199px] flex   justify-center mobile:w-full items-center text-ft5 px-4 mobile:h-[43px] rounded-xl bg-[#5663F2] font-semibold text-white ${
                        !selectedCard ? "bg-opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => handleClaimXbrRewards(selectedCard)}
                    >
                      {isVerifying ? (
                        <BiLoaderAlt className="animate-spin text-xl" />
                      ) : (
                        "Claim XBR Rewards"
                      )}
                    </button>

                    <button
                      onClick={() => handleTransferNode()}
                      className={` ${
                        theme.palette.mode === "light"
                          ? "bg-[#E6E7FD] text-[#1422ED]"
                          : "bg-white text-[#1422ED]"
                      } font-semibold text-ft5 px-6 py-3  mobile:w-full mobile:h-[43px] rounded-xl`}
                    >
                      Transfer Node
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className={`w-full md:h-[501px] py-5 flex flex-col justify-center items-center text-center ${
                    theme.palette.mode === "light"
                      ? "bg-white border border-gray-5"
                      : "bg-[#0B0E4A]"
                  } rounded-[24px]`}
                >
                  <p className="font-bold text-ft22 mb-6 font-inter mobile:text-ft20">
                    You haven’t bought any node yet.
                  </p>
                  <p className="lg:text-ft5 text-ft23 w-60per text-[#ACADBB] mb-6">
                    To get more rewards, you can buy nodes. After buying a node
                    you'll see all the details here.
                  </p>
                  <button
                    className=" font-semibold bg-[#5663F2] text-ft4 text-white rounded-xl px-4 py-2 cursor-pointer"
                    onClick={() => navigate("/buy-node")}
                  >
                    Buy a Node
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col mobile:flex-col-reverse md:justify-between lg:flex-row  ">
            <div
              className={` mt-10 mobile:full tab:full lg:w-[75%] ${
                theme.palette.mode === "light"
                  ? "border border-lightgray-500 bg-white text-[#141527]"
                  : ""
              }    bg-[#0B0E4A] flex flex-col items-center mt-12 p-8 gap-4 text-lightgray-100 rounded-3xl`}
            >
              <div
                className={` ${
                  theme.palette.mode === "light"
                    ? "text-gray-200"
                    : "text-white"
                } flex flex-row mr-auto text-ft22 font-bold justify-end uppercase items-end gap-2 mobile:text-ft18`}
              >
                Transactions
              </div>
              <div
                className={`flex w-full flex-col md:flex-row justify-start gap-4 pb-3 pt-1 mr-auto`}
              >
                <div className="flex-1 md:w-30per rounded-lg">
                  <div className="mobile:flex mobile:items-center  mobile:gap-2">
                    <img className="w-12 h-12 pb-2" src={XbriconSrc} alt="" />
                    <div className="flex flex-col">
                      <h2
                        className={`text-2xl font-bold ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        {parseFloat(claimedRewards).toFixed(6)} XBR
                      </h2>
                      <p
                        className={`  ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        Total rewards claimed already
                      </p>
                      <p
                        className={`  ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        ≈ $
                        {claimedRewards === 0
                          ? 0
                          : (claimedRewards * xbrPrice).toFixed(6)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex-1 md:w-30per rounded-lg">
                  <div className="mobile:flex mobile:items-center  mobile:gap-2">
                    <img className="w-12 h-12 pb-2" src={EditorChoice} alt="" />
                    <div className="flex flex-col">
                      <h2
                        className={`text-2xl font-bold ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        {earnedRewards} XBR
                      </h2>
                      <p
                        className={`  ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        Estimated Mining Rewards
                      </p>
                      <p
                        className={`  ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        ≈ $
                        {earnedRewards === 0
                          ? 0
                          : parseFloat(earnedRewards * xbrPrice).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex-1 md:w-30per rounded-lg">
                  <div className="mobile:flex mobile:items-center  mobile:gap-2">
                    <img className="w-12 h-12 pb-2" src={PersonAdd} alt="" />
                    <div className="flex flex-col">
                      <h2
                        className={`text-2xl font-bold ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        {totalReferralAmount.toFixed(2)} USDT
                      </h2>
                      <p
                        className={` ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        From Referrals
                      </p>
                      <p
                        className={` ${
                          theme.palette.mode === "light"
                            ? "text-[#141527]"
                            : "text-[#ACADBB]"
                        }`}
                      >
                        ≈ ${totalReferralAmount.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-start gap-2 mobile:gap-5 justify-start">
                  <div
                    className={`flex flex-row ${
                      theme.palette.mode === "light"
                        ? "text-gray-200"
                        : "text-midnightblue-500"
                    } items-center justify-center py-2 md:px-4 ${
                      activeTab === "Transaction"
                        ? "border-b-3 border-mediumslateblue-500 font-semibold"
                        : ""
                    }`}
                    onClick={() => setActiveTab("Transaction")}
                  >
                    <div className="text-ft5 mobile:text-ft1 cursor-pointer">
                      Transaction History
                    </div>
                  </div>
                  <div
                    className={`flex cursor-pointer flex-row ${
                      theme.palette.mode === "light"
                        ? "text-gray-200"
                        : "text-midnightblue-500"
                    } items-center justify-center py-2 md:px-4 ${
                      activeTab === "Referral"
                        ? "border-b-3 border-mediumslateblue-500 font-semibold"
                        : ""
                    }`}
                    onClick={() => setActiveTab("Referral")}
                  >
                    <div className="text-ft5 mobile:text-ft1">
                      Referral History
                    </div>
                  </div>
                  <div
                    className={`flex cursor-pointer flex-row ${
                      theme.palette.mode === "light"
                        ? "text-gray-200"
                        : "text-midnightblue-500"
                    } items-center justify-center py-2 md:px-4 ${
                      activeTab === "ClaimXBR"
                        ? "border-b-3 border-mediumslateblue-500 font-semibold"
                        : ""
                    }`}
                    onClick={() => setActiveTab("ClaimXBR")}
                  >
                    <div className="text-ft5 mobile:text-ft1">
                      Claim XBR Reward
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-1 overflow-x-auto hide-scrollbar flex flex-col items-start justify-start gap-1 w-full">
                {activeTab === "Transaction" ? (
                  <NodeTable
                    activeTab={activeTab}
                    connectedWalletAddress={connectedAccount}
                    transactions={transactions}
                  />
                ) : activeTab === "Referral" ? (
                  <ReferralHistoryTable referrdata={referralHistory} />
                ) : activeTab === "ClaimXBR" ? (
                  <ClaimXBRtable claimData={claimTransactions} />
                ) : null}
              </div>
            </div>
            <div
              className={`md:w-25per xl:w-[318px] mt-12 ${
                theme.palette.mode === "light"
                  ? "text-[#141527]"
                  : "text-[#ACADBB]"
              } md:p-6 rounded-lg`}
            >
              <h2
                className={`text-wrap md:text-[39px] text-[31px]  font-inter ${
                  theme.palette.mode === "light"
                    ? "text-[#141527]"
                    : "text-white"
                } font-bold mb-6`}
              >
                Global Founders
              </h2>
              <div className="mobile:grid mobile:grid-cols-2 mobile:p-5 gap-2 ">
                <div className="mb-6">
                  <span className="block text-ft22 font-bold">5000</span>
                  <span className="text-ft5">Global NODES</span>
                </div>
                <div className="mb-6">
                  <span className="block text-ft22 font-bold">
                    {parseFloat(rewardsDistributed).toFixed(2)} XBR
                  </span>
                  <span className="text-ft5">Rewards distributed</span>
                </div>
                <div className="mb-6">
                  <span className="block text-ft22 font-bold">
                    {parseFloat(unclaimedXBR).toFixed(2)} XBR
                  </span>
                  <span className="text-ft5">Unclaimed XBR</span>
                </div>
                <div className="mb-6">
                  <span className="block text-ft22 font-bold">
                    {parseFloat(claimXBRTotal).toFixed(2)} XBR
                  </span>
                  <span className="text-ft5">claimed XBR</span>
                </div>
                <div className="mb-6">
                  <span className="block text-ft22 font-bold">{nodesLeft}</span>
                  <span className="text-ft5">Available Founder's Node</span>
                </div>
                {/* <div className="mb-6">
                  <span className="block text-ft22 font-bold">1234</span>
                  <span className="text-ft5">Referrals added</span>
                </div> */}
              </div>
              {/* <button
                type="button"
                className="mt-6 py-2.5 px-4 bg-[#5663F2] mobile:w-full hover:bg-opacity-75 text-white rounded-lg"
              >
                Buy a node
              </button> */}
              <div className="w-full justify-center">
              <button
                onClick={() => navigate("/buy-node")}
                className={` ${
                  theme.palette.mode === "light"
                    ? "text-[#E6E7FD] bg-[#1422ED]"
                    : "text-white bg-[#1422ED]"
                } py-2  flex justify-center items-center hover:opacity-85 mobile:w-full text-ft5 px-6  h-10   mobile:h-[43px] rounded-xl bg-[#5663F2] font-semibold text-white`}
              >
                Buy a node
              </button>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          {/* <EmailComponent /> */}
        </div>
        <div
          className="bottom-0 w-full"
          style={{ background: theme.palette.background.alt }}
        >
          {/* <FooterComponent /> */}
        </div>
      </div>
          <Footer/>
    </div>
  );
};

export default NodeComponent;
