import { createSlice } from "@reduxjs/toolkit";

const getInitialMode = () => {
  const savedMode = localStorage.getItem("themeMode");
  return savedMode ? savedMode : "dark";
};

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: getInitialMode(),
  },
  reducers: {
    setThemeMode: (state, action) => {
      state.mode = action.payload;
      localStorage.setItem("themeMode", action.payload); // Update localStorage whenever theme mode changes
    },
  },
});

export const { setThemeMode } = themeSlice.actions;
export const selectThemeMode = (state) => state.theme.mode;
export default themeSlice.reducer;
