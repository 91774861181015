import axios from "axios";

const {
  id,
  isAddress,
  JsonRpcProvider,
  Contract,
  formatUnits,
} = require("ethers");
const farmABI = require("./../abis/farm.json");

export async function fetchTransactions(userAddress = null) {
  try {
    const provider = new JsonRpcProvider("https://polygon-rpc.com");
    const contractAddress = process.env.REACT_APP_FARM_CONTRACT_ADDRESS;
    const contract = new Contract(contractAddress, farmABI, provider);

    // API details
    const apiKey = "2SUHUZHN7KW5RMF9W2FZQJ8PHVIVIQI6QU";
    const apiUrl = "https://api.polygonscan.com/api";

    // Define method signatures
    const stakeMethodId = id("stake(uint256,uint256)").slice(0, 10);
    const unstakeMethodId = id("unstake(uint256,uint256)").slice(0, 10);
    const farmSignatures = [
      "0xf556991011e831bcfac4f406d547e5e32cdd98267efab83935230d5f8d02c446",
      "0xfbd65cfd6de1493db337385c0712095397ecbd0504df64b861cdfceb80c7b422",
    ];

    // Fetch transactions
    const params = {
      module: "account",
      action: "txlist",
      address: contractAddress,
      sort: "desc",
      apikey: apiKey,
    };

    const response = await axios.get(apiUrl, { params });
    const transactions = response.data.result;


    // Validate the user address
    const isValidAddress = userAddress && isAddress(userAddress);

    const filteredTransactions = transactions.filter(
      (tx) =>
        (tx.input.startsWith(stakeMethodId) ||
          tx.input.startsWith(unstakeMethodId)) &&
        (!isValidAddress || tx.from.toLowerCase() === userAddress.toLowerCase())
    );
    const txHashes = filteredTransactions.map((tx) => tx.hash);

    const results = [];

    const receipts = await Promise.all(
      txHashes.map((txHash) => provider.getTransactionReceipt(txHash))
    );

    receipts.forEach(async (receipt) => {
      const transferLogs = receipt.logs.filter((log) =>
        farmSignatures.includes(log.topics[0])
      );
      const block = await provider.getBlock(receipt.blockNumber);

      transferLogs.forEach((log) => {
        try {
          const parsedLog = contract.interface.parseLog(log);

          const functionName = filteredTransactions
            .find((tx) => tx.hash === receipt.hash)
            .input.startsWith(stakeMethodId)
            ? "stake"
            : "unstake";

          const data = {
            transactionHash: receipt.hash,
            user: parsedLog.args[0].toString(),
            tokenId: parsedLog.args[1].toString(),
            lpTokens: formatUnits(parsedLog.args[2], 18),
            pid: parsedLog.args[3].toString(),
            eventName: functionName,
            timeStamp: block.timestamp,
          };
          results.push(data);
        } catch (error) {
          if (error.code === "BUFFER_OVERRUN") {
            // Silently ignore buffer overrun errors
            return;
          } else {
            // Log other errors
            console.error("Error parsing log:", error);
          }
        }
      });
    });

    return results;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
}
